import {
  GET_EMERGENCY_PLAYLISTS,
  GET_PLAYLIST_BY_ID,
  GET_PLAYLIST_CHANGELOGS,
  LIST_WIDGET_DISPLAY_RESTRICTIONS
} from '@/graphql/queries'
import {
  ADD_PLAYLIST_SLIDE,
  COPY_PLAYLIST_BY_ID,
  COPY_PLAYLIST_SLIDES,
  CREATE_PLAYLIST,
  DELETE_PLAYLIST_BY_ID,
  DELETE_PLAYLIST_SLIDE,
  DELETE_PLAYLIST_SOUNDTRACK,
  EXPORT_SLIDES_TO_PLAYLISTS,
  MOVE_PLAYLIST_BY_ID,
  MOVE_PLAYLIST_SLIDE,
  PUBLISH_PLAYLIST_VERSION,
  SET_PLAYLIST_SOUNDTRACK_URL,
  UPDATE_PLAYLIST_BY_ID,
  UPDATE_PLAYLIST_SLIDE,
  UPLOAD_PLAYLIST_SOUNDTRACK
} from '@/graphql/mutations'
import { LAYOUT_ZONE_KEYS_TO_INDEX } from '@/constants'
import { apolloCall } from '@/helpers/Graphql'
import { handleActionError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    playlist: null,
    loading: {
      playlist: false,
      changeLogs: false,
      emergencyPlaylists: false
    },
    playlistChangelogs: null,
    playlistChangelogsLoading: false,
    playlistLoading: false,
    emergencyPlaylistsLoading: false,
    widgetDisplayRestrictions: null,
    emergencyPlaylists: []
  },
  actions: {
    async refreshPlaylist ({ dispatch, getters }) {
      const playlistId = getters.playlistId
      await dispatch('getPlaylist', playlistId)
    },
    async getPlaylist ({ commit, state }, playlistId) {
      if (!playlistId) return
      try {
        const { getPlaylist } = await apolloCall({
          commit,
          query: GET_PLAYLIST_BY_ID,
          variables: { id: playlistId },
          key: 'playlist'
        })
        commit('SET_PLAYLIST', getPlaylist)
      } catch (e) {
        handleActionError(e)
      }
    },
    async getEmergencyPlaylists ({ commit }) {
      try {
        const { listPlaylists } = await apolloCall({
          commit, query: GET_EMERGENCY_PLAYLISTS,
          variables: { filters: { specialization: 'EMERGENCY' } },
          key: 'emergencyPlaylists'
        })
        commit('SET_EMERGENCY_PLAYLISTS', listPlaylists.sort((a, b) => a.createdAt.localeCompare(b.createdAt)))
      } catch (e) {
        handleActionError(e)
      }
    },
    async createEmergencyPlaylist ({ dispatch, commit }, { layout }) {
      const input = {
        color: null,
        layout,
        name: 'Emergency Playlist',
        paused: false,
        specialization: 'EMERGENCY'
      }
      try {
        await apolloCall({
          commit,
          mutation: CREATE_PLAYLIST,
          variables: { input },
          key: 'emergencyPlaylists'
        })
        await dispatch('getEmergencyPlaylists')
      } catch (e) {
        handleActionError(e)
      }
    },
    async deleteEmergencyPlaylist ({ dispatch, commit }, payload) {
      try {
        await apolloCall({ commit, mutation: DELETE_PLAYLIST_BY_ID, variables: payload, key: 'emergencyPlaylists' })
        await dispatch('getEmergencyPlaylists')
      } catch (e) {
        handleActionError(e)
      }
    },
    async widgetDisplayRestrictions ({ commit }) {
      try {
        const { listWidgetDisplayRestrictions } = await apolloCall({
          commit,
          query: LIST_WIDGET_DISPLAY_RESTRICTIONS,
        })
        commit('SET_WIDGET_DISPLAY_RESTRICTIONS', listWidgetDisplayRestrictions)
      } catch (e) {
        handleActionError(e)
      }

    },
    async getPlaylistChangelogs ({ commit }, playlistId) {
      if (!playlistId) return
      const pagination = {
        limit: 20
      }
      try {
        const { listPlaylistChangeLogPatches: { data } } = await apolloCall({
          commit,
          query: GET_PLAYLIST_CHANGELOGS,
          variables: { playlistId, pagination },
          key: 'changeLogs'
        })
        commit('SET_PLAYLIST_CHANGELOGS', data)
      } catch (e) {
        handleActionError(e)
      }
    },
    async updatePlaylist ({ commit, getters }, { input, changeLogs }) {
      const playlistId = getters.playlistId
      try {
        const { updatePlaylist } = await apolloCall({
          commit,
          mutation: UPDATE_PLAYLIST_BY_ID,
          variables: {
            id: playlistId,
            input,
            changeLogs
          },
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', updatePlaylist)
        commit('groups/UPDATE_CURRENT_GROUP_PLAYLIST', updatePlaylist, { root: true })
      } catch (e) {
        handleActionError(e)
      }
    },
    async uploadPlaylistSoundtrack ({ commit, dispatch, getters }, file) {
      const playlistId = getters.playlistId
      try {
        const { uploadPlaylistStaticMediaAudioByReference } = await apolloCall({
          commit,
          mutation: UPLOAD_PLAYLIST_SOUNDTRACK,
          variables: {
            file,
            playlistId
          },
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', { mediaAudio: uploadPlaylistStaticMediaAudioByReference })
        return uploadPlaylistStaticMediaAudioByReference
      } catch (e) {
        handleActionError(e)
      }
    },
    async deletePlaylistSoundtrack ({ commit, getters }) {
      const playlistId = getters.playlistId
      try {
        await apolloCall({
          commit,
          mutation: DELETE_PLAYLIST_SOUNDTRACK,
          variables: { playlistId },
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', { mediaAudio: null })
      } catch (e) {
        handleActionError(e)
      }
    },
    async setPlaylistSoundtrackUrl ({ commit, getters }, {name, url}) {
      const playlistId = getters.playlistId
      try {
        await apolloCall({
          commit,
          mutation: SET_PLAYLIST_SOUNDTRACK_URL,
          variables: { playlistId, name, url },
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', { mediaAudio: { metadata: { name } } })
      } catch (e) {
        handleActionError(e)
      }
    },
    async movePlaylist ({ commit, getters }, input) {
      const playlistId = getters.playlistId
      try {
        const { movePlaylistById } = await apolloCall({
          commit,
          mutation: MOVE_PLAYLIST_BY_ID,
          variables: { id: playlistId, input },
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', movePlaylistById)
      } catch (e) {
        handleActionError(e)
      }
    },
    async copyPlaylist ({ commit, getters, rootGetters }, input) {
      const currentGroupId = rootGetters['groups/currentGroupId']
      const { groupId } = input
      const playlistId = getters.playlistId
      try {
        const { copyPlaylist } = await apolloCall({
          commit,
          mutation: COPY_PLAYLIST_BY_ID,
          variables: { id: playlistId, input },
          key: 'playlist'
        })
        if (currentGroupId === groupId) {
          commit('groups/ADD_CURRENT_GROUP_PLAYLIST', copyPlaylist, { root: true })
        }
      } catch (e) {
        handleActionError(e)
      }
    },
    async deletePlaylistSlide ({ commit, getters }, { payload, zoneKey, changeLogs, zoneId }) {
      const playlistId = getters.playlistId
      zoneId = zoneId || getters.layoutZoneIdByKey(zoneKey)
      try {
        const { deletePlaylistSlide } = await apolloCall({
          commit,
          mutation: DELETE_PLAYLIST_SLIDE,
          variables: { playlistId, zoneId, ...payload, changeLogs },
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', deletePlaylistSlide)
      } catch (e) {
        handleActionError(e)
      }
    },
    async createPlaylistSlide ({ commit, getters }, { payload, zoneKey }) {
      const playlistId = getters.playlistId
      const zoneId = getters.layoutZoneIdByKey(zoneKey)
      try {
        const { addPlaylistSlide } = await apolloCall({
          commit,
          mutation: ADD_PLAYLIST_SLIDE,
          variables: { playlistId, zoneId, ...payload },
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', addPlaylistSlide)
      } catch (e) {
        handleActionError(e)
      }
    },
    async updatePlaylistSlide ({ commit, getters }, { payload, zoneKey, changeLogs, playlistId, zoneId }) {
      playlistId = playlistId || getters.playlistId
      zoneId = zoneId || getters.layoutZoneIdByKey(zoneKey)
      try {
        const { updatePlaylistSlide } = await apolloCall({
          commit,
          mutation: UPDATE_PLAYLIST_SLIDE,
          variables: { playlistId, zoneId, ...payload, changeLogs },
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', updatePlaylistSlide)
      } catch (e) {
        handleActionError(e)
      }
    },
    async updateEmergencyPlaylistSlide ({ commit, getters }, { payload, zoneKey, changeLogs, playlistId, zoneId }) {
      zoneId = zoneId || getters.layoutZoneIdByKey(zoneKey)
      try {
        const { updatePlaylistSlide } = await apolloCall({
          commit,
          mutation: UPDATE_PLAYLIST_SLIDE,
          variables: { playlistId, zoneId, ...payload, changeLogs },
          key: 'playlist'
        })
        commit('UPDATE_EMERGENCY_PLAYLIST', { playlist: updatePlaylistSlide, playlistId })
      } catch (e) {
        handleActionError(e)
      }
    },
    async movePlaylistSlide ({ commit, getters }, { payload, zoneId, changeLogs } ) {
      const playlistId = getters.playlistId
      try {
        const { movePlaylistSlide } = await apolloCall({
          commit,
          mutation: MOVE_PLAYLIST_SLIDE,
          variables: { playlistId, zoneId, ...payload, changeLogs },
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', movePlaylistSlide)
      } catch (e) {
        handleActionError(e)
      }
    },
    async exportPlaylistSlides ({ commit }, payload) {
      try {
        const { appendPlaylistZones } = await apolloCall({ commit,
          mutation: EXPORT_SLIDES_TO_PLAYLISTS,
          variables: payload,
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', appendPlaylistZones)

      } catch (e) {
        handleActionError(e)
      }
    },
    async copyPlaylistSlides ({ commit }, payload) {
      try {
        const { appendPlaylistZones } = await apolloCall({ commit,
          mutation: COPY_PLAYLIST_SLIDES,
          variables: payload,
          key: 'playlist'
        })
        commit('UPDATE_PLAYLIST', appendPlaylistZones)
      } catch (e) {
        handleActionError(e)
      }
    },
    async publishPlaylistVersion ({ dispatch, commit }, payload) {
      const { playlistId } = payload
      try {
        await apolloCall({
          commit,
          mutation: PUBLISH_PLAYLIST_VERSION,
          variables: payload,
          key: 'playlist'
        })
        dispatch('getPlaylist', playlistId)
      } catch (e) {
        handleActionError(e)
      }
    },
    async resetModule ({ commit }) {
      commit('CLEAR_PLAYLIST_DATA')
    }
  },
  getters: {
    playlist: state => state.playlist,
    emergencyPlaylists: state => state.emergencyPlaylists,
    emergencyPlaylistsLoading: state => state.loading.emergencyPlaylists,
    playlistChangelogs: state => state.playlistChangelogs,
    playlistId: state => state.playlist?.id,
    playlistIsMain: state => state.playlist?.isMainPlaylist,
    playlistLoading: state => state.loading.playlist,
    playlistTransitionType: state => state.playlist?.layout?.transitionType,
    playlistVersion: state => state.playlist?.version,
    playlistSoundtrack: state => state.playlist?.mediaAudio,
    playlistChangelogsLoading: state => state.loading.changeLogs,
    widgetDisplayRestrictions: state => {
      const restrictions = {}
      state.widgetDisplayRestrictions?.forEach((layout)=>{
        const [MAIN, ZONE_A, ZONE_B] = layout.zones.map((zone)=> {
          const { bannedWidgets = [] } = zone || {}
          return bannedWidgets.map(({widgetType, additionalData})=>{
            return {
              widgetType,
              data: additionalData
            }
          })
        })
        restrictions[layout.playlistLayoutType] = {
          MAIN, ZONE_A, ZONE_B
        }
      })
      return restrictions
    },
    layout: state => state.playlist?.layout || null,
    zoningLayoutType: (state, getters) => getters.layout?.type,
    zoningLayoutShuffle: (state, getters) => getters.layout?.shuffle,
    layoutZones: (state, getters) => getters.layout?.zones || [],
    layoutMainZone: (state, getters) => getters.layoutZones[0] || null,
    layoutZoneA: (state, getters) => getters.layoutZones[1] || null,
    layoutZoneB: (state, getters) => getters.layoutZones[2] || null,
    layoutMainZoneSlides: (state, getters) => getters.layoutMainZone?.slides || [],
    layoutZoneASlides: (state, getters) => getters.layoutZoneA?.slides || [],
    layoutZoneBSlides: (state, getters) => getters.layoutZoneB?.slides || [],
    layoutZoneIdByKey: (state, getters) => zoneKey => {
      const index = LAYOUT_ZONE_KEYS_TO_INDEX[zoneKey]
      return index !== undefined ? getters.layoutZones[index]?.id || null : null
    }
  },
  mutations: {
    SET_LOADING_STATUS (state, {
      status,
      key
    }) {
      state.loading[key] = status
    },
    SET_PLAYLIST (state, playlist) {
      state.playlist = playlist
      state.loading.playlist = false
    },
    SET_EMERGENCY_PLAYLISTS (state, playlists) {
      state.emergencyPlaylists = playlists
      state.loading.emergencyPlaylists = false
    },
    SET_WIDGET_DISPLAY_RESTRICTIONS (state, widgetDisplayRestrictions) {
      state.widgetDisplayRestrictions = widgetDisplayRestrictions
    },
    SET_PLAYLIST_CHANGELOGS (state, changelogs) {
      state.playlistChangelogs = changelogs
      state.loading.playlist = false
    },
    UPDATE_PLAYLIST (state, playlist) {
      state.playlist = { ...state.playlist, ...playlist }
      state.loading.playlist = false
    },
    UPDATE_EMERGENCY_PLAYLIST (state, { playlist, playlistId }) {
      state.emergencyPlaylists = state.emergencyPlaylists.map((p) => {
        if (p.id === playlistId) {
          return playlist
        }
        return p
      })
      state.loading.emergencyPlaylists = false
    },
    CLEAR_PLAYLIST_DATA (state) {
      state.playlist = null
      state.loading.playlist = false
    }
  }
}
