<template>
  <a-modal
    :open="showGlobalSettings"
    :title="$t('components.globalSettingsModal.title')"
    width="960px"
    style="top: 20px"
    wrap-class-name="full-height-modal global-settings-modal"
    @ok="closeSettings"
    @cancel="closeSettings"
  >
    <a-layout>
      <a-layout-sider
        style="background-color: #fff; overflow-x: auto"
        width="230"
      >
        <a-menu
          theme="light"
          mode="inline"
          :selected-keys="selectedKeys"
        >
          <a-menu-item-group>
            <template #title>
              {{ $t('components.globalSettingsModal.account') }}
            </template>
            <a-menu-item
              v-for="menuItem in accountMenuItems"
              :key="menuItem.key"
              @click="selectTab(menuItem.key)"
            >
              <template #icon>
                <component :is="menuItem.icon" />
              </template>
              <span style="text-transform: capitalize">{{ $t(`components.globalSettingsModal.${menuItem.key}`) }}</span>
            </a-menu-item>
          </a-menu-item-group>
          <a-menu-item-group v-if="showWorkspaceMenu">
            <template #title>
              {{ $t('components.globalSettingsModal.workspace') }}
            </template>
            <a-menu-item
              v-for="menuItem in workspaceMenuItems"
              :key="menuItem.key"
              @click="selectTab(menuItem.key)"
            >
              <template #icon>
                <component :is="menuItem.icon" />
              </template>
              <span style="text-transform: capitalize">{{ $t(`components.globalSettingsModal.${menuItem.key}`) }}</span>
            </a-menu-item>
          </a-menu-item-group>
        </a-menu>
      </a-layout-sider>
      <a-layout-content style="background-color: #F8FAFC; padding: 16px; overflow-x: auto">
        <transition
          name="fade-fast"
          mode="out-in"
        >
          <component
            :is="currentTab?.component"
            v-if="currentTab"
          />
        </transition>
      </a-layout-content>
    </a-layout>
    <template #footer>
      <a-button
        type="primary"
        @click="closeSettings"
      >
        {{ $t('components.globalSettingsModal.closeButtonText') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { ACCOUNT_MENU_ITEMS, WORKSPACE_MENU_ITEMS } from '@/constants'
import GENERAL_MENU_COMPONENTS from '@/components/globalSettings/index'
import { ACCOUNT_MENU_ICONS, WORKSPACE_MENU_ICONS } from '@/helpers/Icons'

const menuItemsMap = Object.fromEntries([...ACCOUNT_MENU_ITEMS, ...WORKSPACE_MENU_ITEMS].map((menuItem) => [menuItem.key, menuItem]))

export default defineComponent({
  name: 'GlobalSettingsModal',
  components: {
    ...GENERAL_MENU_COMPONENTS,
    ...ACCOUNT_MENU_ICONS,
    ...WORKSPACE_MENU_ICONS
  },
  setup () {
    const store = useStore()
    const user = computed(() => store.getters['user/user'])
    const globalSettingsActiveTab = computed(() => store.getters.globalSettingsActiveTab)
    const showGlobalSettings = computed(() => store.getters.isModalVisible('globalSettings'))
    const whitelabelEnabled = computed(() => store.getters['workspace/features/whitelabelEnabled'])
    const externalApiEnabled = computed(() => store.getters['workspace/features/externalApiEnabled'])
    const isAdmin = computed(() => store.getters['workspace/role/isAdmin'])
    const isOwner = computed(() => store.getters['workspace/role/isOwner'])
    const selectedKeys = ref([store.getters.globalSettingsActiveTab])
    const currentTab = computed(() => {
      const [tabKey] = selectedKeys.value
      return menuItemsMap[tabKey] || null
    })

    const showWorkspaceMenu = computed(()=> isAdmin.value || isOwner.value)

    const workspaceMenuItems = computed(() => {
      let menuItems = WORKSPACE_MENU_ITEMS
      if (!whitelabelEnabled.value) {
        menuItems = menuItems.filter(i => i.key !== 'customBranding')
      }

      if (!externalApiEnabled.value) {
        menuItems = menuItems.filter(i => i.key !== 'apiKeys')
      }
      return menuItems
    })

    const workspace = computed(() => store.getters['workspace/workspace'])

    const closeSettings = () => {
      store.dispatch('closeModal', 'globalSettings')
    }
    const selectTab = (tab) => {
      store.commit('CHANGE_GLOBAL_SETTINGS_TAB', tab)
    }

    watch(globalSettingsActiveTab, (newValue) => {
      selectedKeys.value = [newValue]
    })
    watch(showGlobalSettings, (value) => {
      if (value) {
        selectedKeys.value = [globalSettingsActiveTab.value]
      }
    })

    return {
      user,
      workspace,
      currentTab,
      selectedKeys,
      showGlobalSettings,
      workspaceMenuItems,
      showWorkspaceMenu,
      accountMenuItems: ACCOUNT_MENU_ITEMS,
      closeSettings,
      selectTab
    }
  }
})
</script>

<style lang="less">
.global-settings-modal {
  .ant-modal-body {
    padding: 0;
    .ant-layout {
      height: 100%;
    }
  }
}
</style>
