<script setup>
import { computed, reactive, watch } from 'vue'
import KitcastNotification from '@/components/icons/KitcastNotification.vue'
import { useStore } from 'vuex'
import UserRoleChange from '@/components/notifications/UserRoleChange.vue'
import MediaDeleted from '@/components/notifications/MediaDeleted.vue'
import MediaPendingAutoDelete from '@/components/notifications/MediaPendingAutoDelete.vue'
import DevicesStatusesChanged from '@/components/notifications/DevicesStatusesChanged.vue'
import PaymentFailed from '@/components/notifications/paymentFailed.vue'
import RoleRestrictionsUpdated from '@/components/notifications/RoleRestrictionsUpdated.vue'
import MediaCleanupInUse from '@/components/notifications/MediaCleanupInUse.vue'
import SubscriptionUpdated from '@/components/notifications/SubscriptionUpdated.vue'

const NOTIFICATIONS_PER_PAGE = 10

const componentMap = {
  'USER_ROLE_CHANGED': UserRoleChange,
  'MEDIA_DELETED': MediaDeleted,
  'WORKSPACE_USER_ROLE_RESTRICTIONS_UPDATED': RoleRestrictionsUpdated,
  'MEDIA_PENDING_AUTO_DELETE': MediaPendingAutoDelete,
  'MEDIA_AUTO_DELETE_IN_USE': MediaCleanupInUse,
  'DEVICE_STATUSES_CHANGED': DevicesStatusesChanged,
  'WORKSPACE_SUBSCRIPTION_UPDATED': SubscriptionUpdated,
  'WORKSPACE_SUBSCRIPTION_PAYMENT_FAILED': PaymentFailed,
}

const mapNotification = ({createdAt, data, type} = {}) => {
  return {
    createdAt,
    data,
    component: componentMap[type] || null
  }
}

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

defineEmits(['update:visible'])

const store = useStore()
const pagination = computed(()=> store.getters['notifications/notificationsPagination'])
const notifications = computed(()=> store.getters['notifications/notificationsData']?.map(mapNotification))
const notificationsLoading = computed(()=> store.getters['notifications/notificationsLoading'])
const notificationsTotal = computed(()=> pagination.value?.total)
const paginationState = reactive({
  limit: NOTIFICATIONS_PER_PAGE,
  offset: 0
})

const hasMore = computed(()=>{
  return notificationsTotal.value > paginationState.offset + NOTIFICATIONS_PER_PAGE
})

const loadMore = () => {
  if (!hasMore.value) return
  paginationState.offset+=NOTIFICATIONS_PER_PAGE
  fetchNotifications()
}

const fetchNotifications = () => {
  store.dispatch('notifications/getNotifications', {pagination: paginationState})
}

watch(()=>props.visible, (value)=>{
  fetchNotifications()
})

</script>

<template>
  <a-drawer
    :width="508"
    :title="$t('components.notificationsSider.title')"
    :placement="'left'"
    :open="visible"
    @close="$emit('update:visible', !visible)"
  >
    <a-list
      item-layout="horizontal"
      :data-source="notifications"
      :loading="notificationsLoading"
    >
      <template #loadMore>
        <div
          v-if="!notificationsLoading && hasMore"
          :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
        >
          <a-button @click="loadMore">
            {{ $t('components.notificationsSider.loadMoreButtonText') }}
          </a-button>
        </div>
      </template>
      <template #renderItem="{ item }">
        <a-list-item v-if="item.component">
          <a-list-item-meta>
            <template
              #description
            >
              <component
                :is="item.component"
                :data="item.data"
              />
            </template>
            <template #avatar>
              <a-avatar
                :size="48"
                style="border: 0"
              >
                <template #icon>
                  <KitcastNotification />
                </template>
              </a-avatar>
            </template>
          </a-list-item-meta>
          <div class="notification-date">
            <a-typography-text type="secondary">
              <span v-format-date="{date: item.createdAt}" />
            </a-typography-text>
          </div>
        </a-list-item>
      </template>
    </a-list>
  </a-drawer>
</template>

<style scoped lang="less">
.notification-date {
  width: 100px;
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
  justify-content: flex-end;
}
.break-all {
  word-break: break-all;
}
</style>
