<script setup>

import { useStore } from 'vuex'

const store = useStore()
defineProps({
  data: {
    type: Object,
    required: true
  }
})

const openUpdatePaymentMethod = () => {
  store.dispatch('openModal', { modal: 'updatePaymentMethod'})
}
</script>

<template>
  <template v-if="data.attemptNumber === 1">
    {{ $t('components.notificationsSider.paymentFailedFirstAttempt') }}
  </template>
  <template v-else>
    {{ $t('components.notificationsSider.paymentFailedLastAttempt') }}
  </template>
  <a-typography-text
    class="underline-link"
    @click="openUpdatePaymentMethod"
  >
    {{ $t('components.notificationsSider.updatePaymentInfo') }}
  </a-typography-text>
</template>

<style scoped lang="less">

</style>
