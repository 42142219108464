<template>
  <div>
    <a-list
      item-layout="horizontal"
    >
      <div class="dragArea list-group">
        <template
          v-for="element in zoneSlides"
          :key="element.id"
        >
          <SlideCard
            v-if="isSlide(element)"
            :key="element.id+element.updateTime"
            :unused-in-current-layout-zone="(slideHasDisplayRestrictions(element))"
            :slide="element"
            :playlist-id="playlistId"
            :hide-pause="true"
            :hide-export="true"
            :hide-duration="true"
            :hide-move="true"
            :hide-duplicate="true"
            :main-zone="true"
            :zone-key="zoneKey"
            layout-type="V"
            @click="$emit('slide-click', { slide: element, e: $event, zoneKey})"
            @delete-slide="$emit('slide-delete', {slide: $event, zoneKey})"
            @mute-slide="$emit('slide-mute', {...$event, zoneKey})"
            @change-object-fit="$emit('slide-object-fit-change', {...$event, zoneKey})"
          />
        </template>
      </div>
    </a-list>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Slide } from '@/helpers/Slides'
import SlideCard from '@/components/slideCard/SlideCard.vue'
import { matches } from 'lodash'

export default defineComponent({
  name: 'EmergencyPlaylistZone',
  components: {
    SlideCard
  },
  props: {
    widgetsDisplayRestrictions: Object,
    zoneSlides: Array,
    zoneKey: String,
    playlistId: String
  },
  emits: [
    'slide-click',
    'slide-delete',
    'slide-object-fit-change',
    'slide-mute'
  ],
  setup (props) {

    const isSlide = (element) => {
      return element instanceof Slide
    }

    const slideHasDisplayRestrictions = (slide) => {
      if (!props.widgetsDisplayRestrictions) return false
      return !!props.widgetsDisplayRestrictions
          .find(rule => rule.widgetType === slide.widget?.type
              && (!rule.data || (matches(rule.data))(slide.slideData)))
    }

    return {
      slideHasDisplayRestrictions,
      isSlide
    }
  }
})
</script>

<style scoped lang="less">
@import '../../styles/variables';
</style>
