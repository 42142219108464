<template>
  <a-spin
    tip="Verifying..."
    class="spinner"
    :spinning="loading"
  />
  <a-result
    v-if="isError"
    status="warning"
    :title="$t('components.verifyEmailPage.errorTitle')"
    :sub-title="$t('components.verifyEmailPage.errorText')"
  >
    <template #extra>
      <a-button
        type="primary"
        size="large"
        @click="redirectToDashboard"
      >
        {{ $t('components.verifyEmailPage.backButtonText') }}
      </a-button>
    </template>
  </a-result>
</template>

<script>
import { computed, defineComponent, onMounted, ref, toRef } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'VerifyEmail',
  props: {
    token: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()
    const loading = ref(true)
    const isError = ref(false)
    const token = toRef(props, 'token')
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])

    onMounted(async () => {
      if (token.value) {
        store.dispatch('user/verifyEmail', token.value).then(async ()=>{
          if (isAuthenticated.value) {
            return redirectToDashboardWithConfirmedEmail()
          }
          else {
            return redirectToLoginWithConfirmedEmail()
          }
        }).catch(e => {
          loading.value = false
          isError.value = true
        })
      }
      else {
        return redirectToDashboard()
      }
    })

    const redirectToDashboard = () => {
      return router.replace({ name: 'Home' })
    }

    const redirectToDashboardWithConfirmedEmail = () => {
      return router.replace({ name: 'Home', query: { 'email-confirmed': 'true'} })
    }

    const redirectToLoginWithConfirmedEmail = () => {
      return router.replace({ name: 'EmailConfirmed' })
    }

    return {
      loading,
      isError,
      redirectToDashboard
    }
  }
})
</script>

<style scoped>
.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
