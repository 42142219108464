import ApolloClient from '@/apollo'
import { GET_NOTIFICATIONS_LIST, GET_UNSEEN_NOTIFICATIONS_COUNT, } from '@/graphql/queries'
import { MARK_ALL_NOTIFICATIONS_AS_SEEN, } from '@/graphql/mutations'
import { ON_NEW_NOTIFICATIONS_TRIGGER } from '@/graphql/subscriptions'
import { apolloCall } from '@/helpers/Graphql'

export default {
  namespaced: true,
  state: {
    unseenNotificationsCountSubscription: null,
    unseenNotificationsCount: 0,
    notifications: null,
    loading: {
      notifications: false
    }
  },
  actions: {
    async getUnreadNotificationsCount ({ commit }) {
      const { getUnseenNotificationsCount } = await apolloCall({
        query: GET_UNSEEN_NOTIFICATIONS_COUNT
      })
      commit('SET_UNSEEN_NOTIFICATIONS_COUNT', getUnseenNotificationsCount)
    },
    async subscribeToUnreadNotifications ({ commit, dispatch }) {
      const sub = ApolloClient.subscribe({ query: ON_NEW_NOTIFICATIONS_TRIGGER })
        .subscribe(({ data: { onNewNotificationsTrigger: { needReload } }, loading }) => {
          needReload && dispatch('getUnreadNotificationsCount')
        })
      commit('SET_NOTIFICATIONS_COUNT_SUBSCRIPTION', sub)
    },
    async getNotifications ({ commit, dispatch, getters }, {pagination = { limit: 10}}) {
      const { offset } = pagination
      const { listNotifications: { data, pagination: _pagination } } = await apolloCall({
        commit,
        query: GET_NOTIFICATIONS_LIST,
        variables: {pagination},
        key: 'notifications'
      })
      commit(offset ? 'ADD_NOTIFICATIONS' : 'SET_NOTIFICATIONS', { data, pagination: _pagination })
      getters.unseenNotificationsCount && dispatch('markAllNotificationsAsSeen')
    },
    async markAllNotificationsAsSeen ({ commit }) {
      await apolloCall({
        mutation: MARK_ALL_NOTIFICATIONS_AS_SEEN
      })
      commit('SET_UNSEEN_NOTIFICATIONS_COUNT', 0)
    },
    async resetModule ({ commit }) {
      commit('CLEAR_NOTIFICATIONS')
    }
  },
  getters: {
    unseenNotificationsCount: state => state.unseenNotificationsCount,
    notificationsData: state => state.notifications?.data,
    notificationsPagination: state => state.notifications?.pagination,
    notificationsLoading: state => state.loading.notifications,
  },
  mutations: {
    SET_UNSEEN_NOTIFICATIONS_COUNT (state, value) {
      state.unseenNotificationsCount = value
    },
    SET_NOTIFICATIONS (state, { data, pagination }) {
      state.notifications = { data, pagination }
    },
    ADD_NOTIFICATIONS (state, { data, pagination }) {
      state.notifications = { data: [...state.notifications?.data, ...data], pagination }
    },
    SET_LOADING_STATUS (state, {
      status,
      key
    }) {
      state.loading[key] = status
    },
    SET_NOTIFICATIONS_COUNT_SUBSCRIPTION (state, subscription) {
      state.unseenNotificationsCountSubscription = subscription
    },
    CLEAR_NOTIFICATIONS (state) {
      state.notifications = null
      state.unseenNotificationsCountSubscription?.unsubscribe()
      state.unseenNotificationsCountSubscription = null
    }
  }
}
