<script setup>

import { computed, ref } from 'vue'
import { error, success } from '@/utils'
import { useStore } from 'vuex'
import { copyText } from 'vue3-clipboard'
import { useI18n } from 'vue-i18n'
import { ROLES } from '@/constants'

const store = useStore()
const { t } = useI18n()

const props = defineProps({
  invites: {
    type: Array,
    default: () => []
  },
  userFilter: {
    type: String,
    default: ''
  },
  availableGroupsIdMap: {
    type: Object,
    required: true
  },
  availableGroupsPlaylistsIdMap: {
    type: Object,
    required: true
  }
})

const RESTRICTION_NAMES_MAP = {
  [ROLES.MANAGER]: 'Group(s)',
  [ROLES.AUTHOR]: 'Playlist(s)'
}

const INVITE_COLUMNS = [
  {
    title: 'email',
    key: 'email',
    dataIndex: 'email'
  },
  {
    title: 'role',
    key: 'role',
    dataIndex: 'role',
    width: 100
  },
  {
    title: 'access',
    key: 'access',
    dataIndex: 'access',
    width: 150
  },
  {
    title: 'sent',
    key: 'sent',
    dataIndex: 'sent',
    width: 180
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    width: 200
  }]


const loading = computed(() => store.getters['workspace/invites/invitesLoading'])

const filteredInvites = computed(() => {
  return props.invites.filter(invite => invite.email.includes(props.userFilter.toLowerCase()))
})

const resendInvite = (inviteId) => {
  store.dispatch('workspace/invites/resendInvite', inviteId).then(() => {
    success()
  }).catch(e => {
    error(e.message)
  })
}

const cancelInvite = (email) => {
  store.dispatch('workspace/invites/cancelInvite', email).then(() => {
    success()
  }).catch(e => {
    error(e.message)
  })
}

const copyInviteLink = (inviteId) => {
  getInviteLink(inviteId).then((link) => {
    copyText(link, undefined, () => {})
    success()
  }).catch(e => {
    error(e.message)
  })
}

const getInviteLink = (inviteId) => {
  return store.dispatch('workspace/invites/getInviteLink', inviteId)
}

</script>

<template>
  <a-table
    :columns="INVITE_COLUMNS"
    :data-source="filteredInvites"
    :loading="loading"
  >
    <template #headerCell="{ column }">
      <template v-if="column.key">
        {{ $t(`components.collaborationModal.${column.key}`) }}
      </template>
    </template>
    <template #bodyCell="{ text, column, record }">
      <template v-if="column.dataIndex === 'actions'">
        <a-typography-link @click="resendInvite(record.id)">
          {{ $t('components.collaborationModal.resendLink') }}
        </a-typography-link>
        <a-divider type="vertical" />
        <a-tooltip>
          <template #title>
            {{ $t('components.collaborationModal.copyLinkTooltipTitle') }}
          </template>
          <a-typography-link
            @click="copyInviteLink(record.id)"
          >
            {{ $t('components.collaborationModal.copyLink') }}
          </a-typography-link>
        </a-tooltip>
        <a-divider type="vertical" />
        <a-typography-link
          type="danger"
          @click="cancelInvite(record.email)"
        >
          {{ $t('components.collaborationModal.cancelLink') }}
        </a-typography-link>
      </template>
      <template v-else-if="column.dataIndex === 'role'">
        <span style="text-transform: capitalize">{{ $t(`components.collaborationModal.${text?.toLowerCase()}Role`) }}</span>
      </template>
      <template v-else-if="column.dataIndex === 'access'">
        <template v-if="record.role === ROLES.MANAGER">
          <a-tooltip v-if="record.restrictions?.length">
            <template #title>
              <div
                v-for="restrictionId in record.restrictions"
                :key="restrictionId"
              >
                {{ availableGroupsIdMap[restrictionId] }}
              </div>
            </template>
            {{ `${record.restrictions.length} ${RESTRICTION_NAMES_MAP[record.role]}` }}
          </a-tooltip>
          <template v-else>
            {{ $t('components.collaborationModal.all') }}
          </template>
        </template>
        <template v-else-if="record.role === ROLES.AUTHOR">
          <a-tooltip v-if="record.restrictions?.length">
            <template #title>
              <div
                v-for="restrictionId in record.restrictions"
                :key="restrictionId"
              >
                {{ availableGroupsPlaylistsIdMap[restrictionId] }}
              </div>
            </template>
            {{ `${record.restrictions.length} ${RESTRICTION_NAMES_MAP[record.role]}` }}
          </a-tooltip>
          <template v-else>
            {{ $t('components.collaborationModal.all') }}
          </template>
        </template>
        <template v-else>
          {{ $t('components.collaborationModal.all') }}
        </template>
      </template>
    </template>
  </a-table>
</template>

<style scoped lang="less">

</style>
