<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue'
import { cloneDeep } from 'lodash'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()
const initialQuery = ref(null)
const isAdmin = computed(() => store.getters['workspace/role/isAdmin'])
const isOwner = computed(() => store.getters['workspace/role/isOwner'])
const emailVerified = computed(() => store.getters['user/emailVerified'])

onMounted(async () => {
  initialQuery.value = cloneDeep(route.query)
})

const handleInitialQuery = () => {
  if (initialQuery.value?.settings && (isAdmin.value || isOwner.value)) {
    switch (initialQuery.value.settings) {
      case 'subscription':
        store.dispatch('openModal', { modal: 'globalSettings' , options: { activeTab: 'subscription' }})
        break
      case 'upgrade':
        if (emailVerified.value) {
          store.dispatch('handleUpdateSubscription')
        }
        break
    }
    initialQuery.value = null
  }
}

watchEffect(() => {
  handleInitialQuery()
})

</script>

<template>
  <none />
</template>

<style scoped lang="less">

</style>
