<template>
  <a-modal
    :open="showAuthorizedAccounts"
    width="960px"
    style="top: 20px"
    wrap-class-name="full-height-modal authorized-accounts-modal"
    @ok="closeModal"
    @cancel="closeModal"
  >
    <template #title>
      <ModalHeading
        :title="$t('components.authorizedAccountsModal.title')"
        :subtitle="$t('components.authorizedAccountsModal.subtitle')"
      />
    </template>
    <a-layout style="overflow: hidden">
      <a-layout-content style="background-color: #fff; overflow-x: auto">
        <a-row
          type="flex"
          justify="space-between"
          class="authorized-accounts-filter-bar"
        >
          <a-col span="12">
            <a-input-search
              v-model:value="socialFilter"
              :placeholder="$t('components.authorizedAccountsModal.filterPlaceholder')"
              style="width: 300px"
            />
          </a-col>
          <a-col>
            <AddSocialAccountButton />
          </a-col>
        </a-row>
        <a-table
          :columns="columns"
          :data-source="socialAccounts"
          :loading="socialAccountsLoading"
        >
          <template #headerCell="{ column }">
            <template v-if="column.key">
              {{ $t(`components.authorizedAccountsModal.${column.key}`) }}
            </template>
          </template>
          <template #bodyCell="{ column, record, text }">
            <template v-if="column.dataIndex === 'type'">
              <span style="text-transform: capitalize">{{ text.toLowerCase() }}</span>
            </template>
            <template v-if="column.dataIndex === 'name'">
              {{ text || record.error }}
            </template>
            <template v-else-if="column.dataIndex === 'edit'">
              <div style="display: flex; gap: 4px; justify-content: center">
                <a-tooltip :title="$t('components.authorizedAccountsModal.unlink')">
                  <a-button @click="onSocialAccountUnlink(record.id)">
                    <template #icon>
                      <DisconnectOutlined style="font-size: 12px;" />
                    </template>
                  </a-button>
                </a-tooltip>
              </div>
            </template>
          </template>
        </a-table>
      </a-layout-content>
    </a-layout>
    <template #footer>
      <a-button
        type="primary"
        @click="closeModal"
      >
        {{ $t('components.authorizedAccountsModal.closeButtonText') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed, createVNode, defineComponent, ref } from 'vue'
import { getAffectedPlaylistsCount, success } from '@/utils'
import { useStore } from 'vuex'
import { toStandard } from '@/helpers/SocialAccount'
import AddSocialAccountButton from '@/components/AddSocialAccountButton.vue'
import { Modal } from 'ant-design-vue'
import { DisconnectOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'
import ModalHeading from '@/components/ModalHeading.vue'
import { useRoute } from 'vue-router'

const COLUMNS = [
  {
    title: 'Platform',
    key: 'platform',
    dataIndex: 'type',
    width: 140
  },
  {
    title: 'Account',
    key: 'account',
    dataIndex: 'name'
  },
  {
    title: 'Edit',
    key: 'edit',
    dataIndex: 'edit',
    align: 'center',
    width: 120
  }
]

export default defineComponent({
  name: 'AuthorizedAccountsModal',
  components: {
    ModalHeading,
    AddSocialAccountButton,
    DisconnectOutlined
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const { t } = useI18n()
    const socialFilter = ref('')
    const accounts = computed(() => store.getters['social/socialAccounts'].map(toStandard))
    const socialAccountsLoading = computed(() => store.getters['social/socialAccountsLoading'])
    const showAuthorizedAccounts = computed(() => store.getters.isModalVisible('authorizedAccounts'))


    const socialAccounts = computed(() => {
      return accounts.value?.filter(sa => sa.name?.toLowerCase().includes(socialFilter.value.toLowerCase()) ||
          sa?.type.toLowerCase().includes(socialFilter.value.toLowerCase()))
    })

    const onSocialAccountUnlink = (id) => {
      const payload = { id }
      unlinkSocialAccount({ id }).then(() => {
        success()
      }).catch((e) => {
        const affectedPlaylistsCount = getAffectedPlaylistsCount(e)
        if (!affectedPlaylistsCount) {
          return error(e.message || 'Error')
        }
        return getConfirmationModal(affectedPlaylistsCount, function () {
          payload.force = true
          unlinkSocialAccount(payload)
            .then(()=>{
              if (route.name === 'PlaylistPage') {
                store.dispatch('playlist/refreshPlaylist')
              }
              success()
            })
            .catch((e) => {
              error(e.message || 'Error')
            })
        })
      })

    }

    const getConfirmationModal = (affectedPlaylistsCount, onOk) => {
      return Modal.confirm({
        title: t('components.authorizedAccountsModal.accountUsedInNPlaylists', {count: affectedPlaylistsCount}),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('components.authorizedAccountsModal.deleteAccountDisclaimer', {count: affectedPlaylistsCount}),
        onOk () {
          onOk()
        }
      })
    }

    const unlinkSocialAccount = (payload) => {
      return store.dispatch('social/unlinkSocialAccount', payload)
    }

    const closeModal = () => {
      store.dispatch('closeModal', 'authorizedAccounts')
    }

    return {
      columns: COLUMNS,
      socialAccounts,
      socialFilter,
      socialAccountsLoading,
      showAuthorizedAccounts,
      onSocialAccountUnlink,
      closeModal
    }
  }
})
</script>

<style lang="less">
.authorized-accounts-modal {
  .authorized-accounts-filter-bar {
    position: sticky;
    z-index: 1;
    top: -24px;
    padding-bottom: 16px;
    padding-top: 24px;
    margin-top: -24px;
    background-color: #fff;
  }
  .ant-modal-body {
    padding: 0;
    .ant-layout {
      height: 100%;
    }
  }
}
</style>
