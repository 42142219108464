<script setup>
import { useStore } from 'vuex'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { computed } from 'vue'

const store = useStore()
const paymentMethod = computed(() => store.getters['subscription/paymentMethod'])

const updatePaymentMethod = () => {
  store.dispatch('closeModal', 'globalSettings')
  store.dispatch('openModal', { modal: 'updatePaymentMethod'})
}

</script>

<template>
  <span @click="updatePaymentMethod">
    <template v-if="paymentMethod">
      <a-typography-link>
        {{ $t('components.subscriptionSettingsManageBlockAddChangePaymentMethodLink.changePaymentMethodLink') }}
      </a-typography-link>&nbsp;
      <a-typography-text
        type="secondary"
      >
        {{ paymentMethod?.card?.brand }} ••••{{ paymentMethod?.card?.last4 }}
      </a-typography-text>
    </template>
    <template v-else>
      <a-typography-link>
        {{ $t('components.subscriptionSettingsManageBlockAddChangePaymentMethodLink.addPaymentMethodLink') }}
      </a-typography-link>
      <a-tooltip
        :title="$t('components.subscriptionSettingsManageBlockAddChangePaymentMethodLink.addPaymentMethodTooltip')"
      >
        <InfoCircleOutlined style="margin-left: 8px;" />
      </a-tooltip>
    </template>
  </span>
</template>

<style scoped lang="less">

</style>
