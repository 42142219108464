<template>
  <a-modal
    :open="showCreateWorkspaceModal"
    :title="'Create Workspace'"
    :ok-button-props="{ disabled: workspaceName.length <= 3}"
    :ok-text="$t('components.createWorkspaceModal.okText')"
    :cancel-text="$t('components.createWorkspaceModal.cancelText')"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form
      layout="vertical"
      @submit.prevent="handleSubmit"
    >
      <a-form-item
        :label="$t('components.createWorkspaceModal.workspaceNameLabel')"
        name="workspaceName"
      >
        <a-input
          ref="nameInput"
          v-model:value="workspaceName"
          :placeholder="$t('components.createWorkspaceModal.workspaceNamePlaceholder')"
        />
      </a-form-item>
      <input
        type="submit"
        style="visibility: hidden;"
      >
    </a-form>
  </a-modal>
</template>

<script>
import { computed, defineComponent, nextTick, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'CreateWorkspaceModal',
  setup () {

    const store = useStore()
    const workspaceName = ref('')
    const showCreateWorkspaceModal = computed(() => store.getters.isModalVisible('createWorkspace'))
    const nameInput = ref()

    const handleSubmit = () => {
      if (workspaceName.value?.length <= 3) return
      store.dispatch('workspace/createAndChangeWorkspace', {
        name: workspaceName.value
      }).finally(()=>{
        workspaceName.value = ''
        store.dispatch('closeModal', 'createWorkspace')
      })
    }

    const handleCancel = () => {
      workspaceName.value = ''
      store.dispatch('closeModal', 'createWorkspace')
    }

    watchEffect(()=>{
      if (showCreateWorkspaceModal.value) {
        nextTick(()=>{
          nameInput.value?.focus()
        })
      }
    })


    return {
      workspaceName,
      nameInput,
      showCreateWorkspaceModal,
      handleSubmit,
      handleCancel
    }
  }
})
</script>

<style scoped>

</style>
