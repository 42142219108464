<script setup>
import { ref, onMounted } from 'vue'
import { ACCEPTED_MIME_TYPES } from '@/constants'

const props = defineProps({
  config: Object,
  multipleSelect: {
    type: Boolean,
    default: false
  },
  allowedTypes: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['picked'])

const pickerApiLoaded = ref(false)

const openPicker = (accessToken = null) => {
  if (!window.gapi) {
    console.warn('Google API not loaded')
    return
  }

  if (pickerApiLoaded.value && accessToken) {
    const allowedTypes = props.allowedTypes ? ACCEPTED_MIME_TYPES[props.allowedTypes] : ACCEPTED_MIME_TYPES.all
    const view = new google.picker.DocsView()
        .setIncludeFolders(true)
        .setMimeTypes(allowedTypes)

    const pickerBuilder = new google.picker.PickerBuilder()
        .setDeveloperKey(props.config.developerKey)
        .setAppId(props.config.appId)
        .setOAuthToken(accessToken)
        .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
        .addView(view)
        .setCallback(pickerCallback)
    if (props.multipleSelect) {
      pickerBuilder.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
    }
    const picker = pickerBuilder.build()
    picker.setVisible(true)
  }
}

const pickerCallback = (data) => {
  if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
    emit('picked', data)
  }
}

onMounted(() => {
  if (!window.gapi) {
    console.warn('Google API not loaded')
    return
  }
  window.gapi.load('picker', () => {
    pickerApiLoaded.value = true
  })
})

defineExpose({
  openPicker
})

</script>

<template>
  <button
    :disabled="!pickerApiLoaded"
    @click="openPicker"
  >
    <slot />
  </button>
</template>
