<script setup>

import TallyModal from '@/components/TallyModal.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const showModal = computed(() => store.getters.isModalVisible('eduAppliance'))
const userEmail = computed(() => store.getters['user/userEmail'])
const fullName = computed(() => store.getters['user/fullName'])
const phone = computed(() => store.getters['user/phone'])
const workspaceId = computed(() => store.getters['workspace/workspaceId'])
const workspaceName = computed(() => store.getters['workspace/workspaceName'])
const companyName = computed(() => store.getters['user/companyName'])
const escapedEmail = computed(() => encodeURIComponent(userEmail.value))
const prefilledFields = computed(() => {
  return `&email=${escapedEmail.value}&workspaceId=${workspaceId.value}&workspaceName=${workspaceName.value}&fullName=${fullName.value}&phone=${phone.value}&companyName=${companyName.value}`
})
const src = 'https://tally.so/embed/npWGOV?dynamicHeight=1'

const tallyUrlWithPrefilledFields = computed(() => {
  return `${src}${prefilledFields.value}`
})

const handleCancel = () => {
  store.dispatch('closeModal', 'eduAppliance')
  store.dispatch('workspace/updateEDUAppliance', { shown: true})
}

const handleClose = () => {
  store.dispatch('closeModal', 'eduAppliance')
}

const handleFormSubmit = () => {
  store.dispatch('workspace/updateEDUAppliance', { applied: true})
}

</script>

<template>
  <TallyModal
    :open="showModal"
    :src="tallyUrlWithPrefilledFields"
    @cancel="handleCancel"
    @close="handleClose"
    @submit="handleFormSubmit"
  />
</template>

<style scoped lang="less">

</style>
