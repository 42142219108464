<template>
  <div id="watermark-settings">
    <a-typography-title :level="5">
      {{ $t('components.watermarkSettings.title') }}
    </a-typography-title>
    <a-divider />
    <a-col
      v-if="isTrialTier && !forcedWatermarkToggleEnabled"
      :span="24"
    >
      <a-alert
        type="info"
        style="margin-bottom: 16px;"
      >
        <template #description>
          {{ $t('components.watermarkSettings.inDemoMode') }}
          <a-typography-link
            v-if="emailVerified"
            @click.stop.prevent="openUpgradeSubscriptionModal"
          >
            {{ $t('components.watermarkSettings.upgradeLink') }}
          </a-typography-link>
          <template v-else>
            <i>{{ $t('components.watermarkSettings.verifyText') }}</i>
          </template>
        </template>
      </a-alert>
    </a-col>
    <a-form layout="vertical">
      <a-form-item name="showWatermark">
        <a-checkbox
          :checked="useCustomWatermark || watermarkToggleDisabled ? false : !workspaceHideWatermark"
          :disabled="watermarkToggleDisabled || useCustomWatermark"
          @change="toggleWatermark"
        >
          {{ $t('components.watermarkSettings.displayWatermark') }}
        </a-checkbox>
      </a-form-item>
      <a-form-item name="showCustomWatermark">
        <a-checkbox
          :checked="useCustomWatermark"
          :disabled="isTrialTier || !customWatermarkEnabled"
          @change="toggleCustomWatermark"
        >
          {{ $t('components.watermarkSettings.customWatermarkCheckbox') }}
        </a-checkbox>
      </a-form-item>
    </a-form>
    <div v-if="useCustomWatermark && customWatermarkEnabled">
      <a-divider />
      <a-typography-title :level="5">
        {{ $t('components.watermarkSettings.customWatermark') }}
      </a-typography-title>
      <a-upload
        name="watermark"
        list-type="picture-card"
        class="watermark-uploader"
        :show-upload-list="false"
        :before-upload="beforeWatermarkUpload"
        :accept="acceptedImageTypes"
      >
        <template v-if="customWatermark && watermarkLoading">
          <LoadingOutlined v-if="watermarkLoading" />
        </template>
        <template v-else-if="customWatermark">
          <div class="watermark-actions">
            <a-space>
              <a-button
                shape="circle"
                type="dashed"
                size="small"
              >
                <template #icon>
                  <edit-outlined />
                </template>
              </a-button>
              <a-button
                shape="circle"
                danger
                size="small"
                @click.stop="deleteCustomWatermark"
              >
                <template #icon>
                  <delete-outlined />
                </template>
              </a-button>
            </a-space>
          </div>
          <img
            :src="customWatermark"
            alt="watermark"
          >
        </template>
        <div v-else>
          <loading-outlined v-if="watermarkLoading" />
          <plus-outlined v-else />
          <div class="ant-upload-text">
            {{ $t('components.watermarkSettings.upload') }}
          </div>
        </div>
      </a-upload>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { error, success } from '@/utils'
import { ACCEPTED_MIME_TYPES } from '@/constants'
import { useI18n } from 'vue-i18n'
import { DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'WatermarkSettings',
  components: {
    PlusOutlined,
    LoadingOutlined,
    EditOutlined,
    DeleteOutlined
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const isTrialTier = computed(() => store.getters['workspace/billing/isTrialTier'])
    const isPaid = computed(() => store.getters['workspace/billing/isPaid'])
    const emailVerified = computed(() => store.getters['user/emailVerified'])
    const workspaceHideWatermark = computed(() => store.getters['workspace/settings/hideWatermark'])
    const customWatermarkEnabled = computed(() => store.getters['workspace/features/customWatermarkEnabled'])
    const forcedWatermarkToggleEnabled = computed(() => store.getters['workspace/features/forcedWatermarkToggleEnabled'])
    const useCustomWatermark = computed(() => store.getters['workspace/settings/useCustomWatermark'])
    const customWatermark = computed(() => store.getters['workspace/media/watermark'])
    const watermarkLoading = computed(() => store.getters['workspace/media/watermarkLoadingStatus'])
    const watermarkToggleDisabled = computed(() => {
      return !isPaid.value && !forcedWatermarkToggleEnabled.value
    })

    const toggleWatermark = (e) => {
      const checked = !e.target?.checked
      store.dispatch('workspace/updateWorkspace', { settings: { hideWatermark: checked } }).then(() => {
      }).then(()=>{
        success()
      }).catch(e => {
        error(e.message)
      })
    }

    const toggleCustomWatermark = (e) => {
      const checked = e.target?.checked
      store.dispatch('workspace/updateWorkspace', { settings: { useCustomWatermark: checked } }).then(() => {
      }).then(()=>{
        success()
      }).catch(e => {
        error(e.message)
      })
    }

    const beforeWatermarkUpload = async (fileRaw) => {
      const isLt2M = fileRaw.size / 1024 / 1024 < 2

      if (!isLt2M) {
        error(t('components.watermarkSettings.watermarkUploadError'))
        return false
      }

      watermarkLoading.value = true
      await store.dispatch('workspace/media/uploadWorkspaceWatermark', fileRaw).catch(e => {
        error(e.message)
      })
      return false
    }

    const deleteCustomWatermark = async () => {
      await store.dispatch('workspace/media/deleteWorkspaceWatermark').catch(e => {
        error(e.message)
      })
    }

    const openUpgradeSubscriptionModal = () => {
      store.dispatch('closeGlobalSettings')
      store.dispatch('handleUpdateSubscription')
    }

    return {
      isTrialTier,
      customWatermark,
      emailVerified,
      acceptedImageTypes: ACCEPTED_MIME_TYPES.IMAGE,
      watermarkLoading,
      workspaceHideWatermark,
      useCustomWatermark,
      customWatermarkEnabled,
      watermarkToggleDisabled,
      forcedWatermarkToggleEnabled,
      toggleWatermark,
      beforeWatermarkUpload,
      deleteCustomWatermark,
      toggleCustomWatermark,
      openUpgradeSubscriptionModal
    }
  }
})
</script>

<style lang="less">
 #watermark-settings {
   .ant-form-item {
     margin-bottom: 0;
   }
   .ant-checkbox+span {
     padding-right: 8px;
     padding-left: 8px;
   }
   .watermark-uploader {
     margin-top: 16px;
     .ant-upload {
       position: relative;
       &:hover .watermark-actions {
         opacity: 1;
       }
       .watermark-actions {
         opacity: 0;
         position: absolute;
         width: 100%;
         height: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         z-index: 1;
         span {
           position: relative;
         }
         &::before {
           content: '';
           background-color: var(--ant-primary-1);
           display: block;
           width: 100%;
           opacity: .5;
           height: 100%;
           position: absolute;
         }
       }
     }

     img {
       max-width: 100%;
     }
   }
 }
</style>
