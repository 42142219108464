<template>
  <div>
    <a-typography-title :level="5">
      {{ $t('components.brandingSettings.title') }}
    </a-typography-title>
    <a-divider />
    <a-typography-text>
      {{ $t('components.brandingSettings.customizeDashboard') }}
    </a-typography-text>
    <a-divider />
    <a-row
      type="flex"
      align="middle"
    >
      <a-col
        flex="0 0 auto"
        style="margin-right: 50px;"
      >
        {{ $t('components.brandingSettings.logo') }}
        <a-tooltip :title="$t('components.brandingSettings.logoTooltipTitle')">
          <InfoCircleOutlined style="margin-left: 4px;" />
        </a-tooltip>
      </a-col>
      <a-col flex="0 1 240px">
        <a-space>
          <a-upload
            name="avatar"
            list-type="picture-card"
            class="logo-uploader"
            :show-upload-list="false"
            :accept="acceptedImageTypes"
            :before-upload="beforeLogoUpload"
          >
            <template v-if="workspaceLogo && logoLoading">
              <LoadingOutlined v-if="logoLoading" />
            </template>
            <template v-else-if="workspaceLogo">
              <img
                :src="workspaceLogo"
                alt="logo"
                class="workspace-logo"
              >
            </template>
            <div v-else>
              <loading-outlined v-if="logoLoading" />
              <plus-outlined v-else />
            </div>
          </a-upload>
          <a-tooltip
            v-if="workspaceLogo"
            :title="$t('components.brandingSettings.deleteTooltipTitle')"
          >
            <a-button
              type="link"
              size="small"
              danger
              @click="deleteLogo"
            >
              <template #icon>
                <delete-outlined />
              </template>
            </a-button>
          </a-tooltip>
        </a-space>
      </a-col>
      <a-col
        flex="0 0 auto"
        style="margin-right: 50px;"
      >
        {{ $t('components.brandingSettings.favicon') }}
        <a-tooltip :title="$t('components.brandingSettings.faviconTooltipTitle')">
          <InfoCircleOutlined style="margin-left: 4px;" />
        </a-tooltip>
      </a-col>
      <a-col flex="100px">
        <a-space>
          <a-upload
            name="avatar"
            list-type="picture-card"
            class="favicon-uploader"
            :show-upload-list="false"
            :accept="acceptedFaviconTypes"
            :before-upload="beforeFaviconUpload"
          >
            <template v-if="workspaceFavicon && faviconLoading">
              <LoadingOutlined v-if="faviconLoading" />
            </template>
            <template v-else-if="workspaceFavicon">
              <img
                :src="workspaceFavicon"
                alt="logo"
                class="workspace-favicon"
              >
            </template>
            <div v-else>
              <loading-outlined v-if="faviconLoading" />
              <plus-outlined v-else />
            </div>
          </a-upload>
          <a-tooltip
            v-if="workspaceFavicon"
            :title="$t('components.brandingSettings.deleteTooltipTitle')"
          >
            <a-button
              type="link"
              size="small"
              danger
              @click="deleteFavicon"
            >
              <template #icon>
                <delete-outlined />
              </template>
            </a-button>
          </a-tooltip>
        </a-space>
      </a-col>
    </a-row>
    <a-divider />
    <a-row
      type="flex"
      justify="center"
      align="middle"
    >
      <a-col
        flex="0 0 auto"
        style="margin-right: 50px;"
      >
        {{ $t('components.brandingSettings.accentColor') }}
        <a-tooltip title="Tooltip">
          <InfoCircleOutlined style="margin-left: 4px;" />
        </a-tooltip>
      </a-col>
      <a-col flex="auto">
        <a-input-group compact>
          <a-input
            v-model:value="accentColor"
            type="color"
            style="width: 60px;"
            @press-enter="saveAccentColor"
          />
          <a-input
            v-model:value="accentColor"
            style="width: 90px;"
            @press-enter="saveAccentColor"
          />
          <a-button
            type="primary"
            @click="saveAccentColor"
          >
            {{ $t('components.brandingSettings.saveButtonText') }}
          </a-button>
        </a-input-group>
      </a-col>
    </a-row>
    <a-divider />
    <a-typography-text>
      {{ $t('components.brandingSettings.customDashboardAddress') }}
      <a-tooltip>
        <template #title>
          <p style="padding-left: 16px;">
            {{ $t('components.brandingSettings.domainRestrictions') }}
          </p>
          <ul>
            <li>{{ $t('components.brandingSettings.domainRestriction1') }}</li>
            <li>{{ $t('components.brandingSettings.domainRestriction2') }}</li>
            <li>{{ $t('components.brandingSettings.domainRestriction3') }}</li>
            <li>{{ $t('components.brandingSettings.domainRestriction4') }}</li>
            <li>{{ $t('components.brandingSettings.domainRestriction5') }}</li>
          </ul>
        </template>
        <InfoCircleOutlined style="margin-left: 4px;" />
      </a-tooltip>
    </a-typography-text>
    <a-typography-paragraph type="secondary">
      {{ $t('components.brandingSettings.setupCustomAddress') }}
    </a-typography-paragraph>
    <a-typography-text type="danger">
      {{ $t('components.brandingSettings.domainSetupDisclaimer') }}
    </a-typography-text>
    <a-input-group
      compact
      style="margin-top:16px; "
    >
      <a-input
        v-model:value="domain"
        addon-after=".kitcast.tv"
        style="width: 300px;"
        placeholder="dashboard"
        :disabled="!!currentDomain"
        @press-enter="startDomainSave"
      />
      <a-button
        v-if="!currentDomain"
        type="primary"
        :disabled="!domain"
        @click="startDomainSave"
      >
        {{ $t('components.brandingSettings.saveButtonText') }}
      </a-button>
    </a-input-group>
  </div>
</template>

<script>
import { computed, createVNode, defineComponent, reactive, ref, watch } from 'vue'
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons-vue'

import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { error, isLt2M, success } from '@/utils'
import { ACCEPTED_MIME_TYPES, DEFAULT_ACCENT_COLOR } from '@/constants'
import { useI18n } from 'vue-i18n'

const domainRegex = new RegExp('^[a-zA-Z]+[a-zA-Z0-9\\-]*$')

export default defineComponent({
  name: 'BrandingSettings',
  components: {
    LoadingOutlined,
    PlusOutlined,
    DeleteOutlined,
    InfoCircleOutlined
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const currentAccentColor = computed(() => store.getters['workspace/settings/accentColor'])
    const currentDomain = computed(() => store.getters['workspace/workspaceWhitelabelDomain'])
    const workspaceLogo = computed(() => store.getters['workspace/media/logo'])
    const workspaceFavicon = computed(() => store.getters['workspace/media/favicon'])
    const logoLoading = computed(() => store.getters['workspace/media/logoLoadingStatus'])
    const faviconLoading = computed(() => store.getters['workspace/media/faviconLoadingStatus'])
    const domain = ref(currentDomain.value || '')
    const accentColor = ref(currentAccentColor.value || DEFAULT_ACCENT_COLOR)

    const beforeUpload = async (fileRaw, uploadAction, commitMutation, errorMessageKey, valueGetter) => {
      if (!isLt2M(fileRaw.size)) {
        error(t(errorMessageKey))
        return false
      }

      try {
        await store.dispatch(uploadAction, fileRaw)
        const updatedValue = valueGetter()
        store.commit(commitMutation, updatedValue)
      } catch (e) {
        error(e.message)
      }
      return false
    }

    const beforeLogoUpload = (fileRaw) => {
      return beforeUpload(
          fileRaw,
          'workspace/media/uploadWorkspaceLogo',
          'auth/SET_LOGO',
          'components.brandingSettings.logoUploadError',
          () => workspaceLogo.value
      )
    }

    const beforeFaviconUpload = (fileRaw) => {
      return beforeUpload(
          fileRaw,
          'workspace/media/uploadWorkspaceFavicon',
          'auth/SET_FAVICON',
          'components.brandingSettings.faviconUploadError',
          () => workspaceFavicon.value
      )
    }
    const deleteLogo = async () => {
      await store.dispatch('workspace/media/deleteWorkspaceLogo').catch(e => {
        error(e.message)
      })
    }

    const deleteFavicon = async () => {
      await store.dispatch('workspace/media/deleteWorkspaceFavicon').catch(e => {
        error(e.message)
      })
    }

    const startDomainSave = () => {
      if (!domain.value) {
        return
      }

      domain.value = domain.value?.toLowerCase()

      if (!domainRegex.test(domain.value) || domain.value.length > 63) {
        error(t('components.brandingSettings.domainInvalidError'))
        return
      }

      return Modal.confirm({
        title: t('components.brandingSettings.popConfirmTitle'),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('components.brandingSettings.popConfirmContent', {domain:domain.value}),
        okText: t('components.brandingSettings.popConfirmOkText'),
        centered: true,

        onOk () {
          setWorkspaceWhiteLabelDomain()
        }

      })
    }

    watch(()=> currentAccentColor.value, (color) => {
      accentColor.value = color
    })

    const setWorkspaceWhiteLabelDomain = () => {
      store.dispatch('workspace/updateWorkspace', { whitelabel: domain.value }).then(() => {
      }).then(() => {
        success()
      }).catch((e) => {
        error(e.message)
      })
    }

    const saveAccentColor = () => {
      store.dispatch('workspace/updateWorkspace', { settings: { whitelabelAccentColor: accentColor.value } }).then(() => {
      }).then(() => {
        store.commit('auth/SET_ACCENT_COLOR', accentColor.value)
        store.dispatch('changeAccentColor', accentColor.value)
        success()
      }).catch((e) => {
        error(e.message)
      })
    }

    return {
      domain,
      accentColor,
      logoLoading,
      currentDomain,
      workspaceLogo,
      faviconLoading,
      workspaceFavicon,
      acceptedImageTypes: ACCEPTED_MIME_TYPES.IMAGE,
      acceptedFaviconTypes: ACCEPTED_MIME_TYPES.FAVICON,
      deleteLogo,
      beforeLogoUpload,
      beforeFaviconUpload,
      deleteFavicon,
      saveAccentColor,
      startDomainSave
    }
  }
})
</script>

<style lang="less">
</style>
