import {
  PREPARE_SETUP_INTENT,
  PREVIEW_WORKSPACE_SUBSCRIPTION_UPDATE,
  REDEEM_PROMOTION_CODE,
  UPDATE_WORKSPACE_CUSTOMER,
  UPDATE_WORKSPACE_SUBSCRIPTION
} from '@/graphql/mutations'
import {
  GET_ACTIVE_COUPONS,
  GET_PAYMENT_METHOD,
  GET_STRIPE_GROWTH_PRICES,
  GET_WORKSPACE_CUSTOM_PRICE,
  GET_WORKSPACE_CUSTOMER,
  GET_WORKSPACE_SUBSCRIPTION,
  LIST_WORKSPACE_INVOICES
} from '@/graphql/queries'
import moment from 'moment'
import { apolloCall } from '@/helpers/Graphql'
import { handleActionError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    paymentMethod: null,
    loading: {
      paymentMethod: false
    },
    workspaceInvoices: null,
    workspaceCustomer: null,
    activeCoupons: null,
    workspaceSubscription: null,
    stripeGrowthPrices: null,
    stripeCustomPrice: null,
    stripeIntent: null
  },
  actions: {
    async getPaymentMethod ({commit}) {
      try {
        const { getPaymentMethod } = await apolloCall({
          commit,
          query: GET_PAYMENT_METHOD,
          key: 'paymentMethod'
        })
        return commit('SET_PAYMENT_METHOD', getPaymentMethod)
      }
      catch (e) {
        throw e
      }
    },
    async geWorkspaceInvoices ({commit}) {
      try {
        const { listWorkspaceInvoices: { data: invoices} } = await apolloCall({
          query: LIST_WORKSPACE_INVOICES,
        })
        commit('SET_WORKSPACE_INVOICES', invoices)
      }
      catch (e) {
        handleActionError(e)
      }
    },
    async getWorkspaceSubscription ({ commit }) {
      try {
        const { getWorkspaceSubscription } = await apolloCall({
          query: GET_WORKSPACE_SUBSCRIPTION
        })
        commit('SET_WORKSPACE_SUBSCRIPTION', getWorkspaceSubscription)
      }
      catch (e) {
        handleActionError(e)
      }
    },
    async getStripeGrowthPrices ({ commit }) {
      try {
        const { getStripeGrowthPrices } = await apolloCall({
          query: GET_STRIPE_GROWTH_PRICES
        })
        commit('SET_STRIPE_GROWTH_PRICES', getStripeGrowthPrices)
      }
      catch (e) {
        handleActionError(e)
      }
    },
    async getStripeCustomPrice ({ commit }) {
      try {
        const { getWorkspaceStripeCustomPrice } = await apolloCall({
          query: GET_WORKSPACE_CUSTOM_PRICE
        })
        commit('SET_STRIPE_CUSTOM_PRICE', getWorkspaceStripeCustomPrice)
      }
      catch (e) {
        handleActionError(e)
      }
    },
    async setWorkspaceCustomer ({ commit }) {
      try {
        const { getWorkspaceCustomer } = await apolloCall({
          query: GET_WORKSPACE_CUSTOMER
        })
        commit('SET_WORKSPACE_CUSTOMER', getWorkspaceCustomer)
      }
      catch (e) {
        handleActionError(e)
      }
    },
    async getActiveCoupons ({ commit }) {
      try {
        const { getActiveCoupons } = await apolloCall({
          query: GET_ACTIVE_COUPONS
        })
        commit('SET_ACTIVE_COUPONS', getActiveCoupons)
      } catch (e) {
        handleActionError(e)
      }
    },
    async getStripeSetupIntentClientSecret ({ commit }) {
      try {
        const { prepareSetupIntent: {client_secret} } = await apolloCall({
          query: PREPARE_SETUP_INTENT
        })
        return client_secret
      } catch (e) {
        throw e
      }
    },
    async previewWorkspaceSubscriptionUpdate ({ commit }, { input }) {
      return await apolloCall({
        mutation: PREVIEW_WORKSPACE_SUBSCRIPTION_UPDATE,
        variables: { input }
      }).then(({ previewWorkspaceSubscriptionUpdate }) => previewWorkspaceSubscriptionUpdate)
    },
    async updateWorkspaceSubscription ({commit, dispatch}, { input }) {
      try {
        const { updateWorkspaceSubscription } = await apolloCall({
          mutation: UPDATE_WORKSPACE_SUBSCRIPTION,
          variables: { input }
        })
        dispatch('workspace/billing/refetchFrozenStatusesAndBillingInfo', null, { root: true })
        commit('UPDATE_WORKSPACE_SUBSCRIPTION', updateWorkspaceSubscription)
        return updateWorkspaceSubscription
      }
      catch (e) {
        throw e
      }
    },
    async updateWorkspaceCustomer ({commit}, { input }) {
      try {
        const { updateWorkspaceCustomer } = await apolloCall({
          mutation: UPDATE_WORKSPACE_CUSTOMER,
          variables: { input }
        })
        commit('SET_WORKSPACE_CUSTOMER', updateWorkspaceCustomer)
      }
      catch (e) {
        throw e
      }
    },
    async redeemPromotionCode ({commit}, code) {
      try {
        const { redeemPromotionCode} = await apolloCall({
          mutation: REDEEM_PROMOTION_CODE,
          variables: { code }
        })
        commit('ADD_ACTIVE_COUPON', redeemPromotionCode)
      }
      catch (e) {
        throw e
      }
    },
    async resetModule ({ commit }) {
      commit('CLEAR_SUBSCRIPTION_DATA')
    }
  },
  getters: {
    paymentMethod: state => state.paymentMethod,
    hasPaymentMethod: state => !!state.paymentMethod,
    paymentMethodLoading: state => state.loading.paymentMethod,
    activeCoupons: state => state.activeCoupons,
    freeDevicesCoupon: state => state.activeCoupons?.find(coupon => coupon.couponType === 'ADD_FREE_DEVICES'),
    daysToCardExpiration: state => {
      const card = state.paymentMethod?.card
      if (!card) return null
      const expYear = card.exp_year
      const expMonth = card.exp_month
      if (!expYear || !expMonth) return null
      const expirationDate = moment(`${expYear}-${expMonth}`, "YYYY-MM").endOf('month')
      const currentDate = moment()
      return expirationDate.diff(currentDate, 'days')
    },
    workspaceSubscription: state => state.workspaceSubscription,
    workspaceInvoices: state => state.workspaceInvoices,
    currentBillingInterval: state => state.workspaceSubscription?.billingInfo?.billingInterval,
    workspaceCustomer: state => state.workspaceCustomer,
    lastWorkspaceInvoiceUrl: state => state.workspaceInvoices?.[0]?.invoice_pdf,
    lastWorkspaceReceiptUrl: state => {
      const invoice = state.workspaceInvoices?.find(invoice => invoice.paid && invoice.charge)
      return invoice?.charge?.receipt_pdf || null
    },
    currentPeriodEnds: state => state.workspaceSubscription?.billingInfo?.currentPeriodEnd,
    currentPeriodEndsFormatted: state => (format = 'MMMM D') => {
      const endDate = state.workspaceSubscription?.billingInfo?.currentPeriodEnd
      return endDate ? moment(endDate).format(format) : null
    },
    stripeGrowthPrices: state => state.stripeGrowthPrices,
    stripeCustomPrice: state => state.stripeCustomPrice,
    stripeUpdateIntentClientSecret: state => state.intent?.client_secret
  },
  mutations: {
    SET_PAYMENT_METHOD (state, paymentMethod) {
      state.loading.paymentMethod = false
      state.paymentMethod = paymentMethod
    },
    SET_LOADING_STATUS (state, {
      status,
      key
    }) {
      state.loading[key] = status
    },
    SET_WORKSPACE_INVOICES(state, invoices) {
      state.workspaceInvoices = invoices
    },
    SET_WORKSPACE_SUBSCRIPTION (state, subscription) {
      state.workspaceSubscription = subscription
    },
    UPDATE_WORKSPACE_SUBSCRIPTION (state, subscription) {
      state.workspaceSubscription = {...state.workspaceSubscription, ...subscription}
    },
    SET_STRIPE_CUSTOM_PRICE (state, price) {
      state.stripeCustomPrice = price
    },
    SET_STRIPE_GROWTH_PRICES (state, prices) {
      state.stripeGrowthPrices = prices
    },
    SET_WORKSPACE_CUSTOMER (state, customer) {
      state.workspaceCustomer = customer
    },
    SET_ACTIVE_COUPONS (state, customer) {
      state.activeCoupons = customer
    },
    ADD_ACTIVE_COUPON (state, coupon) {
      state.activeCoupons = state.activeCoupons ? [...state.activeCoupons, coupon] : [coupon]
    },
    CLEAR_SUBSCRIPTION_DATA (state) {
      state.paymentMethod = null
      state.workspaceSubscription = null
      state.workspaceCustomer = null
      state.activeCoupons = null
      state.stripeGrowthPrices = null
      state.stripeCustomPrice = null
      state.stripeIntent = null
    }
  }
}
