import { LIST_EXTERNAL_API_KEYS } from '@/graphql/queries'
import {
  CREATE_EXTERNAL_API_KEY,
  DELETE_EXTERNAL_API_KEY,
  RESOLVE_EXTERNAL_API_KEY_VALUE,
  UPDATE_EXTERNAL_API_KEY
} from '@/graphql/mutations'
import { apolloCall } from '@/helpers/Graphql'

export default {
  namespaced: true,
  state: {
    apiKeysList: [],
    loading: {
      apiKeys: false
    }
  },
  actions: {
    async getApiKeysList ({ commit }) {
      const { listExternalApiKeys } = await apolloCall({
        commit,
        query: LIST_EXTERNAL_API_KEYS,
        key: 'apiKeys',
      })
      commit('SET_API_KEYS_LIST', listExternalApiKeys)
    },
    async createApiKey ({ dispatch, commit }, { name }) {
      await apolloCall({
        commit,
        mutation: CREATE_EXTERNAL_API_KEY,
        variables: { input: { name } },
        key: 'apiKeys',
      })
      dispatch('getApiKeysList')
    },
    async resolveApiKeyValue ({ dispatch, commit }, { id }) {
      const { resolveExternalApiKeyValue } = await apolloCall({
        commit,
        mutation: RESOLVE_EXTERNAL_API_KEY_VALUE,
        variables: { externalApiKeyId: id },
        key: 'apiKeys'
      })
      return resolveExternalApiKeyValue
    },
    async deleteApiKey ({ dispatch, commit }, { id }) {
      await apolloCall({
        commit,
        mutation: DELETE_EXTERNAL_API_KEY,
        variables: { id },
        key: 'apiKeys'
      })
      dispatch('getApiKeysList')
    },
    async updateApiKey ({ dispatch, commit }, { id, name }) {
      await apolloCall({
        commit,
        mutation: UPDATE_EXTERNAL_API_KEY,
        variables: { id, input: { name } },
        key: 'apiKeys'
      })
      dispatch('getApiKeysList')
    }
  },
  getters: {
    apiKeysList: state => state.apiKeysList,
    apiKeysLoading: state => state.loading.apiKeys
  },
  mutations: {
    SET_LOADING_STATUS (state, {
      status,
      key
    }) {
      state.loading[key] = status
    },
    SET_API_KEYS_LIST (state, apiKeysList) {
      state.apiKeysList = apiKeysList
    }
  }
}
