import { createStore } from 'vuex'
import auth from './auth'
import user from './user'
import workspace from './workspace'
import tags from './tags'
import groups from './groups'
import playlist from './playlist'
import devices from './devices'
import social from './social'
import slides from './slides'
import media from './media'
import apiKeys from './apiKeys'
import subscription from './subscription'
import notifications from './notifications'
import { Modal, theme } from 'ant-design-vue'
import { GET_APPLICATION_PUBLIC_CONFIG, } from '@/graphql/queries'
import { DEFAULT_ACCENT_COLOR, DEFAULT_BORDER_RADIUS } from '@/constants'
import { cloneDeep, merge } from 'lodash'
import chroma from 'chroma-js'
import { apolloCall } from '@/helpers/Graphql'

function getAccentBgColor (color) {
  return chroma(color).alpha(0.1).css()
}

const DEFAULT_MODALS_STATE = {
  globalSettings: { visible: false, activeTab: 'profile' },
  collaboration: false,
  authorizedAccounts: false,
  emergencyPlaylist: false,
  media: false,
  devices: false,
  contactUs: false,
  updateSubscription: false,
  updatePaymentMethod: false,
  eduAppliance: false,
  createWorkspace: false,
  emailConfirmed: false,
}

export default createStore({
  state: {
    modals: cloneDeep(DEFAULT_MODALS_STATE),
    globalSettingsActiveTab: 'profile',
    whitelabel: null,
    config: null,
    prevRoute: null,
    themeConfig: {
      hashed: false,
      token: {
        colorPrimary: DEFAULT_ACCENT_COLOR,
        borderRadius: DEFAULT_BORDER_RADIUS,
        colorPrimaryBg: getAccentBgColor(DEFAULT_ACCENT_COLOR),
      },
      algorithm: theme.defaultAlgorithm
    },
    groupSidebarCollapsed: !!JSON.parse(localStorage.getItem('groupSidebarCollapsed'))
  },

  actions: {
    openModal({ commit }, { modal, options }) {
      commit('TOGGLE_MODAL', { modal, visible: true, options })
    },
    closeModal({ commit }, modal) {
      commit('TOGGLE_MODAL', { modal, visible: false })
    },
    setPrevRoute ({commit}, route) {
      commit('SET_PREV_ROUTE', route)
    },
    handleUpdateSubscription ({ commit, rootGetters, dispatch }) {
      const modal = rootGetters['workspace/billing/isEnterprize'] ? 'contactUs' : 'updateSubscription'
      dispatch('openModal', { modal, visible: true })
    },
    async setApplicationPublicConfig ({ commit }) {
      try {
        const { getApplicationPublicConfig } = await apolloCall({
          query: GET_APPLICATION_PUBLIC_CONFIG
        })
        commit('SET_CONFIG', getApplicationPublicConfig)
      } catch (e) {
        console.error('Failed to load public config:', e);
      }
    },
    toggleGroupSidebar ({ commit }) {
      commit('TOGGLE_GROUP_SIDEBAR')
    },
    closeAllModals ({ commit }) {
      Modal.destroyAll()
      commit('CLOSE_ALL_MODALS')
    },
    changeAccentColor ({ commit, dispatch }, color) {
      commit('SET_THEME_CONFIG', {token: {colorPrimary: color, colorPrimaryBg: getAccentBgColor(color)}})
    },
    setThemeConfig ({ commit, dispatch }, themeConfig) {
      commit('SET_THEME_CONFIG', themeConfig)
    }
  },
  getters: {
    groupSidebarCollapsed: state => state.groupSidebarCollapsed,
    prevRoute: state => state.prevRoute,
    config: state => state.config,
    themeConfig: state => state.themeConfig,
    isModalVisible: state => modal => {
      const modalState = state.modals[modal];
      if (typeof modalState === 'boolean') {
        return modalState
      }
      return modalState?.visible || false
    },
    modalOptions: state => modal => state.modals[modal] || {},
    globalSettingsActiveTab: state => state.modals.globalSettings.activeTab,
    globalSettingsActiveTabOpened: state => state.modals.globalSettings.visible ? state.modals.globalSettings.activeTab : null
  },
  mutations: {
    TOGGLE_MODAL(state, { modal, visible, options }) {
      if (typeof state.modals[modal] === 'boolean') {
        state.modals[modal] = visible;
      } else {
        state.modals[modal] = { ...state.modals[modal], visible, ...options };
      }
    },
    CLOSE_ALL_MODALS (state) {
      state.modals = cloneDeep(DEFAULT_MODALS_STATE)
    },
    CHANGE_GLOBAL_SETTINGS_TAB(state, tab) {
      state.modals.globalSettings.activeTab = tab
    },
    SET_WHITELABEL (state, whitelabel) {
      state.whitelabel = whitelabel
    },
    SET_CONFIG (state, config) {
      state.config = config
    },
    CLEAR_CONFIG (state) {
      state.config = null
    },
    SET_PREV_ROUTE (state, route) {
      state.prevRoute = route
    },
    CLEAR_PREV_ROUTE (state) {
      state.prevRoute = null
    },
    TOGGLE_GROUP_SIDEBAR (state) {
      localStorage.setItem('groupSidebarCollapsed', !state.groupSidebarCollapsed+'')
      state.groupSidebarCollapsed = !state.groupSidebarCollapsed
    },
    SET_THEME_CONFIG (state, themeConfig) {
      state.themeConfig = cloneDeep(merge({}, state.themeConfig, themeConfig))
    }
  },
  modules: {
    auth,
    user,
    tags,
    workspace,
    groups,
    playlist,
    social,
    devices,
    media,
    slides,
    subscription,
    notifications,
    apiKeys
  }
})
