import { ROLES } from '@/constants'

const hasRole = (role, rootState) => {
  const userRole = rootState.workspace?.workspace?.myRole
  return userRole === role
}

export default {
  namespaced: true,
  state: {},
  actions: {},
  getters: {
    isOwner: (state, getters, rootState) => hasRole(ROLES.OWNER, rootState),
    isAdmin: (state, getters, rootState) => hasRole(ROLES.ADMIN, rootState),
    isManager: (state, getters, rootState) => hasRole(ROLES.MANAGER, rootState),
    isAuthor: (state, getters, rootState) => hasRole(ROLES.AUTHOR, rootState),
  },
  mutations: {}
}
