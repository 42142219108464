const featuresList = [
  'ssoEnabled',
  'slideEditModalEnabled',
  'forcedWatermarkToggleEnabled',
  'nestedGroupsEnabled',
  'externalApiEnabled',
  'playlistVersionsEnabled',
  'whitelabelEnabled',
  'customWatermarkEnabled',
  'smartGroupsEnabled',
  'authorRoleEnabled',
  'externalMediaDrivesIntegrationsEnabled',
  'multipleSocialAccountsEnabled'
]

const generateGetters = (features) => {
  return features.reduce((getters, feature) => {
    getters[feature] = (state, getters, rootState) =>
      rootState.workspace?.workspace?.features?.[feature]
    return getters;
  }, {})
}

export default {
  namespaced: true,
  state: {},
  actions: {},
  getters: generateGetters(featuresList),
  mutations: {}
}
