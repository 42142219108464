<template>
  <a-layout style="margin: auto">
    <a-layout-content style="padding: 80px 24px; width: 480px;">
      <a-result
        v-if="passwordReset"
        status="success"
        :title="$t('components.resetPasswordPage.successTitle')"
      >
        <template #extra>
          <a-button>
            <router-link :to="{name: 'Login'}">
              {{ $t('components.resetPasswordPage.backToLoginButtonText') }}
            </router-link>
          </a-button>
        </template>
      </a-result>
      <template v-else>
        <a-typography-title
          :level="3"
          style="text-align: center"
        >
          {{ $t('components.resetPasswordPage.resetPassword') }}
        </a-typography-title>
        <a-form
          ref="formRef"
          layout="vertical"
          :model="formState"
          style="margin-top: 32px;display: block"
          :rules="rules"
          @submit.prevent="resetPassword"
        >
          <a-form-item
            :label="$t('components.resetPasswordPage.newPasswordLabel')"
            name="password"
          >
            <a-input-password
              v-model:value="formState.password"
              :placeholder="$t('components.resetPasswordPage.newPasswordPlaceholder')"
            />
          </a-form-item>
          <a-form-item
            :label="$t('components.resetPasswordPage.confirmPasswordLabel')"
            name="confirmPassword"
          >
            <a-input-password
              v-model:value="formState.confirmPassword"
              :placeholder="$t('components.resetPasswordPage.confirmPasswordPlaceholder')"
            />
          </a-form-item>
          <a-form-item style="margin-bottom: 16px; margin-top: 16px; text-align: center">
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              :loading="loading"
            >
              <span v-if="!loading">{{ $t('components.resetPasswordPage.resetPasswordButtonText') }}</span>
              <span v-else>{{ $t('components.resetPasswordPage.loadingButtonText') }}</span>
            </a-button>
          </a-form-item>
        </a-form>
      </template>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { defineComponent, reactive, ref, toRef } from 'vue'
import { error, success } from '@/utils'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ResetPasswordPage',
  props: {
    token: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const token = toRef(props, 'token')
    const store = useStore()
    const { t } = useI18n()
    const formRef = ref()
    const passwordReset = ref(false)
    const loading = ref(false)
    const formState = reactive({
      password: '',
      confirmPassword: ''
    })

    const validateConfirmPass = async (_rule, value) => {
      if (value === '') {
        // eslint-disable-next-line
        return Promise.reject(t('components.resetPasswordPage.confirmPasswordRequiredError'))
      } else if (value !== formState.password) {
        // eslint-disable-next-line
        return Promise.reject(t('components.resetPasswordPage.confirmPasswordInvalidError'))
      } else {
        return Promise.resolve()
      }
    }

    const rules = {
      password: [{
        required: true,
        trigger: 'change',
        message: t('components.resetPasswordPage.newPasswordInvalidError')
      }],
      confirmPassword: [{
        required: true,
        trigger: 'change',
      }, {
        validator: validateConfirmPass,
        trigger: 'change'
      }]
    }

    const resetPassword = () => {
      setTimeout(async () => {
        try {
          await formRef.value.validate()
        } catch (e) {
          return
        }
        loading.value = true
        const input = { password: formState.password }
        await store.dispatch('user/resetUserPassword', { input, token: token.value }).then(() => {
          success()
          passwordReset.value = true
        }).catch(e => {
          error(e.message)
        }).then(() => {
          loading.value = false
        })
      })
    }

    return {
      rules,
      loading,
      formRef,
      formState,
      passwordReset,
      resetPassword
    }
  }
})
</script>

<style scoped>

</style>
