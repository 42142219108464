<script setup>
import { ref, reactive, computed, toRaw } from 'vue'
import { error, success } from '@/utils'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

defineProps({
  showForm: Boolean
})

const { t } = useI18n()
const store = useStore()
const loading = ref(false)
const passwordFormRef = ref(null)
const passwordFormState = reactive({
  oldPassword: '',
  password: '',
  confirmPassword: ''
})

const validateConfirmPass = async (_rule, value) => {
  if (value === '') {
    // eslint-disable-next-line
    return Promise.reject(t('components.securitySettings.confirmPasswordRequiredError'))
  } else if (value !== passwordFormState.password) {
    // eslint-disable-next-line
    return Promise.reject(t('components.securitySettings.confirmPasswordMismatchError'))
  } else {
    return Promise.resolve()
  }
}

const passwordRules = computed(()=>{
  return {
    oldPassword: [{
      required: true,
      trigger: 'blur',
      message: t('components.securitySettings.oldPasswordInvalidError')
    }],
    password: [{
      required: true,
      trigger: 'blur',
      message: t('components.securitySettings.passwordInvalidError')
    },
      {
        trigger: 'blur',
        min: 8,
        message: t('components.securitySettings.passwordTooShortError')
      },
      {
        trigger: 'blur',
        max: 20,
        message: t('components.securitySettings.passwordTooLongError')
      }],
    confirmPassword: [{
      validator: validateConfirmPass,
      trigger: 'change'
    }]
  }
})

const resetPasswordForm = () => {
  passwordFormRef.value.resetFields()
}

const onChangePassword = async () => {
  loading.value = true
  const { confirmPassword, ...user } = toRaw(passwordFormState)
  store.dispatch('user/updateProfile', user).then(() => {
    success(t('components.securitySettings.passwordChanged'))
    resetPasswordForm()
  }).catch((e) => {
    const [errorObj] = e.graphQLErrors
    const [message] = errorObj?.extensions?.response?.message || [e.message]
    error(message)
  }).then(() => {
    loading.value = false
  })
}

</script>

<template>
  <a-form
    v-if="showForm"
    ref="passwordFormRef"
    layout="vertical"
    style="margin-bottom: 32px;"
    :model="passwordFormState"
    :rules="passwordRules"
    @finish="onChangePassword"
  >
    <a-row :gutter="0">
      <a-col :span="12">
        <a-form-item
          :label="$t('components.securitySettings.oldPasswordLabel')"
          name="oldPassword"
        >
          <a-input-password
            v-model:value="passwordFormState.oldPassword"
            :placeholder="$t('components.securitySettings.oldPasswordPlaceholder')"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="0">
      <a-col :span="12">
        <a-form-item
          :label="$t('components.securitySettings.newPasswordLabel')"
          name="password"
        >
          <a-input-password
            v-model:value="passwordFormState.password"
            :placeholder="$t('components.securitySettings.newPasswordPlaceholder')"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="0">
      <a-col :span="12">
        <a-form-item
          :label="$t('components.securitySettings.confirmPasswordLabel')"
          name="confirmPassword"
        >
          <a-input-password
            v-model:value="passwordFormState.confirmPassword"
            :placeholder="$t('components.securitySettings.confirmPasswordPlaceholder')"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item>
      <a-button
        type="primary"
        html-type="submit"
        :loading="loading"
      >
        {{ $t('components.securitySettings.changeButtonText') }}
      </a-button>
    </a-form-item>
  </a-form>
</template>

<style scoped lang="less">

</style>
