<script setup>
import { computed, watch, watchEffect } from 'vue'
import { DEFAULT_ACCENT_COLOR } from '@/constants'
import { useStore } from 'vuex'

const store = useStore()

const accentColor = computed(() => store.getters['workspace/settings/accentColor'])
const defaultAccentColor = computed(() => store.getters['auth/accentColor'])

watch(accentColor, (accentColor) => {
  store.dispatch('changeAccentColor', accentColor || defaultAccentColor.value || DEFAULT_ACCENT_COLOR)
})

watchEffect(() => {
  if (accentColor.value) {
    store.dispatch('changeAccentColor', accentColor.value)
  }
})

</script>

<template>
  <none />
</template>

<style scoped lang="less">

</style>
