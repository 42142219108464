<template>
  <div>
    <a-modal
      v-model:open="showChangeEmailModal"
      width="400px"
      :title="$t('components.profileSettings.changeEmailModalTitle')"
      centered
      :closable="false"
      @cancel="onChangeEmailCancel"
    >
      <a-form
        ref="changeEmailFormRef"
        :model="changeEmailFormState"
        :rules="changeEmailRules"
        @submit.prevent="onChangeEmailProceed"
      >
        <a-row type="flex">
          <a-col
            flex="0 0 auto"
            style="margin-right: 16px; margin-top: 4px;"
          >
            <InfoCircleOutlined
              v-if="!changeEmailStarted"
              style="color: var(--ant-warning-color); font-size: 22px;"
            />
            <CheckCircleOutlined
              v-else
              style="color: var(--ant-primary-color); font-size: 22px;"
            />
          </a-col>
          <a-col flex="1">
            <transition
              name="fade"
              mode="out-in"
            >
              <div v-if="!changeEmailStarted">
                <a-form-item name="email">
                  <a-input
                    ref="changeEmailInputRef"
                    v-model:value="changeEmailFormState.email"
                    :placeholder="$t('components.profileSettings.newEmailPlaceholder')"
                    name="email"
                  />
                </a-form-item>
                <a-typography-text type="secondary">
                  {{ $t('components.profileSettings.changeEmailDisclaimer') }}
                </a-typography-text>
              </div>
              <div v-else>
                <a-typography-text type="secondary">
                  {{ $t('components.profileSettings.changeEmailSent') }}
                </a-typography-text>
              </div>
            </transition>
          </a-col>
        </a-row>
      </a-form>
      <template #footer>
        <template v-if="!changeEmailStarted">
          <a-button
            key="back"
            :disabled="changeEmailStartInProcess"
            @click="onChangeEmailCancel"
          >
            {{ $t('components.profileSettings.cancelButtonText') }}
          </a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="changeEmailStartInProcess"
            @click="onChangeEmailProceed"
          >
            {{ $t('components.profileSettings.changeEmailButtonText') }}
          </a-button>
        </template>
        <template v-else>
          <a-button
            key="back"
            type="primary"
            @click="onChangeEmailCancel"
          >
            {{ $t('components.profileSettings.gotItButtonText') }}
          </a-button>
        </template>
      </template>
    </a-modal>
    <a-typography-title :level="5">
      {{ $t('components.profileSettings.title') }}
    </a-typography-title>
    <a-divider />
    <a-typography-text strong>
      {{ $t('components.profileSettings.photo') }}
    </a-typography-text>
    <a-upload
      name="avatar"
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      :before-upload="beforeAvatarUpload"
      :accept="acceptedTypes"
    >
      <template v-if="avatar && avatarLoading">
        <LoadingOutlined v-if="avatarLoading" />
      </template>
      <template v-else-if="avatar">
        <div class="avatar-actions">
          <a-space>
            <a-button
              shape="circle"
              type="dashed"
              size="small"
            >
              <template #icon>
                <edit-outlined />
              </template>
            </a-button>
            <a-button
              shape="circle"
              danger
              size="small"
              @click.stop="deleteAvatar"
            >
              <template #icon>
                <delete-outlined />
              </template>
            </a-button>
          </a-space>
        </div>
        <img
          :src="avatar"
          alt="avatar"
        >
      </template>
      <div v-else>
        <loading-outlined v-if="avatarLoading" />
        <plus-outlined v-else />
        <div class="ant-upload-text">
          {{ $t('components.profileSettings.upload') }}
        </div>
      </div>
    </a-upload>
    <a-divider />
    <a-form layout="vertical">
      <a-row :gutter="0">
        <a-col :span="11">
          <a-form-item
            :label="$t('components.profileSettings.emailLabel')"
          >
            <a-input
              :value="profileFormState.email"
              disabled
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-form-item>
      <a-button @click="startEmailChange">
        {{ $t('components.profileSettings.changeEmail') }}
      </a-button>
    </a-form-item>
    <a-divider />
    <a-form
      layout="vertical"
      :model="profileFormState"
      :rules="profileRules"
      @finish="onProfileUpdate"
    >
      <a-row :gutter="0">
        <a-col :span="11">
          <a-form-item
            :label="$t('components.profileSettings.firstNameLabel')"
            name="firstName"
          >
            <a-input
              v-model:value="profileFormState.firstName"
              :placeholder="$t('components.profileSettings.firstNamePlaceholder')"
            />
          </a-form-item>
        </a-col>
        <a-col
          :span="11"
          :offset="1"
        >
          <a-form-item
            :label="$t('components.profileSettings.lastNameLabel')"
            name="lastName"
          >
            <a-input
              v-model:value="profileFormState.lastName"
              :placeholder="$t('components.profileSettings.lastNamePlaceholder')"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="0">
        <a-col :span="11">
          <a-form-item :label="$t('components.profileSettings.languageLabel')">
            <a-select
              v-model:value="profileFormState.language"
              :options="languagesList.map((key) => ({ value: key, label: $t(`languages.${key}`)}))"
            />
          </a-form-item>
        </a-col>
        <a-col
          :span="11"
          :offset="1"
        >
          <a-form-item
            :label="$t('components.profileSettings.phoneLabel')"
            name="phone"
          >
            <vue-tel-input
              ref="phoneInput"
              v-model="profileFormState.phone"
              :class="{'has-error': !phoneObject?.valid && profileFormState.phone}"
              mode="international"
              @on-input="handlePhoneChange"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="0">
        <a-col :span="11">
          <a-form-item
            :label="$t('components.profileSettings.companyNameLabel')"
            name="companyName"
          >
            <a-input
              v-model:value="profileFormState.companyName"
              :placeholder="$t('components.profileSettings.companyNamePlaceholder')"
            />
          </a-form-item>
        </a-col>
        <a-col
          :span="11"
          :offset="1"
        >
          <a-form-item
            :label="$t('components.profileSettings.companySizeLabel')"
            name="companySize"
          >
            <a-select
              v-model:value="profileFormState.companySize"
              :placeholder="$t('components.profileSettings.companySizePlaceholder')"
              :options="companySizesList.map((key) => ({ value: key, label: $t(`components.profileSettings.companySizes.${key}`) }))"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item v-bind="formItemLayout">
        <a-button
          type="primary"
          html-type="submit"
          :loading="loading"
        >
          {{ $t('components.profileSettings.saveButtonText') }}
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, reactive, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import { error, success } from '@/utils'
import { useI18n } from 'vue-i18n'
import {
  ACCEPTED_MIME_TYPES, COMPANY_NAME_JOB_TITLE_REGEXP,
  COMPANY_SIZE_LIST,
  DEVICES_NUMBER_MARKS,
  FORM_ITEM_LAYOUT_DEFAULT,
  LANGUAGES_LIST,
  LOCALES_MAP, NAME_REGEXP
} from '@/constants'
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons-vue'

export default defineComponent({
  name: 'ProfileSettings',
  components: {
    PlusOutlined,
    LoadingOutlined,
    EditOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    CheckCircleOutlined
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const user = computed(() => store.getters['user/user'])
    const _avatarLoading = computed(() => store.getters['user/avatarLoading'])
    const avatar = computed(() => store.getters['user/avatar'](128))
    const avatarPlaceholder = computed(() => store.getters['user/avatarPlaceholder'])
    const showChangeEmailModal = ref(false)
    const changeEmailStarted = ref(false)
    const changeEmailStartInProcess = ref(false)
    const changeEmailInputRef = ref()
    const changeEmailFormRef = ref()
    const changeEmailFormState = reactive({
      email: ''
    })
    const phoneObject = ref()


    const avatarLoading = ref(false)
    const loading = ref(false)
    const profileFormState = ref({})

    function getUserProfile ({ __typename, id, createdAt, updatedAt, emailVerified, avatarMedia, ...user }) {
      profileFormState.value = { ...user }
    }

    const languageChanged = computed(() => user.value?.language !== profileFormState.value?.language)

    user.value && getUserProfile(user.value)

    const validateEmail = (_rule, value) => {
      value = value || ''
      // eslint-disable-next-line
      return value !== profileFormState.value?.email ? Promise.resolve() : Promise.reject('You’re trying to use the same email address.')
    }

    const changeEmailRules = computed(()=> {
      return {
        email: [{
          required: true,
          message: t('components.profileSettings.newEmailRequiredError'),
          trigger: 'blur'
        },
        {
          type: 'email',
          message: t('components.profileSettings.newEmailInvalidError'),
          trigger: 'blur'
        },
        {
          validator: validateEmail,
          trigger: 'blur'
        }]
      }
    })

    const profileRules = computed(()=>{
      return {
        companyName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.profileSettings.companyNameInvalidError')
        },{
          trigger: 'blur',
          pattern: COMPANY_NAME_JOB_TITLE_REGEXP,
          message: t('components.profileSettings.companyNameInvalidPatternError')
        }],
        companySize: [{
          required: true,
          message: t('components.profileSettings.companySizeInvalidError'),
        }],
        firstName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.profileSettings.firstNameInvalidError')
        }, {
          trigger: 'blur',
          pattern: NAME_REGEXP,
          message: t('components.profileSettings.firstNameInvalidPatternError')
        }],
        lastName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.profileSettings.lastNameInvalidError')
        }, {
          trigger: 'blur',
          pattern: NAME_REGEXP,
          message: t('components.profileSettings.lastNameInvalidPatternError')
        }],
      }
    })

    const onProfileUpdate = async () => {
      if (!phoneObject.value?.valid) return
      loading.value = true
      const user = toRaw(profileFormState.value)
      delete user.userCreatedBy
      delete user.features
      delete user.is2faSet
      delete user.isPasswordSet
      const changeLang = languageChanged.value
      store.dispatch('user/updateProfile', user).then(() => {
        if (changeLang) {
          store.dispatch('user/setLanguage', LOCALES_MAP[user.language])
        }
        success()
      }).catch((e) => {
        const [errorObj] = e.graphQLErrors
        const [message] = errorObj?.extensions?.response?.message || [e.message]
        const originalErrorMessage = e.graphQLErrors?.[0]?.extensions?.originalError?.message?.[0]
        const errorMessage = originalErrorMessage ? t(`errors.codes["${originalErrorMessage}"]`) : message || e.message
        error(errorMessage)
      }).then(() => {
        loading.value = false
      })
    }

    const beforeAvatarUpload = async (fileRaw) => {
      const isLt2M = fileRaw.size / 1024 / 1024 < 2

      if (!isLt2M) {
        error(t('components.profileSettings.photoUploadError'))
        return false
      }

      _avatarLoading.value = true
      await store.dispatch('user/uploadProfileAvatar', fileRaw).catch(e => {
        error(e.message)
      })
      return false
    }

    const startEmailChange = () => {
      showChangeEmailModal.value = true
      nextTick(() => {
        changeEmailInputRef.value && changeEmailInputRef.value.focus()
      })
    }

    const resetChangeEmailForm = () => {
      changeEmailFormState.email = ''
      changeEmailFormRef.value && changeEmailFormRef.value.resetFields()
      changeEmailStartInProcess.value = false
      changeEmailStarted.value = false
    }

    const onChangeEmailProceed = async () => {
      try {
        await changeEmailFormRef.value.validate()
      } catch (e) {
        if (e.errorFields.length) {
          return
        }
      }
      changeEmailStartInProcess.value = true
      await store.dispatch('user/requestEmailChange', { email: changeEmailFormState.email })
        .then(() => {
          changeEmailStarted.value = true
        })
        .catch(e => {
          error(e.message === 'Email already registered' ? t('components.profileSettings.newEmailTaken') : e.message)
          changeEmailStartInProcess.value = false
        })
    }

    const handlePhoneChange = (number, phoneObj) => {
      phoneObject.value = phoneObj
    }

    const onChangeEmailCancel = () => {
      showChangeEmailModal.value = false
      resetChangeEmailForm()
    }

    const deleteAvatar = async () => {
      await store.dispatch('user/deleteProfileAvatar').catch(e => {
        error(e.message)
      })
    }

    watch(() => user.value, (newValue) => {
      newValue && getUserProfile(newValue)
    })

    watch(_avatarLoading, (loading) => {
      avatarLoading.value = loading
    })

    return {
      user,
      loading,
      avatar,
      avatarPlaceholder,
      profileFormState,
      profileRules,
      changeEmailStarted,
      changeEmailFormRef,
      changeEmailInputRef,
      changeEmailRules,
      changeEmailStartInProcess,
      changeEmailFormState,
      showChangeEmailModal,
      avatarLoading,
      languageChanged,
      phoneObject,
      languagesList: LANGUAGES_LIST,
      devicesMarks: DEVICES_NUMBER_MARKS,
      companySizesList: COMPANY_SIZE_LIST,
      acceptedTypes: ACCEPTED_MIME_TYPES.IMAGE,
      formItemLayout: FORM_ITEM_LAYOUT_DEFAULT,
      startEmailChange,
      onProfileUpdate,
      onChangeEmailProceed,
      onChangeEmailCancel,
      handlePhoneChange,
      deleteAvatar,
      beforeAvatarUpload
    }
  }

})
</script>

<style lang="less">
.avatar-uploader {
  margin-top: 16px;
  .ant-upload {
    position: relative;
    &:hover .avatar-actions {
      opacity: 1;
    }
    .avatar-actions {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      span {
        position: relative;
      }
      &::before {
        content: '';
        background-color: var(--ant-primary-1);
        display: block;
        width: 100%;
        opacity: .5;
        height: 100%;
        position: absolute;
      }
    }
  }

  img {
    max-width: 100%;
  }
}
</style>
