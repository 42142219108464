<script setup>
import { SortAscendingOutlined, SortDescendingOutlined, SwapOutlined } from '@ant-design/icons-vue'
import { computed } from 'vue'

const props = defineProps({
  searchValue: String,
  sortTree: String
})
const emit = defineEmits(['update:searchValue', 'update:sortTree'])
const handleSearchChange = (event) => {
  emit('update:searchValue', event.target.value)
}

const handleSortTreeChange = () => {
  emit('update:sortTree', props.sortTree === 'asc' ? 'desc' : props.sortTree === 'desc' ? null : 'asc')
}

const sortKey = computed(() => {
  return props.sortTree === 'asc' ? 'Asc' : props.sortTree === 'desc' ? 'Desc' : 'None'
})

</script>

<template>
  <a-space>
    <a-input-search
      :value="searchValue"
      :placeholder="$t('components.searchAndFilter.searchPlaceholder')"
      class="groups-filter"
      @change="handleSearchChange"
    />
    <a-tooltip :title="$t(`components.searchAndFilter.sort${sortKey}`)">
      <a-button
        @click="handleSortTreeChange"
      >
        <template #icon>
          <SortAscendingOutlined v-if="sortTree === 'asc'" />
          <SortDescendingOutlined v-else-if="sortTree === 'desc'" />
          <SwapOutlined
            v-else
            :rotate="90"
          />
        </template>
      </a-button>
    </a-tooltip>
  </a-space>
</template>

<style scoped lang="less">
@import "../../styles/variables.less";
:deep(.ant-input-search) {
  input {
    background-color: transparent;
    border-color: @menu-dark-item-active-bg;
    color: @menu-item-color;
    &::placeholder {
      color: @menu-item-color;
    }
  }

  .ant-input-group-addon {
    button {
      background: transparent;
      border-color: @menu-dark-item-active-bg;
      border-left-color: transparent !important;
      color: @menu-item-color !important;
    }

    background: transparent;
    border-color: @menu-dark-item-active-bg;
  }
  .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-color: @menu-dark-item-active-bg;
  }
}
:deep(.ant-btn-default) {
  background: transparent;
  border-color: @menu-dark-item-active-bg;
  color: @menu-item-color;
  &:hover {
    background: @menu-dark-item-active-bg;
    color: @menu-item-color;
  }
}
</style>
