<template>
  <a-popover
    v-model:open="visible"
    placement="topRight"
    :trigger="['click']"
    :overlay-style="{'width': '250px'}"
    class="main-menu"
  >
    <a-avatar
      v-if="avatar"
      style="cursor: pointer"
      :src="avatar"
    />
    <a-avatar
      v-else
      :src="avatarPlaceholder"
      style="cursor: pointer"
    >
      <template #icon>
        <UserOutlined />
      </template>
    </a-avatar>
    <template #content>
      <a-list
        item-layout="horizontal"
        :split="true"
        class="main-menu-list"
      >
        <a-list-item class="main-menu-list-item">
          <a-typography-text
            type="secondary"
            class="main-menu-list-item-title"
          >
            <small>{{ $t('components.mainMenu.workspace') }}</small>
          </a-typography-text>
          <a-select
            v-if="workspacesList.length > 1 || userCanCreateWorkspace"
            v-model:value="workspaceId"
            class="main-menu-workspace-select"
            @change="handleWorkspaceChange"
          >
            <a-select-option
              v-for="workspaceItem in workspacesList"
              :key="workspaceItem.id"
              :value="workspaceItem.id"
            >
              {{ workspaceItem.name }}
            </a-select-option>
            <a-select-option
              v-if="userCanCreateWorkspace"
              value="NEW"
            >
              <PlusCircleOutlined />
              {{ $t('components.mainMenu.newWorkspace') }}
            </a-select-option>
          </a-select>

          <a-list-item-meta v-if="showAdminLayout">
            <template #avatar>
              <a-tooltip
                :title="workspaceName"
                placement="right"
              >
                <a-avatar>{{ workspaceNameCapital }}</a-avatar>
              </a-tooltip>
            </template>
          </a-list-item-meta>
          <div
            v-if="showAdminLayout"
            class="main-menu-list-item-content"
          >
            <a-typography-link
              type="secondary"
              @click="openGlobalSettings('general')"
            >
              {{ $t('components.mainMenu.general') }}
            </a-typography-link>
            <a-typography-link
              type="secondary"
              @click="openGlobalSettings('subscription')"
            >
              {{ $t('components.mainMenu.subscription') }}
            </a-typography-link>
          </div>
        </a-list-item>
        <a-divider style="margin: 0" />
        <a-list-item class="main-menu-list-item">
          <a-typography-text
            type="secondary"
            class="main-menu-list-item-title"
          >
            <small>{{ $t('components.mainMenu.account') }}</small>
          </a-typography-text>
          <a-list-item-meta>
            <template #avatar>
              <a-avatar
                v-if="avatar"
                :src="avatar"
              />
              <a-avatar v-else>
                <template #icon>
                  <UserOutlined />
                </template>
              </a-avatar>
            </template>
          </a-list-item-meta>
          <div class="main-menu-list-item-content">
            <a-typography-text strong>
              {{ fullName }}
            </a-typography-text>
            <a-typography-link
              type="secondary"
              @click="openGlobalSettings('profile')"
            >
              {{ $t('components.mainMenu.profile') }}
            </a-typography-link>
            <a-typography-link
              type="secondary"
              @click="logOut"
            >
              {{ $t('components.mainMenu.logOut') }}
            </a-typography-link>
          </div>
        </a-list-item>
      </a-list>
    </template>
  </a-popover>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { PlusCircleOutlined, UserOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'MainMenu',
  components: {
    UserOutlined, PlusCircleOutlined
  },
  setup () {
    const store = useStore()
    const visible = ref(false)
    const user = computed(() => store.getters['user/user'])
    const workspace = computed(() => store.getters['workspace/workspace'])
    const workspaceId = computed(() => store.getters['workspace/workspaceId'])
    const workspacesList = computed(() => store.getters['workspace/workspacesList'])
    const workspaceName = computed(() => workspace.value ? workspace.value.name : '')
    const workspaceNameCapital = computed(() => workspaceName.value ? workspaceName.value[0] : 'W')
    const fullName = computed(() => user.value ? `${user.value.firstName} ${user.value.lastName}` : '')
    const avatar = computed(() => store.getters['user/avatar'](64))
    const avatarPlaceholder = computed(() => store.getters['user/avatarPlaceholder'])
    const isAdmin = computed(() => store.getters['workspace/role/isAdmin'])
    const isOwner = computed(() => store.getters['workspace/role/isOwner'])
    const ownedWorkspacesLimit = computed(() => store.getters['user/ownedWorkspacesLimit'])
    const workspacesWhereUserIsOwnerCount = computed(()=>workspacesList.value?.filter(w=>w.myRole ==='OWNER')?.length)
    const userCanCreateWorkspace = computed(()=> (workspacesWhereUserIsOwnerCount.value || 1) < (ownedWorkspacesLimit.value || 1) )


    const showAdminLayout = computed(() => isAdmin.value || isOwner.value)

    const logOut = () => {
      store.dispatch('auth/logOut')
      visible.value = false
    }

    const openGlobalSettings = (tab) => {
      store.dispatch('openModal', { modal: 'globalSettings' , options: { activeTab: tab }})
      visible.value = false
    }

    const handleWorkspaceChange = async (workspaceId) => {
      visible.value = false
      if (workspaceId === 'NEW') {
        return store.dispatch('openModal', { modal: 'createWorkspace' })
      }
      setTimeout(()=>{
        store.dispatch('workspace/changeWorkspace', workspaceId).then(()=>{})
      }, 200)
    }
    return {
      user,
      fullName,
      avatar,
      avatarPlaceholder,
      visible,
      workspace,
      workspaceName,
      workspacesList,
      workspaceNameCapital,
      workspaceId,
      showAdminLayout,
      ownedWorkspacesLimit,
      userCanCreateWorkspace,
      workspacesWhereUserIsOwnerCount,
      logOut,
      handleWorkspaceChange,
      openGlobalSettings
    }
  }
})
</script>

<style lang="less">
  .ant-list.main-menu-list {
    .main-menu-workspace-select {
      width: 100%;
      margin-bottom: 16px;
    }
    padding: 4px 8px;
    .main-menu-list-item {
      flex-wrap: wrap;
      padding: 8px;
      .ant-list-item-meta {
        align-self: flex-start;
        flex: 0;
      }
      .main-menu-list-item-title{
        flex: 1 1 100%;
        margin-bottom: 8px;
      }
      .main-menu-list-item-content {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }

</style>
