import {
  ACCEPT_INVITE_BY_ID,
  ACCEPT_INVITE_BY_TOKEN,
  CHANGE_USER_EMAIL,
  DELETE_AVATAR_MEDIA,
  DELETE_SESSION_BY_ID,
  DISABLE_2FA,
  FINALIZE_2FA_SETUP,
  PREPARE_2FA_SETUP,
  REJECT_INVITE_BY_ID,
  REJECT_INVITE_BY_TOKEN,
  REQUEST_PASSWORD_SET_OTP_CODE,
  REQUEST_USER_EMAIL_CHANGE,
  REQUEST_USER_PASSWORD_RESET,
  RESEND_EMAIL_VERIFICATION,
  RESET_USER_PASSWORD,
  REVERT_USER_EMAIL,
  SUBMIT_ENTERPRISE_REQUEST_FORM,
  UPDATE_PROFILE,
  UPLOAD_AVATAR_MEDIA,
  VERIFY_EMAIL
} from '@/graphql/mutations'
import {
  GET_PENDING_INVITE_BY_TOKEN,
  GET_PROFILE_AVATAR,
  LIST_MY_PENDING_INVITES,
  LIST_SESSIONS,
  LOGGED_IN_USER
} from '@/graphql/queries'
import ApolloClient from '@/apollo'
import { LOCALES_MAP } from '@/constants'
import { initIntercom, shutDownIntercom } from '@/intercom'
import { getUserAvatarPlaceholder } from '@/utils'
import { handleActionError } from '@/helpers/ErrorHandler'
import { apolloCall } from '@/helpers/Graphql'

const MEDIA_POLL_INTERVAL = 1500 // 1.5 seconds

function isUserComplete(user) {
  return !!(user
    && user.firstName
    && user.lastName
    && user.phone
    && user.desiredDevicesCount
    && user.companyName
    && user.companySize
    && user.companyWebsite
    && user.jobTitle);
}

function formatFullName(user) {
  return `${user.firstName} ${user.lastName}`.trim()
}

export default {
  namespaced: true,
  state: {
    user: {},
    loading: {
      avatar: false,
      sessions: false
    },
    sessionsList: null
  },
  actions: {
    async getUserPendingInvites () {
      const { listMyPendingInvites } = await apolloCall({
        query: LIST_MY_PENDING_INVITES,
      })
      return listMyPendingInvites
    },
    async prepare2Fa ({}, input) {
      const { prepare2faSetup: { otpUrl } } = await apolloCall({
        mutation: PREPARE_2FA_SETUP,
        variables: input
      })
      return otpUrl
    },
    async disable2Fa ({commit}, input) {
      await apolloCall({
        mutation: DISABLE_2FA,
        variables: input
      })
      commit('SET_USER', { is2faSet: false })
    },
    async finalize2faSetup ({commit}, twoFactorCode) {
      const { finalize2faSetup } = await apolloCall({
        mutation: FINALIZE_2FA_SETUP,
        variables: { twoFactorCode }
      })
      if (finalize2faSetup) {
        return commit('SET_USER', { is2faSet: true })
      }
      throw new Error('Code is incorrect')
    },
    async updateOnboarding ({ commit, dispatch, getters }, { onboardingStep, onboardingFinished } ) {
      const input = {
        ...(onboardingStep ? { onboardingStep } : {}),
        onboardingFinished
      }
      const { updateMyProfile } = await apolloCall({
        mutation: UPDATE_PROFILE,
        variables: { input }
      })
      return await commit('SET_USER', updateMyProfile)
    },
    async updateProfile ({ commit, dispatch }, user) {
      const {
        email,
        ...input
      } = user
      const { updateMyProfile } = await apolloCall({
        mutation: UPDATE_PROFILE,
        variables: { input }
      })
      return await commit('SET_USER', updateMyProfile)
    },
    async uploadProfileAvatar ({ commit, dispatch }, file) {
      commit('SET_LOADING_STATUS', { key: 'avatar', value: true })
      try {
        await apolloCall({
          mutation: UPLOAD_AVATAR_MEDIA,
          variables: { file },
          context: { hasUpload: true }
        })
        dispatch('fetchProfileAvatar')
      } catch (e) {
        commit('SET_LOADING_STATUS', { key: 'avatar', value: false })
        throw e
      }
    },
    async deleteProfileAvatar ({ commit }) {
      await apolloCall({
        commit,
        mutation: DELETE_AVATAR_MEDIA,
        key: 'avatar'
      })
      return await commit('SET_USER', { avatarMedia: [] })
    },
    async fetchProfileAvatar ({ commit }) {
      const avatarSub = ApolloClient.watchQuery({ query: GET_PROFILE_AVATAR, pollInterval: MEDIA_POLL_INTERVAL, fetchPolicy: 'no-cache' })
        .subscribe(({ data: { getMyProfile: { avatarMedia = {} } }, loading }) => {
          if (!!avatarMedia.generatedMedia.length) {
            avatarSub.unsubscribe()
            commit('SET_USER', { avatarMedia })
            commit('SET_LOADING_STATUS', { key: 'avatar', value: false })
          }
        })
    },
    async getUserData ({ commit }) {
      try {
        const { getMyProfile } = await apolloCall({
          query: LOGGED_IN_USER
        })
        commit('SET_USER', getMyProfile)
      }
      catch (e) {
        handleActionError(e)
      }
    },
    async getPendingInviteByToken ({ commit }, token) {
      return apolloCall({
        query: GET_PENDING_INVITE_BY_TOKEN,
        variables: { token }
      })
    },
    async getUserSessions ({commit}) {
      const { listMySessions } = await apolloCall({
        commit,
        query: LIST_SESSIONS,
        key: 'sessions'
      })
      return await commit('SET_USER_SESSIONS', listMySessions)
    },
    async deleteUserSessionById ({ dispatch, commit }, sessionId) {
      await apolloCall({
        mutation: DELETE_SESSION_BY_ID,
        variables: { sessionId }
      })
      return await commit('DELETE_USER_SESSION_BY_ID', sessionId)
    },
    async acceptInviteByToken ({}, token) {
      return apolloCall({
        mutation: ACCEPT_INVITE_BY_TOKEN,
        variables: { token }
      })
    },
    async acceptInviteById ({}, id) {
      return apolloCall({
        mutation: ACCEPT_INVITE_BY_ID,
        variables: { id }
      })
    },
    async rejectInviteByToken ({}, token) {
      return apolloCall({
        mutation: REJECT_INVITE_BY_TOKEN,
        variables: { token }
      })
    },
    async rejectInviteById ({}, id) {
      return apolloCall({
        mutation: REJECT_INVITE_BY_ID,
        variables: { id }
      })
    },
    async requestPasswordSetupConfirmationCode ({}) {
      return apolloCall({
        mutation: REQUEST_PASSWORD_SET_OTP_CODE
      })
    },
    async resendEmailVerification () {
      return apolloCall({
        mutation: RESEND_EMAIL_VERIFICATION
      })
    },
    async resetUserPassword ({ commit }, { input, token }) {
      return apolloCall({
        mutation: RESET_USER_PASSWORD,
        variables: { input, token }
      })
    },
    async requestEmailChange ({ commit }, input) {
      return apolloCall({
        mutation: REQUEST_USER_EMAIL_CHANGE,
        variables: { input }
      })
    },
    async changeEmail ({ commit }, token) {
      return apolloCall({
        mutation: CHANGE_USER_EMAIL,
        variables: { token }
      })
    },
    async requestPasswordReset ({ commit }, input) {
      return apolloCall({
        mutation: REQUEST_USER_PASSWORD_RESET,
        variables: { input }
      })
    },
    async revertEmail ({ commit }, token) {
      return apolloCall({
        mutation: REVERT_USER_EMAIL,
        variables: { token }
      })
    },
    async verifyEmail ({}, token) {
      return apolloCall({
        mutation: VERIFY_EMAIL,
        variables: { token }
      })
    },
    async submitEnterpriseRequestForm ({}, input) {
      return apolloCall({
        mutation: SUBMIT_ENTERPRISE_REQUEST_FORM,
        variables: { input }
      })
    },
    initIntercom ({ getters }) {
      getters.userEmail && initIntercom(getters.userEmail)
    },
    shutDownIntercom () {
      shutDownIntercom()
    },
    setLanguage ({commit, getters}, language) {
      localStorage.setItem('kc_lang', language || getters.language)
    },
    clearUser ({ commit, dispatch }) {
      dispatch('shutDownIntercom')
      commit('CLEAR_USER')
    }
  },
  getters: {
    avatarLoading: state => state.loading?.avatar,
    onboardingFinished: state => state.user?.onboardingFinished,
    onboardingStep: state => state.user?.onboardingStep || 0,
    user: state => state.user,
    userIsMigrated: state => state.user?.userCreatedBy === 'V1_MIGRATION',
    userIsComplete: state => isUserComplete(state.user),
    fullName: state => formatFullName(state.user),
    phone: state => state.user?.phone,
    companyName: state => state.user?.companyName,
    userEmail: state => state.user?.email,
    ownedWorkspacesLimit: state => state.user?.features?.ownedWorkspacesLimit,
    userId: state => state.user?.id,
    is2faSet: state => state.user?.is2faSet,
    isPasswordSet: state => state.user?.isPasswordSet,
    language: state => state.user?.language ? LOCALES_MAP[state.user?.language] : (localStorage.getItem('kc_lang') || navigator.language || 'en'),
    sessionsList: state => state.sessionsList,
    sessionsLoading: state => state.loading?.sessions,
    emailVerified: state => state.user?.emailVerified,
    avatar: state => size => {
      const { generatedMedia = [] } = state.user?.avatarMedia || {}
      const avatarObject = generatedMedia.find(m => m.tag === (size ? `avatar${size}x${size}` : 'content'))
      return avatarObject?.url || ''
    },
    avatarPlaceholder: state => {
      return getUserAvatarPlaceholder(state.user?.id)
    }
  },
  mutations: {
    SET_LOADING_STATUS(state, { key, value }) {
      state.loading[key] = value;
    },
    SET_USER (state, user) {
      state.user = { ...state.user, ...user }
    },
    SET_USER_SESSIONS (state, sessions) {
      state.sessionsList = sessions
    },
    DELETE_USER_SESSION_BY_ID (state, sessionId) {
      state.sessionsList = [...state.sessionsList.filter(s => sessionId !== s.sessionId)]
    },
    CLEAR_USER (state) {
      state.user = {}
    }
  }
}
