<template>
  <div>
    <a-form
      layout="vertical"
      :model="emergencyFeedFormState"
      :rules="emergencyFeedRules"
      @finish="onEmergencyFeedUpdate"
    >
      <a-typography-title :level="5">
        {{ $t('components.emergencySettings.title') }}
      </a-typography-title>
      <a-divider />
      <a-typography-text>
        {{ $t('components.emergencySettings.addRssFeed') }}
      </a-typography-text>
      <a-input-group
        compact
        style="margin-top: 8px"
      >
        <a-form-item
          name="emergencyFeedUrl"
          style="margin-right: 8px;"
        >
          <a-input
            ref="emergencyFeedInputRef"
            v-model:value="emergencyFeedFormState.emergencyFeedUrl"
            :addon-before="protocol"
            :placeholder="$t('components.emergencySettings.emergencyRSSUrlPlaceholder')"
            name="emergencyFeedUrl"
            style="width: 400px;"
          />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            :loading="emergencyFeedLoading"
            html-type="submit"
            :disabled="!urlChanged"
          >
            {{ $t('components.emergencySettings.saveButtonText') }}
          </a-button>
        </a-form-item>
      </a-input-group>
    </a-form>

    <a-form
      layout="vertical"
      @finish="onEmergencyFeedDeviceMatchingRuleUpdate"
    >
      <a-typography-text>
        {{ $t('components.emergencySettings.addEmergencyFeedDeviceMatchingRule') }}
      </a-typography-text>
      <a-input-group
        compact
        style="margin-top: 8px"
      >
        <a-form-item style="margin-right: 8px;">
          <a-tree-select
            ref="treeSelectRef"
            v-model:value="selectedKeys"
            :loading="emergencyFeedDeviceMatchingRuleLoading"
            tree-checkable
            :show-checked-strategy="SHOW_CHILD"
            :tree-check-strictly="true"
            multiple
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :placeholder="$t('components.emergencySettings.emergencyFeedDeviceMatchingRulePlaceholder')"
            allow-clear
            tree-default-expand-all
            :tree-data="standardGroupsTree"
            style="width: 400px;"
          />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            :loading="emergencyFeedDeviceMatchingRuleLoading"
            :disabled="!groupsChanged"
            html-type="submit"
            @click="onEmergencyFeedDeviceMatchingRuleUpdate"
          >
            {{ $t('components.emergencySettings.saveButtonText') }}
          </a-button>
        </a-form-item>
      </a-input-group>
    </a-form>
    <a-form-item style="margin-bottom: 8px;">
      <a-switch
        :checked="workspaceEmergencyFeedEnabled"
        :disabled="emergencyFeedEnabledLoading"
        @click="onEmergencyFeedEnabledUpdate"
      />
      <a-typography-text style="margin-left: 16px;">
        {{ $t('components.emergencySettings.enableRSSNotifications') }}
      </a-typography-text>
    </a-form-item>
    <a-divider />
    <a-typography-text type="secondary">
      {{ $t('components.emergencySettings.emergencyNotificationsDisclaimer') }}
    </a-typography-text>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { isURL } from 'validator'
import { error, success } from '@/utils'
import { DEFAULT_PROTOCOL, PROTOCOL_REGEXP } from '@/constants'
import { useI18n } from 'vue-i18n'
import { TreeSelect } from 'ant-design-vue'
import { isEqual } from 'lodash'

const SHOW_CHILD = TreeSelect.SHOW_CHILD

const normalizeUrl = (url) => {
  return url.trim().replace(PROTOCOL_REGEXP, '').toLowerCase().replace(/(\/)$/, '')
}

function extractIdsFromJson(json) {
  const ids = [];

  if (json && typeof json === 'object') {
    if (Array.isArray(json)) {
      // Handle arrays recursively
      for (const element of json) {
        ids.push(...extractIdsFromJson(element));
      }
    } else {
      // Handle objects recursively
      for (const [key, value] of Object.entries(json)) {
        if (key === 'includeTagKeys') {
          for (const includeTagKey of value) {
            const match = /standard-group:id:(.*)/.exec(includeTagKey);
            if (match) {
              ids.push(match[1]);
            }
          }
        } else {
          ids.push(...extractIdsFromJson(value));
        }
      }
    }
  }

  return ids;
}

function generateJsonFromIds(ids) {
  const orArray = ids.map(id => {
    return { includeTagKeys: [`standard-group:id:${id}`] };
  });

  const json = {
    and: [
      {
        or: orArray
      }
    ]
  };

  return json;
}


export default defineComponent({
  name: 'EmergencySettings',
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const emergencyFeedInputRef = ref()
    const emergencyFeedUrl = computed(() => store.getters['workspace/settings/emergencyFeedUrl'])
    const workspaceEmergencyFeedEnabled = computed(() => store.getters['workspace/settings/emergencyFeedEnabled'])
    const emergencyFeedDeviceMatchingRule = computed(() => store.getters['workspace/settings/emergencyFeedDeviceMatchingRule'])
    const standardGroupsTree = computed(() => store.getters['groups/availableStandardGroupsTree']())
    const globalSettingsActiveTab = computed(() => store.getters['globalSettingsActiveTabOpened'])
    const isEmergencySettings = computed(()=> globalSettingsActiveTab.value === 'emergency')
    const emergencyFeedFormState = reactive({
      emergencyFeedUrl: ''
    })
    const emergencyFeedDeviceMatchingRuleLoading = ref(false)
    const emergencyFeedEnabledLoading = ref(false)
    const emergencyFeedLoading = ref(false)
    const selectedKeys = ref([])
    const prevSelectedIds = ref([])

    onMounted(() => {
      emergencyFeedInputRef.value && emergencyFeedInputRef.value.focus()
    })

    const urlChanged = computed(() => {
      return DEFAULT_PROTOCOL + emergencyFeedFormState.emergencyFeedUrl !== emergencyFeedUrl.value
    })

    const groupsChanged = computed(()=>{
      return !isEqual(selectedKeys.value?.map(({value}) => value), prevSelectedIds.value)
    })

    const validateEmergencyFeedUrl = (_rule, value) => {
      value = value || ''
      const stripUrl = normalizeUrl(value)
      // eslint-disable-next-line
      return isURL(DEFAULT_PROTOCOL + stripUrl) ? Promise.resolve() : Promise.reject('Please enter a valid rss link')
    }

    const emergencyFeedRules = {
      emergencyFeedUrl: [{
        required: true,
        validator: validateEmergencyFeedUrl,
        trigger: 'change'
      }]
    }

    const onEmergencyFeedUpdate = () => {
      emergencyFeedLoading.value = true
      store.dispatch('workspace/updateWorkspace', { settings: { emergencyFeedUrl: DEFAULT_PROTOCOL + emergencyFeedFormState.emergencyFeedUrl } }).then(() => {
        success(t('components.emergencySettings.emergencyFeedChangedSuccessMessage'))
      }).catch((e) => {
        error(e.message)
      }).then(() => {
        emergencyFeedLoading.value = false
      })
    }

    const onEmergencyFeedEnabledUpdate = (value) => {
      emergencyFeedEnabledLoading.value = true
      store.dispatch('workspace/updateWorkspace', { settings: { emergencyFeedEnabled: value } }).then(() => {
      }).then(()=>{
        success()
      }).catch((e) => {
        error(e.message)
      }).then(() => {
        emergencyFeedEnabledLoading.value = false
      })
    }

    const mapRuleToArray = () => {
      const rule = emergencyFeedDeviceMatchingRule.value
      const selectedIds = rule ? extractIdsFromJson(rule) : []
      selectedKeys.value = selectedIds?.map(id => ({ value: id }))
      prevSelectedIds.value = selectedIds
    }

    const onEmergencyFeedDeviceMatchingRuleUpdate = () => {
      if (!groupsChanged.value) return
      emergencyFeedDeviceMatchingRuleLoading.value = true
      const selectedIds = selectedKeys.value?.map(({value}) => value)
      const rule = selectedIds.length > 0 ? generateJsonFromIds(selectedIds) : null
      store.dispatch('workspace/updateWorkspace', { settings: { emergencyFeedDeviceMatchingRule: rule } }).then(() => {
        success(t('components.emergencySettings.emergencyFeedDeviceMatchingRuleSuccessMessage'))
        mapRuleToArray()
      }).catch((e) => {
        error(e.message)
      }).then(() => {
        emergencyFeedDeviceMatchingRuleLoading.value = false
      })
    }

    watchEffect(() => {
      emergencyFeedFormState.emergencyFeedUrl = emergencyFeedUrl.value ? normalizeUrl(emergencyFeedUrl.value) : null
    })

    watchEffect(() => {
      if (isEmergencySettings.value) {
        mapRuleToArray()
      }
    })

    return {
      protocol: DEFAULT_PROTOCOL,
      urlChanged,
      emergencyFeedUrl,
      emergencyFeedRules,
      emergencyFeedLoading,
      emergencyFeedInputRef,
      emergencyFeedFormState,
      emergencyFeedEnabledLoading,
      emergencyFeedDeviceMatchingRuleLoading,
      workspaceEmergencyFeedEnabled,
      standardGroupsTree,
      globalSettingsActiveTab,
      selectedKeys,
      SHOW_CHILD,
      groupsChanged,
      onEmergencyFeedUpdate,
      onEmergencyFeedDeviceMatchingRuleUpdate,
      onEmergencyFeedEnabledUpdate,
    }
  }
})
</script>

<style scoped>

</style>
