<template>
  <a-modal
    :open="showContactUs"
    :title="$t('components.contactUsModal.title')"
    width="768px"
    ok-text="Submit"
    cancel-text="Cancel"
    @ok="onSubmit"
    @cancel="closeModal"
  >
    <a-form
      ref="formRef"
      layout="vertical"
      :model="contactUsState"
      :rules="contactUsRules"
      @finish="onSubmit"
    >
      <a-row :gutter="0">
        <a-col :span="11">
          <a-form-item
            :label="$t('components.contactUsModal.jobTitleLabel')"
            name="jobTitle"
          >
            <a-input
              ref="firstInputRef"
              v-model:value="contactUsState.jobTitle"
              :placeholder="$t('components.contactUsModal.jobTitlePlaceholder')"
            />
          </a-form-item>
        </a-col>
        <a-col
          :span="11"
          :offset="1"
        >
          <a-form-item
            :label="$t('components.contactUsModal.jobDescriptionTitle')"
            name="jobDescription"
          >
            <a-input
              v-model:value="contactUsState.jobDescription"
              :placeholder="$t('components.contactUsModal.jobDescriptionPlaceholder')"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="0">
        <a-col :span="11">
          <a-form-item :label="$t('components.contactUsModal.screensUsageLabel')">
            <a-select
              v-model:value="contactUsState.screensUsage"
              :options="screensUsageList"
            />
          </a-form-item>
        </a-col>
        <a-col
          :span="11"
          :offset="1"
        >
          <a-form-item
            :label="$t('components.contactUsModal.phoneLabel')"
            name="phone"
          >
            <vue-tel-input
              ref="phoneInput"
              v-model="contactUsState.phone"
              :class="{'has-error': !phoneObject?.valid && contactUsState.phone}"
              mode="international"
              @on-input="handlePhoneChange"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="0">
        <a-col :span="11">
          <a-form-item
            :label="$t('components.contactUsModal.screensToConnectLabel')"
            name="preferredScreensNumber"
          >
            <a-input-number
              v-model:value="contactUsState.preferredScreensNumber"
              min="1"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="0">
        <a-col :span="24">
          <a-form-item
            :label="$t('components.contactUsModal.messageLabel')"
            name="message"
          >
            <a-textarea
              v-model:value="contactUsState.message"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>

import { computed, defineComponent, nextTick, reactive, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { error } from '@/utils'
import { useI18n } from 'vue-i18n'
import { COMPANY_NAME_JOB_TITLE_REGEXP } from '@/constants'

const DEFAULT_FORM_STATE = {
  jobTitle: '',
  jobDescription: '',
  screensUsage: 'customers',
  preferredScreensNumber: 1,
  phone: '',
  message: ''
}


export default defineComponent({
  name: 'ContactUsModal',
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const showContactUs = computed(()=> store.getters.isModalVisible('contactUs'))
    const formRef = ref()
    const firstInputRef = ref()
    const contactUsState = reactive({...DEFAULT_FORM_STATE})
    const phoneObject = ref()

    const screensUsageList =  computed(()=>[
      {
        label: t('components.contactUsModal.customers'),
        value: 'customers'
      },
      {
        label: t('components.contactUsModal.employees'),
        value: 'employees'
      },
      {
        label: t('components.contactUsModal.reselling'),
        value: 'reselling'
      }
    ])



    const contactUsRules = {
      jobTitle: [{
        required: true,
        trigger: 'blur',
        min: 2,
        message: t('components.contactUsModal.jobTitleInvalidError')
      },{
        trigger: 'blur',
        pattern: COMPANY_NAME_JOB_TITLE_REGEXP,
        message: t('components.contactUsModal.jobTitleInvalidPatternError')
      }],
      jobDescription: [{
        required: true,
        message: t('components.contactUsModal.jobDescriptionInvalidError')
      }],
      preferredScreensNumber: [{
        required: true,
        message: t('components.contactUsModal.screensToConnectInvalidError')
      }]
    }

    const closeModal = () => {
      store.dispatch('closeModal', 'contactUs')
    }

    const onSubmit = async () => {
      try {
        await formRef.value.validate()
      } catch (e) {
        return
      }

      const input = toRaw(contactUsState)

      store.dispatch('user/submitEnterpriseRequestForm', input).then(async ()=>{
        await store.dispatch('workspace/getWorkspaceInfo')
        onSuccess()
      }).catch(e=>{
        const originalErrorMessage = e.graphQLErrors?.[0]?.extensions?.originalError?.message?.[0]
        const errorMessage = originalErrorMessage ? t(`errors.codes["${originalErrorMessage}"]`) : e.message
        error(errorMessage)
      })

    }

    const onSuccess = () => {
      closeModal()
      message.info({
        content: () => t('components.contactUsModal.emailSent'),
        class: 'notification-message'
      })
    }

    const resetModal = () => {
      Object.assign(contactUsState, DEFAULT_FORM_STATE)
    }

    const handlePhoneChange = (number, phoneObj) => {
      phoneObject.value = phoneObj
    }

    watch(() => showContactUs.value, (show)=>{
      if (!show) {
        setTimeout(()=>{
          resetModal()
        }, 300)
      }
      else {
        nextTick(()=>{
          firstInputRef.value.focus()
        })
      }
    })


    return {
      formRef,
      showContactUs,
      contactUsRules,
      contactUsState,
      screensUsageList,
      firstInputRef,
      phoneObject,
      handlePhoneChange,
      closeModal,
      onSubmit,
    }
  }
})
</script>

<style lang="less">
@import "../styles/variables.less";


</style>
