import ApolloClient from '@/apollo'
import {
  GET_DEVICE_BY_ID,
  GET_DEVICE_PREVIEW_WEBRTC_ICE_SERVERS,
  GET_DEVICES_BY_MATCHING_RULE,
  LIST_DEVICES
} from '@/graphql/queries'
import {
  ATTACH_DEVICE,
  DELETE_DEVICE_BY_ID,
  DELETE_DEVICES_BY_IDS,
  INITIALIZE_DEVICE_PREVIEW,
  MOVE_DEVICE_BY_ID,
  MOVE_DEVICES_BY_IDS,
  SEND_DEVICE_PREVIEW_LOCAL_CANDIDATES,
  SEND_DEVICE_PREVIEW_WEBRTC_OFFER,
  SET_DEVICE_CUSTOM_TAGS_BY_DEVICE_ID,
  UPDATE_DEVICE_BY_ID,
} from '@/graphql/mutations'
import { updateEntityById } from '@/utils'
import { ON_DEVICE_UPDATED } from '@/graphql/subscriptions'
import { apolloCall } from '@/helpers/Graphql'

export default {
  namespaced: true,
  state: {
    loading: {
      devices: false
    },
    allDevices: [],
    deviceUpdateSubscription: null,
    currentGroupDevices: [],
    selectedDevices: [],
    devicesMatchingRules: [],
    devicePreviewObject: null
  },
  actions: {
    async getAllDevices ({ commit, dispatch }) {
      const { listDevices } = await dispatch('fetchDevices', { filters: {} })
      commit('SET_ALL_DEVICES', listDevices)
    },
    async subscribeToDeviceUpdate ({ commit, getters }) {
      if (getters.deviceUpdateSubscription) return
      const sub = ApolloClient.subscribe({ query: ON_DEVICE_UPDATED })
        .subscribe(({ data: { onDeviceUpdated }}) => {
          commit('UPDATE_DEVICE', onDeviceUpdated)
          commit('UPDATE_CURRENT_GROUP_DEVICE', onDeviceUpdated)
        })
      commit('SET_DEVICE_UPDATE_SUBSCRIPTION', sub)
    },

    async unsubscribeFromDeviceUpdate ({ commit }) {
      commit('UNSUBSCRIBE_FROM_DEVICE_UPDATE')
    },
    async fetchDevices ({ commit }, { filters }) {
      return await apolloCall({
        commit,
        query: LIST_DEVICES,
        variables: { filters },
        key: 'devices'
      })
    },
    async getCurrentGroupDevices ({ rootGetters, commit, dispatch }) {
      const groupId = rootGetters['groups/currentGroupId']
      if (!groupId) return
      const { listDevices } = await dispatch('fetchDevices', { filters: { groupIds: [groupId] } })
      commit('SET_CURRENT_GROUP_DEVICES', listDevices)
    },
    async updateDevice ({ rootGetters, commit }, { id, groupId, input, isCurrent }) {
      const currentGroupId = rootGetters['groups/currentGroupId']
      const { updateDeviceById } = await apolloCall({
        commit,
        mutation: UPDATE_DEVICE_BY_ID,
        variables: { id, input },
        key: 'devices'
      })
      if (isCurrent || groupId === currentGroupId) {
        commit('UPDATE_CURRENT_GROUP_DEVICE', updateDeviceById)
      }
      if (rootGetters.isModalVisible('devices')) {
        commit('UPDATE_DEVICE', updateDeviceById)
      }
    },
    async moveDevice ({ rootGetters, commit }, { id, groupId, input, isCurrent }) {
      const moveToGroupId = input.groupId
      const currentGroupId = rootGetters['groups/currentGroupId']
      const { moveDeviceById } = await apolloCall({
        commit,
        mutation: MOVE_DEVICE_BY_ID,
        variables: { id, input },
        key: 'devices'
      })
      if (isCurrent || groupId === currentGroupId) {
        commit('REMOVE_CURRENT_GROUP_DEVICE', id)
      }
      else if (moveToGroupId === currentGroupId) {
        commit('ADD_CURRENT_GROUP_DEVICE', moveDeviceById)
      }
      else if (rootGetters.isModalVisible('devices')) {
        commit('UPDATE_DEVICE', moveDeviceById)
      }
    },
    async moveDevices ({ rootGetters, commit, dispatch }, { ids, groupId }) {
      const currentGroupId = rootGetters['groups/currentGroupId']
      const { moveDevicesByIds } = await apolloCall({
        commit,
        mutation: MOVE_DEVICES_BY_IDS,
        variables: { ids, input: { groupId } },
        key: 'devices'
      })
      for (let device in moveDevicesByIds) {
        const moveToGroupId = moveDevicesByIds[device].groupId
        if (groupId === currentGroupId) {
          commit('REMOVE_CURRENT_GROUP_DEVICE', device.id)
        }
        else if (moveToGroupId === currentGroupId) {
          commit('ADD_CURRENT_GROUP_DEVICE', moveDevicesByIds[device])
        }
        else if (rootGetters.isModalVisible('devices')) {
          commit('UPDATE_DEVICE', moveDevicesByIds[device])
        }
      }
    },
    async attachDeviceToCurrentGroup ({ rootGetters, commit, dispatch }, payload) {
      const groupId = rootGetters['groups/currentGroupId']
      const { attachDevice } = await apolloCall({
        mutation: ATTACH_DEVICE,
        variables: { input: { groupId, ...payload } }
      })
      commit('ADD_CURRENT_GROUP_DEVICE', attachDevice)
      dispatch('getAllDevices')
      return attachDevice
    },
    async deleteDevice ({ rootGetters, commit, dispatch }, { id, groupId, isCurrent }) {
      const currentGroupId = rootGetters['groups/currentGroupId']
      await apolloCall({
        commit,
        mutation: DELETE_DEVICE_BY_ID,
        variables: { id },
        key: 'devices'
      })
      if (isCurrent || groupId === currentGroupId) {
        commit('REMOVE_CURRENT_GROUP_DEVICE', id)
        dispatch('getAllDevices')
      }
      if (rootGetters.isModalVisible('devices')) {
        commit('REMOVE_DEVICE', id)
      }
    },
    async deleteDevices ({ dispatch, commit }, { ids }) {
      await apolloCall({
        commit,
        mutation: DELETE_DEVICES_BY_IDS,
        variables: { ids },
        key: 'devices'
      })
      return Promise.all([dispatch('getCurrentGroupDevices'), dispatch('getAllDevices')])
    },
    async getDevicesMatchingRules ({ commit }, input) {
      const { getDevicesBySmartGroupMatchingRule } = await apolloCall({
        query: GET_DEVICES_BY_MATCHING_RULE,
        variables: { input }
      })
      commit('SET_DEVICES_MATCHING_RULES', getDevicesBySmartGroupMatchingRule)
    },
    async setDeviceCustomTagsById ({ commit, dispatch }, { id, groupId, input }) {
      await apolloCall({
        mutation: SET_DEVICE_CUSTOM_TAGS_BY_DEVICE_ID,
        variables: { id, input }
      })
    },
    async getUpdatedDeviceTagsByDeviceId ({ commit, rootGetters }, { id, groupId }) {
      const currentGroupId = rootGetters['groups/currentGroupId']
      const { getDeviceById } = await apolloCall({
        query: GET_DEVICE_BY_ID,
        variables: { id }
      })
      if (groupId === currentGroupId) {
        commit('UPDATE_CURRENT_GROUP_DEVICE', getDeviceById)
      }
      if (rootGetters.isModalVisible('devices')) {
        commit('UPDATE_DEVICE', getDeviceById)
      }
    },
    async initializeDevicePreview ({ commit, rootGetters }, { deviceId }) {
      const { initializeDevicePreview } = await apolloCall({
        mutation: INITIALIZE_DEVICE_PREVIEW,
        variables: { deviceId }
      })
      return initializeDevicePreview
    },
    async getDevicePreviewWebrtcIceServers ({ commit, rootGetters }, { deviceId }) {
      const { getDevicePreviewWebrtcIceServers } = await apolloCall({
        query: GET_DEVICE_PREVIEW_WEBRTC_ICE_SERVERS,
        variables: { deviceId }
      })
      return getDevicePreviewWebrtcIceServers
    },
    async sendDevicePreviewWebrtcOffer ({ commit, rootGetters }, { deviceId, offerSdp }) {
      const { sendDevicePreviewWebrtcOffer } = await apolloCall({
        mutation: SEND_DEVICE_PREVIEW_WEBRTC_OFFER,
        variables: { deviceId, offerSdp }
      })
      return sendDevicePreviewWebrtcOffer
    },
    async sendDevicePreviewWebrtcLocalCandidates ({ commit, rootGetters }, { deviceId, sdpFragment, eTag }) {
      const { sendDevicePreviewWebrtcLocalCandidates } = await apolloCall({
        mutation: SEND_DEVICE_PREVIEW_LOCAL_CANDIDATES,
        variables: { deviceId, sdpFragment, eTag }
      })
      return sendDevicePreviewWebrtcLocalCandidates
    },
    async resetModule ({ commit }) {
      commit('CLEAR_DEVICES_DATA')
    }
  },
  getters: {
    currentGroupDevices: state => state.currentGroupDevices,
    devicesMatchingRules: state => state.devicesMatchingRules,
    devicesMatchingRulesCount: state => state.devicesMatchingRules?.length,
    allDevices: state => state.allDevices,
    allDevicesIdsMap: state => {
      return (state.allDevices || []).reduce((acc, g) => {
        acc[g.id] = g.name
        return acc
      }, {})
    },
    allDevicesNumber: state => state.allDevices?.length,
    deviceUpdateSubscription: state => state.deviceUpdateSubscription,
    devicesLoading: state => state.loading.devices,
    selectedDevicesIds: state => state.selectedDevices.map(d => d.id),
    devicePreviewObjectIsSet: state => state.devicePreviewObject !== null,
    devicePreviewDeviceId: state => state.devicePreviewObject?.deviceId,
    devicePreviewDeviceName: state => state.devicePreviewObject?.deviceName,
  },
  mutations: {
    SET_LOADING_STATUS (state, {
      status,
      key
    }) {
      state.loading[key] = status
    },
    SET_ALL_DEVICES (state, devices) {
      state.allDevices = devices
    },
    SET_DEVICES_MATCHING_RULES (state, devices) {
      state.devicesMatchingRules = devices
    },
    ADD_CURRENT_GROUP_DEVICE (state, device) {
      state.currentGroupDevices = [device, ...state.currentGroupDevices]
    },
    SET_CURRENT_GROUP_DEVICES (state, devices) {
      state.currentGroupDevices = devices
    },
    REMOVE_CURRENT_GROUP_DEVICE (state, deviceId) {
      state.currentGroupDevices = [...state.currentGroupDevices.filter(d => deviceId !== d.id)]
    },
    UPDATE_CURRENT_GROUP_DEVICE (state, device) {
      if (!state.currentGroupDevices?.some(d => d.id === device.id)) {
        return
      }
      state.currentGroupDevices = updateEntityById(state, 'currentGroupDevices', device)
    },
    UPDATE_DEVICE (state, device) {
      if (!state.allDevices?.some(d => d.id === device.id)) {
        return
      }
      state.allDevices = updateEntityById(state, 'allDevices', device)
    },
    REMOVE_DEVICE (state, deviceId) {
      state.allDevices = [...state.allDevices.filter(d => deviceId !== d.id)]
    },
    SET_DEVICE_UPDATE_SUBSCRIPTION (state, subscription) {
      state.deviceUpdateSubscription = subscription
    },
    UNSUBSCRIBE_FROM_DEVICE_UPDATE (state) {
      state.deviceUpdateSubscription?.unsubscribe()
      state.deviceUpdateSubscription = null
    },
    SET_DEVICE_PREVIEW_OBJECT (state, devicePreviewObject) {
      state.devicePreviewObject = devicePreviewObject
    },
    SET_SELECTED_DEVICES (state, selectedDevices) {
      state.selectedDevices = selectedDevices
    },
    CLEAR_SELECTED_DEVICES (state) {
      state.selectedDevices = []
    },
    CLEAR_DEVICE_PREVIEW_OBJECT (state) {
      state.devicePreviewObject = null
    },
    CLEAR_DEVICES_DATA (state) {
      state.allDevices = []
      state.selectedDevices = []
      state.devicesMatchingRules = []
      state.currentGroupDevices = []
      state.deviceUpdateSubscription?.unsubscribe()
      state.deviceUpdateSubscription = null
    }
  }
}
