<script setup>
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import SlideStep from '@/components/slideModal/SlideStep.vue'
import { getSocialHashtagsOrMentionsOnChange, TastySlide } from '@/helpers/Slides'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import DurationSlideStep from '@/components/slideModal/DurationSlideStep.vue'

const props = defineProps({
  title: String,
  slideObj: TastySlide,
  groupId: String,
  zoning: Boolean,
  disabled: Boolean
})
const emit = defineEmits(['save', 'update:disabled', 'update-slide', 'init-name', 'close'])

const MAX_TAGS_COUNT = 5

const { t } = useI18n()
const store = useStore()
const slide = props.slideObj || new TastySlide()
const { tags, muted, objectFit } = slide.slideData
const isMuted = props.zoning || muted
const continueVideoOnRestarts = ref(!!slide.slideData.continueVideoOnRestarts)
const tastyTags = computed(() => store.getters['slides/tastyTags'] || [])
const tastyTagsOptions = computed(() => tastyTags.value?.map(({name, display_name}) => ({
  label: display_name,
  value: name
})))

const selectedTags = ref(tags || [])

onMounted(()=>{
  store.dispatch('slides/getTastyTags')
})

emit('init-name', {
  name: slide.name,
  defaultName: slide.getDefaultName(t)
})

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const updateSlide = () => {
  emit('update-slide', slide)
}

const toggleDisabled = (status) => {
  emit('update:disabled', status)
}

const updateSlideData = () => {
  if (!selectedTags.value?.length) {
    emit('update:disabled', true)
    return
  }
  slide.updateSlideData({
    tags: selectedTags.value?.map(t=>t+''),
    muted: isMuted,
    continueVideoOnRestarts: continueVideoOnRestarts.value,
    objectFit,
  })
  emit('update:disabled', false)
  emit('update-slide', slide)
}

if (!slide.widgetId) {
  toggleDisabled(true)
}

const handleTagsChange = (value) => {
  if (selectedTags.value?.length > MAX_TAGS_COUNT) {
    selectedTags.value = selectedTags.value.filter(v => v !== value)
  }
  if (!selectedTags.value?.length) {
    return toggleDisabled(true)
  }
  updateSlideData()
  updateSlide()
  toggleDisabled(false)
}

watch(() => continueVideoOnRestarts.value, () => {
  updateSlideData()
  updateSlide()
})
</script>

<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.tasty.slideStep1.subTitle')"
    :title="$t('components.slides.tasty.slideStep1.title')"
  >
    <a-select
      v-model:value="selectedTags"
      style="width: 100%;"
      show-search
      mode="multiple"
      :options="tastyTagsOptions"
      allow-clear
      :filter-option="filterOption"
      placeholder="Select Topic"
      @select="handleTagsChange"
      @deselect="handleTagsChange"
    />
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.tasty.slideStep2.title')"
    no-border
  >
    <a-radio-group v-model:value="continueVideoOnRestarts">
      <div style="margin-bottom: 8px;">
        <a-radio
          :value="true"
        >
          {{ $t('components.slides.tasty.slideStep2.continuePlayback') }}
        </a-radio>
      </div>
      <div>
        <a-radio
          :value="false"
        >
          {{ $t('components.slides.tasty.slideStep2.playNewRecipe') }}
        </a-radio>
      </div>
    </a-radio-group>
  </SlideStep>
</template>

<style lang="less">
.preview-video {
  width: 100%;
  display: block;
}

</style>
