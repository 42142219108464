import ApolloClient from '@/apollo'
import { handleError } from '@/helpers/ErrorHandler'

export async function apolloCall({
  commit,
  query,
  mutation,
  variables = {},
  context = {},
  key,
  loadingMutation = 'SET_LOADING_STATUS',
  fetchPolicy = 'no-cache',
  onError,
  errorContext = 'apolloCall'
}) {
  if (query && mutation) {
    throw new Error('apolloCall: You can only pass either a query or a mutation');
  }

  if (!query && !mutation) {
    throw new Error('apolloCall: You must pass either a query or a mutation');
  }

  key && commit(loadingMutation, { status: true, key });
  try {
    const response = query
      ? await ApolloClient.query({ query, variables, fetchPolicy })
      : await ApolloClient.mutate({ mutation, variables, context });

    return response.data || response;
  } catch (error) {
    if (onError) {
      await onError(error)
    } else {
      handleError(error)
    }
  } finally {
    key && commit(loadingMutation, { status: false, key });
  }
}
