<template>
  <a-modal
    :open="showEmergencyPlaylistModal"
    :closable="false"
    width="1024px"
    :footer="null"
    wrap-class-name="full-modal emergency-playlist-modal"
    @ok="closeModal"
    @cancel="closeModal"
  >
    <CEngineEditor
      v-if="false"
      :slide="activeSlide"
    />
    <CustomTemplateModal
      v-model:visible="showCustomTemplateModal"
      :slide-obj="activeSlide"
      :zone-key="zoneKey"
      :zoning-layout-type="zoningLayoutType"
      @update="onSlideUpdate"
      @close="onSlidesUpdateCancel"
    />
    <AIContentSlideModal
      v-model:visible="showAIContentSlideModal"
      :slide-obj="activeSlide"
      @update="onSlideUpdate"
      @close="onSlidesUpdateCancel"
    />
    <TemplateConstructorModal
      v-model:visible="showSmartTemplateModal"
      :slide-obj="activeSlide"
      @update="onSlideUpdate"
      @close="onSlidesUpdateCancel"
    />
    <SlideModal
      v-model:visible="showSlideModal"
      :slide-type="activeSlideType"
      :slide="activeSlide"
      :is-main-zone="true"
      @update="onSlideUpdate"
      @close="onSlidesUpdateCancel"
    />
    <a-layout
      style="height: 100%;"
    >
      <a-layout-content
        id="emergency-playlist-modal-content"
        style=""
      >
        <a-spin :spinning="loading">
          <a-page-header
            style="position: sticky; z-index: 3; top: 0; background-color: #fff"
          >
            <template #title>
              {{ $t('components.emergencyPlaylistModal.title') }}
            </template>
          </a-page-header>
          <a-layout-content
            style="padding: 0 16px 16px; overflow-x: auto"
            class="playlist-wrapper"
            :class="{'dragging':isDraggingNewSlide}"
          >
            <EmergencyPlaylistEmpty
              v-if="!emergencyPlaylists.length && !isDraggingNewSlide"
            />
            <EmergencyPlaylist
              v-for="playlist in emergencyPlaylists"
              :key="playlist.id+playlist.updatedAt"
              :playlist="playlist"
              :filter-state="filterSlidesState"
              @delete="handlePlaylistDelete"
              @slide-click="onSlideClick"
              @slide-object-fit-change="onSlideObjectFitChange"
              @slide-mute="onSlideToggleSound"
            />
            <div ref="dropZoneRef">
              <EmergencyPlaylistDropZone
                :zone-key="zoneKey"
                :dragging="isDraggingNewSlide"
                @slide-add="onSlideAddLast"
              />
            </div>
          </a-layout-content>
          <StickyFooter>
            <FixedFooter>
              <div style="display: flex; gap: 8px;">
                <a-input
                  v-model:value="filterInput"
                  allow-clear
                  :placeholder="$t('components.playlistPage.filterPlaceholder')"
                  style="width: 100%"
                  @change="debounceFilter"
                />
                <a-segmented
                  v-model:value="filterSlidesState.type"
                  :options="filterSlidesOptions"
                />
              </div>
            </FixedFooter>
          </StickyFooter>
        </a-spin>
      </a-layout-content>
      <a-layout-sider
        style="background-color: #fff"
        collapsed-width="0"
        class="playlist-sider"
        width="250px"
      >
        <PlaylistWidgetsTab
          v-model:filter-slide-types="filterSlideTypes"
          :sider-collapsed="false"
          :slide-type-list="slideTypeList"
          :disabled-widgets="disabledWidgets"
          @start-drag-new-slide="onStartDragNewSlide"
          @stop-drag-new-slide="onStopDragNewSlide"
          @slide-add-last="onSlideAddLast"
        />
      </a-layout-sider>
    </a-layout>
  </a-modal>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, reactive, ref, watchEffect } from 'vue'
import { debounce } from 'lodash-es'
import { success } from '@/utils'
import FixedFooter from '@/components/FixedFooter.vue'
import SlideModal from '@/components/slideModal/SlideModal.vue'
import { useStore } from 'vuex'
import TemplateConstructorModal from '@/components/slideModal/TemplateConstructorModal'
import AIContentSlideModal from '@/components/slideModal/AIContentSlideModal'
import { useI18n } from 'vue-i18n'
import { DISABLED_WIDGETS_TYPES, WIDGET_TYPE_LIST_MENU_ITEMS } from '@/helpers/Slides'
import CustomTemplateModal from '@/components/slideModal/CustomTemplateModal'
import CEngineEditor from '@/components/cEngineEditor/CEngineEditor'
import PlaylistWidgetsTab from '@/components/playlistPage/PlaylistWidgetsTab'
import EmergencyPlaylist from '@/components/emergencyPlaylist/EmergencyPlaylist'
import EmergencyPlaylistDropZone from '@/components/emergencyPlaylist/EmergencyPlaylistDropZone'
import EmergencyPlaylistEmpty from '@/components/emergencyPlaylist/EmergencyPlaylistEmpty'
import StickyFooter from '@/components/StickyFooter'

const ZONE_KEY = 'MAIN'
const LAYOUT = 'V'
const FILTER = 'filter'
const HIGHLIGHT = 'highlight'
const DISABLED_WIDGETS_SLIDE_NAMES = DISABLED_WIDGETS_TYPES

export default defineComponent({
  name: 'EmergencyPlaylistModal',
  components: {
    StickyFooter,
    EmergencyPlaylistEmpty,
    EmergencyPlaylistDropZone,
    EmergencyPlaylist,
    PlaylistWidgetsTab,
    CEngineEditor,
    CustomTemplateModal,
    AIContentSlideModal,
    TemplateConstructorModal,
    SlideModal,
    FixedFooter
  },
  inheritAttrs: false,
  emits: ['delete-playlist'],
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const isDraggingNewSlide = ref(false)
    const showSlideModal = ref(false)
    const showSmartTemplateModal = ref(false)
    const showCustomTemplateModal = ref(false)
    const showAIContentSlideModal = ref(false)
    const dropZoneRef = ref(null)
    const filterSlideTypes = ref('')
    const activeSlideType = ref(null)
    const activeSlide = ref(null)
    const activeSlidePlaylistId = ref(null)
    const activeSlideZoneId = ref(null)
    const filterInput = ref('')
    const emergencyPlaylists =computed(()=>store.getters['playlist/emergencyPlaylists'])
    const emergencyPlaylistsLoading =computed(()=>store.getters['playlist/emergencyPlaylistsLoading'])
    const widgetDisplayRestrictions = computed(() => store.getters['playlist/widgetDisplayRestrictions'])
    const showEmergencyPlaylistModal = computed(() => store.getters.isModalVisible('emergencyPlaylist'))
    const zoningLayoutType = LAYOUT
    const slideTypeList = computed(() => {
      return WIDGET_TYPE_LIST_MENU_ITEMS.filter(w => w.id.toLowerCase().includes(filterSlideTypes.value.toLowerCase()))
    })
    const activeSlideId = computed(() => activeSlide.value?.id)
    const slideUpdating = ref(false)
    const loading = computed(() => emergencyPlaylistsLoading.value|| slideUpdating.value )

    const filterSlidesOptions = computed(() => [
      { label: t('components.playlistPage.filter'), value: FILTER },
      { label: t('components.playlistPage.highlight'), value: HIGHLIGHT }
    ])

    const filterSlidesState = reactive({
      input: '',
      type: FILTER
    })

    onMounted(() => {
      fetchWidgetDisplayRestrictions()
    })

    const fetchWidgetDisplayRestrictions = () => {
      store.dispatch('playlist/widgetDisplayRestrictions')
    }

    const handleAddSlide = ({
      slideType: type,
    }) => {
      activeSlideType.value = type
      if (type === 'CustomSlide') {
        openCustomTemplateEditor()
        return
      }
      if (type === 'SmartTemplateSlide') {
        openSmartTemplateEditor()
        return
      }
      if (type === 'AISlide') {
        openAiContentSlideModal()
        return
      }
      showSlideModal.value = true
    }

    const resetSelection = () => {
      activeSlide.value = null
      activeSlidePlaylistId.value = null
      activeSlideZoneId.value = null
    }

    const handleUpdateSlide = ({
      playlistId,
      zoneId,
      payload,
      changeLogs
    }) => {
      slideUpdating.value = true
      return store.dispatch('playlist/updateEmergencyPlaylistSlide', {
        playlistId: playlistId || activeSlidePlaylistId.value,
        payload,
        zoneKey: ZONE_KEY,
        zoneId: zoneId ||activeSlideZoneId.value,
        changeLogs,
      }).then(async () => {
        await store.dispatch('playlist/getEmergencyPlaylists')
        resetSelection()
        success()
      }).finally(() => {
        slideUpdating.value = false
      })
    }

    const onSlideClick = ({
      playlistId,
      zoneId,
      slide,
      e
    }) => {
      handleEditSlide({
        playlistId,
        zoneId,
        slide
      })
    }

    const handleEditSlide = ({
      playlistId,
      zoneId,
      slide,
    }) => {
      const type = slide.constructor.type
      activeSlideType.value = type
      activeSlide.value = slide
      activeSlidePlaylistId.value = playlistId
      activeSlideZoneId.value = zoneId
      if (type === 'CustomSlide') {
        openCustomTemplateEditor()
        return
      }
      if (type === 'SmartTemplateSlide') {
        return openSmartTemplateEditor()
      }
      if (type === 'AISlide') {
        return openAiContentSlideModal()
      }
      showSlideModal.value = true
    }


    const openSmartTemplateEditor = () => {
      showSmartTemplateModal.value = true
    }

    const openCustomTemplateEditor = () => {
      showCustomTemplateModal.value = true
    }

    const openAiContentSlideModal = () => {
      showAIContentSlideModal.value = true
    }

    const onSlideUpdate = (widgetModel) => {
      const input = widgetModel
      const isNewSlide = !input.widgetId
      delete input.widgetId
      const payload = {
        input
      }
      if (isNewSlide) {
        createEmergencyPlaylist(widgetModel)
      } else {
        payload.slideId = activeSlideId.value
        activeSlide.value && activeSlide.value.setWaitingForUpdate(true)
        const changeLogs = [{
          slideUpdated: {
            slideId: activeSlideId.value,
            slideName: activeSlide.value?.getName()
          }
        }]
        handleUpdateSlide({
          payload,
          zoneKey: ZONE_KEY,
          changeLogs
        })
      }
      resetSelection()
    }

    const onSlideToggleSound = ({
      slide,
      mute,
      playlistId,
      zoneId
    }) => {
      slide.setMute(mute)
      slide.waitingForUpdate = true
      const payload = {
        slideId: slide.id,
        input: slide.getDto({ ignoreWidgetId: true })
      }
      const changeLogs = [{
        ...(mute ? { slideMuted: { slideId: slide.id } } : {
          slideUnmuted: {
            slideId: slide.id,
            slideName: slide.getName()
          }
        })
      }]
      handleUpdateSlide({
        playlistId,
        zoneId,
        payload,
        zoneKey: ZONE_KEY,
        changeLogs
      })
    }

    const onSlideObjectFitChange = ({
      playlistId,
      zoneId,
      slide,
      objectFit,
      zoneKey
    }) => {
      slide.setObjectFit(objectFit)
      slide.waitingForUpdate = true
      const payload = {
        slideId: slide.id,
        input: slide.getDto({ ignoreWidgetId: true })
      }
      const changeLogs = [{
        slideUpdated: {
          slideId: slide.id,
          slideName: slide.getName()
        }
      }]
      handleUpdateSlide({
        payload,
        zoneKey,
        changeLogs,
        playlistId,
        zoneId
      })
    }

    const onSlidesUpdateCancel = () => {
      resetSelection()
    }

    const createEmergencyPlaylist = (widgetModel) => {
      const zones = [{ slides: [widgetModel] }]
      const layout = {
        type: 'V',
        zones
      }
      store.dispatch('playlist/createEmergencyPlaylist', { layout })
    }

    const handlePlaylistDelete = (playlistId) => {
      store.dispatch('playlist/deleteEmergencyPlaylist', { id: playlistId, force: true })
    }

    const onSlideAddLast = (slideType) => {
      handleAddSlide({
        slideType
      })
    }

    const onStartDragNewSlide = async () => {
      isDraggingNewSlide.value = true
      await nextTick()
      dropZoneRef.value?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    const onStopDragNewSlide = () => {
      isDraggingNewSlide.value = false
    }

    const closeModal = () => {
      store.dispatch('closeModal', 'emergencyPlaylist')
    }

    const debounceFilter = debounce(()=>{
      filterSlidesState.input = filterInput.value
    }, 100)

    watchEffect(()=>{
      if (showEmergencyPlaylistModal.value) {
        store.dispatch('playlist/getEmergencyPlaylists')
      }
    })


    return {
      filterSlideTypes,
      filterSlidesState,
      filterInput,
      loading,
      activeSlide,
      activeSlideType,
      slideTypeList,
      isDraggingNewSlide,
      showSlideModal,
      debounceFilter,
      zoningLayoutType,
      widgetDisplayRestrictions,
      showSmartTemplateModal,
      showCustomTemplateModal,
      showAIContentSlideModal,
      zoneKey: ZONE_KEY,
      disabledWidgets: DISABLED_WIDGETS_SLIDE_NAMES,
      filterSlidesOptions,
      showEmergencyPlaylistModal,
      emergencyPlaylists,
      emergencyPlaylistsLoading,
      dropZoneRef,
      closeModal,
      onSlideAddLast,
      onStartDragNewSlide,
      onStopDragNewSlide,
      onSlideUpdate,
      onSlideToggleSound,
      onSlideObjectFitChange,
      onSlideClick,
      onSlidesUpdateCancel,
      handlePlaylistDelete
    }
  }
})
</script>

<style lang="less">
@import '../../styles/variables';
.emergency-playlist-modal {
  .ant-modal-content {
    padding: 0;
    overflow: hidden;
    .ant-modal-body {
      overflow: hidden;
    }
  }
  .widgets-tab .ant-input-group-wrapper {
    padding-top: 20px;
  }
}

#emergency-playlist-modal-content {
  background: #fff;
  height: 100%;
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  .ant-spin-nested-loading {
    height: 100%;
    overflow: hidden;
    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .playlist-empty {
    color: @text-color-secondary;
  }

  .ant-page-header-heading-title > span {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .playlist-wrapper {
    display: flex;
    flex-direction: column;
    .ant-list {
      border: solid 2px transparent;
      flex: 1;

      .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
          height: 100%;

          .dragArea {
            height: 100%;
          }
        }
      }
    }

    .ant-list {
      min-height: 44px;
    }

    &.dragging {
    }
  }
}

.playlist-sider {
  header {
    padding: 20px 20px 12px 20px;

    .ant-radio-group {
      width: 100%;
      display: flex;

      .ant-radio-button-wrapper {
        flex: 1;
        text-align: center;
      }
    }
  }

  border-left: solid 1px #f0f0f0;
}
.widget-list-item {
  height: 32px;
  display: flex;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 1;
    cursor: not-allowed;
    background-color: @bg-light-grey;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  &:first-child {
    border-top: solid 1px #f0f0f0;
  }

  .widget-item-icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .add-icon {
      display: none;
    }
  }

  .widget-item-title {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px 0 0;

    .anticon {
      display: inline-block;
      width: 24px;
      text-align: left;
    }
  }

  &:hover {
    .widget-item-icon {
      .move-icon {
        display: none;
      }

      .add-icon {
        display: flex;
      }

      &:hover {
        .move-icon {
          display: flex;
          cursor: move;
        }

        .add-icon {
          display: none;
        }
      }
    }
  }
}


</style>
