import { GET_WORKSPACE_ACTIVITY_LOGS } from '@/graphql/queries'
import { apolloCall } from '@/helpers/Graphql'
import { handleActionError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    activityLogs: null,
    loading: {
      activityLogs: false
    }
  },
  actions: {
    async getActivityLogs ({ commit }, {pagination = { limit: 10 }, filters = null}) {
      const { offset } = pagination
      try {
        const { listActivityLogs: { data, pagination: _pagination } } = await apolloCall(
          {
            commit,
            query: GET_WORKSPACE_ACTIVITY_LOGS,
            key: 'activityLogs',
            variables: {pagination, filters}
          })
        commit(offset ? 'ADD_ACTIVITY_LOGS' : 'SET_ACTIVITY_LOGS', { data, pagination: _pagination })
      } catch (e) {
        handleActionError(e)
      }
    },
    async resetModule ({ commit }) {
      commit('CLEAR_ACTIVITY_LOGS')
    }
  },
  getters: {
    data: state => state.activityLogs?.data,
    pagination: state => state.activityLogs?.pagination,
    loading: state => state.loading.activityLogs
  },
  mutations: {
    SET_ACTIVITY_LOGS (state, { data, pagination }) {
      state.activityLogs = { data, pagination }
    },
    ADD_ACTIVITY_LOGS (state, { data, pagination }) {
      state.activityLogs = { data: [...state.activityLogs?.data, ...data], pagination }
    },
    SET_LOADING_STATUS (state, {
      status,
      key
    }) {
      state.loading[key] = status
    },
    CLEAR_ACTIVITY_LOGS (state) {
      state.activityLogs = null
    }
  }
}
