<script setup>
import { computed } from 'vue'
import { isEqual } from 'lodash'
import { useStore } from 'vuex'

const TYPES = {
  GROUPS: 'groups',
  PLAYLISTS: 'playlists'
}

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const store = useStore()

const userId = computed(() => store.getters['user/userId'])

const isCurrentUser = computed(() => userId.value === props.data.user.id)

const type = computed(() => {
  return isEqual(props.data?.oldRestrictionGroups, props.data?.newRestrictionGroups) ? TYPES.PLAYLISTS : TYPES.GROUPS
})

const hasAccessTo = computed(() => {
  return type.value === TYPES.GROUPS
      ? props.data?.newRestrictionGroups?.map(group => group.name).join(', ')
      : props.data?.newRestrictionPlaylists?.map(group => group.name).join(', ')
})

const hasAccessToCount = computed(() => {
  return type.value === TYPES.GROUPS
      ? props.data?.newRestrictionGroups?.length || 0
      : props.data?.newRestrictionPlaylists?.length || 0
})

const userName = computed(() => {
  return `${props.data?.user?.firstName || ''} ${props.data?.user?.lastName || ''}`.trim()
})

const key = computed(() => {
  let keyValue = props.type === TYPES.GROUPS ? 'accessToGroups' : 'accessToPlaylists'
  return isCurrentUser.value ? `${keyValue}ForYou` : `${keyValue}ForSomeone`
})

</script>

<template>
  {{ $t(`components.notificationsSider.${key}`, { name: userName, access: hasAccessTo }, hasAccessToCount) }}
</template>

<style scoped lang="less">

</style>
