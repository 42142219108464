<template>
  <a-spin
    v-if="!status"
    tip="Processing..."
    class="spinner"
  />
  <a-layout
    v-else
    style="margin: auto"
  >
    <a-layout-content style="padding: 80px 24px; width: 480px;">
      <a-result
        v-if="status === 'success'"
        status="success"
        :title="$t('components.revertEmailPage.successTitle')"
        :sub-title="$t('components.revertEmailPage.successText')"
      >
        <template #extra>
          <a-button type="primary">
            <router-link :to="{name: 'Home'}">
              {{ $t('components.revertEmailPage.goToDashboardButtonText') }}
            </router-link>
          </a-button>
        </template>
      </a-result>
      <a-result
        v-else
        status="error"
        :title="$t('components.revertEmailPage.errorTitle')"
      >
        <template #subTitle>
          <i18n-t
            keypath="components.revertEmailPage.errorText"
            tag="span"
          >
            <a-typography-link href="mailto:support@kitcast.tv">
              {{ $t('components.revertEmailPage.errorTextSupport') }}
            </a-typography-link>
          </i18n-t>
        </template>
        <template #extra>
          <a-button>
            <router-link :to="{name: 'Home'}">
              {{ $t('components.revertEmailPage.goToDashboardButtonText') }}
            </router-link>
          </a-button>
        </template>
      </a-result>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'RevertEmailPage',
  props: {
    token: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()
    const status = ref(null)

    store.dispatch('user/revertEmail', props.token).then(() => {
      router.push({ name: 'Home' })
    }).catch(() => {
      status.value = 'error'
    })

    return {
      status
    }
  }
})
</script>

<style scoped>
.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>
