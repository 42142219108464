import ApolloClient from '@/apollo'
import { GET_CURRENT_WORKSPACE_LOGO_AND_FAVICON, GET_CURRENT_WORKSPACE_WATERMARK } from '@/graphql/queries'
import {
  DELETE_WORKSPACE_FAVICON,
  DELETE_WORKSPACE_LOGO,
  DELETE_WORKSPACE_WATERMARK,
  UPLOAD_WORKSPACE_FAVICON,
  UPLOAD_WORKSPACE_LOGO,
  UPLOAD_WORKSPACE_WATERMARK
} from '@/graphql/mutations'
import { handleActionError } from '@/helpers/ErrorHandler'
import { apolloCall } from '@/helpers/Graphql'

const MEDIA_POLL_INTERVAL = 1500 // 1.5 seconds

export default {
  namespaced: true,
  state: {
    mediaLoading: {
      watermark: false,
      logo: false,
      favicon: false
    },
    mediaSubs: {
      watermark: null,
      logoAndFavicon: null
    }
  },
  actions: {
    async uploadWorkspaceWatermark ({ commit, dispatch, rootGetters }, file) {
      const workspaceId = rootGetters['workspace/workspaceId']
      try {
        const { uploadWorkspaceWatermark } = apolloCall({
          commit,
          mutation: UPLOAD_WORKSPACE_WATERMARK,
          variables: { workspaceId, file },
          key: 'watermark'
        })
        dispatch('fetchWorkspaceWatermark')
        return uploadWorkspaceWatermark
      } catch (e) {
        handleActionError(e)
      }
    },
    async deleteWorkspaceWatermark ({ commit, rootGetters }) {
      const workspaceId = rootGetters['workspace/workspaceId']
      try {
        await apolloCall({
          commit,
          mutation: DELETE_WORKSPACE_WATERMARK,
          variables: { workspaceId },
          key: 'watermark'
        })
        commit('workspace/UPDATE_CURRENT_WORKSPACE', { watermarkMedia: [] }, { root: true })
      } catch (e) {
        handleActionError(e)
      }
    },
    async uploadWorkspaceLogo ({ commit, dispatch, rootGetters }, file) {
      const workspaceId = rootGetters['workspace/workspaceId']
      try {
        const { uploadWorkspaceWhitelabelLogo } = await apolloCall({
          commit,
          mutation: UPLOAD_WORKSPACE_LOGO,
          variables: { workspaceId, file },
          key: 'logo'
        })
        dispatch('fetchWorkspaceLogoAndFavicon')
        return uploadWorkspaceWhitelabelLogo
      } catch (e) {
        handleActionError(e)
      }
    },
    async deleteWorkspaceLogo ({ commit, rootGetters }) {
      const workspaceId = rootGetters['workspace/workspaceId']
      try {
        await apolloCall({
          commit,
          mutation: DELETE_WORKSPACE_LOGO,
          variables: { workspaceId },
          key: 'logo'
        })
        commit('workspace/UPDATE_CURRENT_WORKSPACE', { whitelabelLogoMedia: [] }, { root: true })
        commit('auth/REMOVE_LOGO', null, { root: true })
      } catch (e) {
        handleActionError(e)
      }
    },
    async uploadWorkspaceFavicon ({ commit, dispatch, rootGetters }, file) {
      commit('SET_LOADING_STATUS', { status: true, key: 'favicon' })
      const workspaceId = rootGetters['workspace/workspaceId']
      try {
        const { uploadWorkspaceWhitelabelFavicon } = await apolloCall({
          commit,
          mutation: UPLOAD_WORKSPACE_FAVICON,
          variables: { workspaceId, file },
          key: 'favicon'
        })
        dispatch('fetchWorkspaceLogoAndFavicon')
        return uploadWorkspaceWhitelabelFavicon
      } catch (e) {
        handleActionError(e)
      }
    },
    async deleteWorkspaceFavicon ({ commit, rootGetters }) {
      const workspaceId = rootGetters['workspace/workspaceId']
      try {
        await apolloCall({
          commit,
          mutation: DELETE_WORKSPACE_FAVICON,
          variables: { workspaceId },
          key: 'favicon'
        })
        commit('workspace/UPDATE_CURRENT_WORKSPACE', { whitelabelFaviconMedia: [] }, { root: true })
        commit('auth/REMOVE_FAVICON', null, { root: true })
      } catch (e) {
        handleActionError(e)
      }
    },
    async fetchWorkspaceLogoAndFavicon ({ commit, getters }) {
      if (getters.logoFaviconSub) {
        return
      }
      const sub = ApolloClient.watchQuery({ query: GET_CURRENT_WORKSPACE_LOGO_AND_FAVICON, pollInterval: MEDIA_POLL_INTERVAL, fetchPolicy: 'no-cache' })
        .subscribe(({ data: { getCurrentWorkspace: { whitelabelFaviconMedia, whitelabelLogoMedia } }, loading }) => {
          const logoLoaded = whitelabelLogoMedia?.processingStatus === 'FINISHED' && !!whitelabelLogoMedia?.generatedMedia?.length
          const faviconLoaded = whitelabelFaviconMedia?.processingStatus === 'FINISHED'
          if (logoLoaded) {
            commit('SET_LOADING_STATUS', { status: false, key: 'logo' })
            commit('workspace/UPDATE_CURRENT_WORKSPACE', { whitelabelLogoMedia }, { root: true })
          }
          if (faviconLoaded) {
            commit('SET_LOADING_STATUS', { status: false, key: 'favicon' })
            commit('workspace/UPDATE_CURRENT_WORKSPACE', { whitelabelFaviconMedia }, { root: true })
          }
          if (logoLoaded && faviconLoaded) {
            commit('CLEAR_SUB', 'logoAndFavicon')
          }
        })
      commit('SET_SUB', { sub, key: 'logoAndFavicon' })
    },
    async fetchWorkspaceWatermark ({ commit, getters }) {
      if (getters.watermarkSub) {
        return
      }
      const sub = ApolloClient.watchQuery({ query: GET_CURRENT_WORKSPACE_WATERMARK, pollInterval: MEDIA_POLL_INTERVAL, fetchPolicy: 'no-cache' })
        .subscribe(({ data: { getCurrentWorkspace: { watermarkMedia } }, loading }) => {
          const watermarkLoaded = watermarkMedia?.processingStatus === 'FINISHED' && !!watermarkMedia?.generatedMedia?.length
          if (watermarkLoaded) {
            commit('SET_LOADING_STATUS', { status: false, key: 'watermark' })
            commit('workspace/UPDATE_CURRENT_WORKSPACE', { watermarkMedia }, { root: true })
            commit('CLEAR_SUB', 'watermark')
          }
        })
      commit('SET_SUB', { sub, key: 'watermark' })
    }
  },
  getters: {
    watermarkSub: state => state.mediaSubs.watermark,
    logoFaviconSub: state => state.mediaSubs.logoAndFavicon,
    logoLoadingStatus: state => state.mediaLoading.logo,
    faviconLoadingStatus: state => state.mediaLoading.favicon,
    watermarkLoadingStatus: state => state.mediaLoading.watermark,
    logo: (state, getters, rootState) => rootState?.workspace?.workspace?.whitelabelLogoMedia?.generatedMedia?.[0]?.url,
    favicon: (state, getters, rootState) => rootState?.workspace?.workspace?.whitelabelFaviconMedia?.generatedMedia?.[0]?.url || rootState?.workspace.workspace?.whitelabelFaviconMedia?.url,
    watermark: (state, getters, rootState) => rootState?.workspace?.workspace?.watermarkMedia?.generatedMedia?.[0]?.url || rootState?.workspace.workspace?.watermarkMedia?.url
  },
  mutations: {
    SET_LOADING_STATUS (state, { status, key }) {
      state.mediaLoading[key] = status
    },
    SET_SUB (state, { sub, key }) {
      state.mediaSubs[key] = sub
    },
    CLEAR_SUB (state, key) {
      state.mediaSubs[key]?.unsubscribe()
      state.mediaSubs[key] = null
    }
  }
}
