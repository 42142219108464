<template>
  <a-modal
    :open="true"
    :closable="false"
    :footer="null"
    width="768px"
    wrap-class-name="auth-modal"
  >
    <a-row :gutter="[48,16]">
      <a-col
        :span="15"
        class="forms"
      >
        <transition
          mode="out-in"
          name="fade"
        >
          <div v-if="isSignup">
            <SignupForm />
          </div>
          <div v-else-if="isSimpleSignup">
            <SimpleSignupForm :token="token" />
          </div>
          <div v-else-if="isLogin">
            <LoginForm />
          </div>
          <div v-else-if="isSSOLogin">
            <SSOForm />
          </div>
          <div v-else-if="isReset">
            <ResetPasswordForm />
          </div>
          <div v-else-if="isEmailConfirmed">
            <a-result
              style="margin-top: 60px;"
              status="success"
              :title="$t('components.authModal.emailValidationSuccessTitle')"
              :sub-title="$t('components.authModal.emailValidationSuccessSubtitle')"
            />
            <router-link
              :to="{name: 'Login'}"
              replace
            >
              <a-button
                type="primary"
                block
                size="large"
              >
                {{ $t('components.authModal.loginButtonText') }}
              </a-button>
            </router-link>
          </div>
        </transition>
      </a-col>
      <a-col
        :span="9"
        class="image-container"
      >
        <img
          src="../../assets/images/kc-login-pic.jpg"
          alt=""
        >
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { defineComponent } from 'vue'
import LoginForm from '@/components/authModal/LoginForm.vue'
import SignupForm from '@/components/authModal/SignupForm.vue'
import ResetPasswordForm from '@/components/authModal/ResetPasswordForm.vue'
import SimpleSignupForm from '@/components/authModal/SimpleSignupForm.vue'
import SSOForm from '@/components/authModal/SSOForm.vue'

export default defineComponent({
  name: 'AuthModal',
  components: { SSOForm, SimpleSignupForm, ResetPasswordForm, SignupForm, LoginForm },
  props: {
    isLogin: Boolean,
    isSignup: Boolean,
    isSimpleSignup: Boolean,
    isReset: Boolean,
    isEmailConfirmed: Boolean,
    isSSOLogin: Boolean,
    token: String
  },
  setup () {
    return {
    }
  }
}
)
</script>

<style lang="less">
.auth-modal {
  .ant-modal-content {
    overflow: hidden;
    padding: 0;
    .ant-modal-body {
      padding: 0;
      transition: all .3s ease;
    }
  }
  .forms {
    padding-top: 48px;
    padding-left: 72px !important;
    padding-bottom: 48px;
    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .image-container {
    height: 650px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

</style>
