<script setup>
import { computed, nextTick, onBeforeMount, ref, watch, inject, watchEffect } from 'vue'
import { loadStripe } from '@stripe/stripe-js'
import { debounce } from 'lodash-es'
import { CheckCircleOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import moment from 'moment'
import { error, success } from '@/utils'
import SubscriptionPlan from '@/components/updateSubscriptionModal/SubscriptionPlan.vue'
import SubscriptionPaymentMethod from '@/components/updateSubscriptionModal/SubscriptionPaymentMethod.vue'
import SubscriptionOrderSummary from '@/components/updateSubscriptionModal/SubscriptionOrderSummary.vue'
import { MAX_DEVICES_FOR_GROWTH_PLAN } from '@/constants'

const MIN_DEVICES_FOR_GROWTH_PLAN = 1
const YEARLY = 'year'
const MONTHLY = 'month'
const PRICE_INTERVAL_MAP = {
  year: 'yearly',
  month: 'monthly'
}

const PER_INTERVAL_PRICE_INFO_MAP = {
  year: 'perYear',
  month: 'perMonth'
}

const appearance = {
  theme: 'stripe',
}

const store = useStore()
const elms = ref()
const gtm = inject('gtm')
const successInfo = {
    devicesBought: 0,
    couponName: null,
    amount: 0,
    invoiceId: null,
    billingInterval: YEARLY,
    price: 0
  }
const stripeKey = process.env.VUE_APP_STRIPE_KEY
const stripeFormComplete = ref(false)
const paymentSuccess = ref(false)
const billingInterval = ref(YEARLY)
const currentStep = ref(0)
const promoCode = ref('')
const stripeLoaded = ref(false)
const paymentInProcess = ref(false)
const subscriptionUpdateInfo = ref(null)
const disableSubscriptionFields = ref(false)
const subscriptionUpdateInfoLoading = ref(false)
const currentClientSecret = ref(null)
const isYearly = computed(()=>billingInterval.value === YEARLY)
const isMonthly = computed(()=>billingInterval.value === MONTHLY)
const paymentMethod = computed(()=> store.getters['subscription/paymentMethod'])
const paymentMethodLoading = computed(()=> store.getters['subscription/paymentMethodLoading'])
const disablePayment = computed(()=> priceInfoHasError.value || noChanges.value || paymentMethodLoading.value || (currentStep.value === 1 && !stripeFormComplete.value))
const priceInfoHasError = ref(false)
const prices = computed(()=> store.getters['subscription/stripeGrowthPrices'])
const isEducationStarterTier = computed(() => store.getters['workspace/billing/isEducationStarterTier'])
const hasCustomPrice = computed(() => store.getters['workspace/billing/hasCustomPrice'])
const stripeCustomPrice = computed(() => store.getters['subscription/stripeCustomPrice'])
const isBusinessTier = computed(() => store.getters['workspace/billing/isBusinessTier'])
const planName = computed(() => isBusinessTier.value ? 'business-growth' : 'education-growth')
const isPaid = computed(() => store.getters['workspace/billing/isPaid'])
const billingDevicesQuantity = computed(() => store.getters['workspace/billing/devicesQuantity'])
const currentBillingInterval = computed(() => isPaid.value ? store.getters['workspace/billing/billingInterval'] : YEARLY)
const workspaceCustomer = computed(() => store.getters['subscription/workspaceCustomer'])
const allDevicesNumber = computed(()=>store.getters['devices/allDevicesNumber'])
const discount = computed(()=>workspaceCustomer.value?.discount)
const workspaceSubscription = computed(()=>store.getters['subscription/workspaceSubscription'])
const latestInvoice = computed(()=>workspaceSubscription.value?.latestInvoice)
const coupon = computed(()=>discount.value?.coupon)
const discountIsActive = computed(()=>{
  const now = moment()
  const start = discount.value?.start ? moment(discount.value?.start * 1000) : null
  const end = discount.value?.end ? moment(discount.value?.end * 1000) : null
  return (start && now.diff(start) > 0) && (!end || now.diff(end) < 0)
})
const amountDiscount = computed(()=>coupon.value?.amount_off)
const percentDiscount = computed(()=>coupon.value?.percent_off)
const devices = ref(1)
const elementsOptions = ref({
  clientSecret: null,
  appearance
})

const customPriceInterval = computed(()=>{
  if (!stripeCustomPrice.value?.recurring?.interval) return null
  return {
    inner: PRICE_INTERVAL_MAP[stripeCustomPrice.value.recurring.interval],
    value: stripeCustomPrice.value.recurring.interval
  }
})

const pricePerItemPerInterval = computed(()=>{
  const interval = PRICE_INTERVAL_MAP[billingInterval.value]
  const intervalString = PER_INTERVAL_PRICE_INFO_MAP[billingInterval.value]
  return pricesInfo.value?.[interval]?.[intervalString] || 0
})

const amountDue = computed(() => subscriptionUpdateInfo.value?.amount_due || 0)

const nextPaymentAnchor = computed(()=>{
  if (subscriptionUpdateInfo.value?.billing_cycle_anchor) {
    return moment(subscriptionUpdateInfo.value?.billing_cycle_anchor * 1000).format('MMM Do')
  }
  return null
})

const noChanges = computed(()=> {
  if (isEducationStarterTier.value) return false
  return isPaid.value && billingDevicesQuantity.value === devices.value && currentBillingInterval.value === billingInterval.value
})

const closable = computed(()=>{
  return !paymentInProcess.value && !paymentInProcess.value && !paymentSuccess.value
})

const pricesInfo = computed(()=>{
  if (!prices.value && !stripeCustomPrice.value?.recurring?.interval) return null
  if (prices.value) {
    const monthlyPerMonth = prices.value?.monthly?.unit_amount_decimal
    const monthlyPerYear = monthlyPerMonth * 12
    const yearlyPerYear = prices.value?.yearly?.unit_amount_decimal
    const yearlyPerMonth = yearlyPerYear / 12
    const yearlyDiscount = monthlyPerYear - yearlyPerYear
    const yearlyDiscountPercent = (yearlyDiscount / monthlyPerYear * 100).toFixed(0)
    return {
      monthly: {
        perMonth: monthlyPerMonth,
        perYear: monthlyPerYear
      },
      yearly: {
        perMonth: yearlyPerMonth,
        perYear: yearlyPerYear
      },
      yearlyDiscount,
      yearlyDiscountPercent
    }
  }
  else {
    const price = stripeCustomPrice.value.unit_amount_decimal
    const perYear = customPriceInterval.value.value === YEARLY ? price : price * 12
    const perMonth = customPriceInterval.value.value === YEARLY ? price / 12 : price
    return {
      [customPriceInterval.value.inner]: {
        perYear,
        perMonth
      }
    }
  }
})

const showUpdateSubscription = computed(() => store.getters.isModalVisible('updateSubscription'))
const minDevicesLimit = computed(()=> {
  if (isEducationStarterTier.value) return 1
  return isPaid.value ? billingDevicesQuantity.value : MIN_DEVICES_FOR_GROWTH_PLAN
})

onBeforeMount(() => {
  store.dispatch('subscription/setWorkspaceCustomer')
  store.dispatch('subscription/getPaymentMethod')
  const stripePromise = loadStripe(stripeKey)
  stripePromise.then(() => {
    stripeLoaded.value = true
  })
})

const changeBillingInterval = (period) => {
  billingInterval.value = period
  previewWorkspaceSubscriptionUpdate()
}


const closeModal = () => {
  store.dispatch('closeModal', 'updateSubscription')
}

const goToSubscription = () => {
  store.dispatch('closeModal', 'updateSubscription')
  store.dispatch('openModal', { modal: 'globalSettings', options: {activeTab: 'subscription'}})
}

const goToDashboard = () => {
  closeModal()
  setTimeout(()=>{
    store.dispatch('workspace/billing/refetchBillingInfo')
  }, 1000)
}

const handleDevicesNumberChange = (value = 0) => {
  if ( value > MAX_DEVICES_FOR_GROWTH_PLAN ) {
    devices.value = MAX_DEVICES_FOR_GROWTH_PLAN
  }
  else if (value < minDevicesLimit.value) {
    devices.value = minDevicesLimit.value
  }
  else if (value < allDevicesNumber.value) {
    devices.value = allDevicesNumber.value
  }
  else {
    devices.value = value
  }
}

const previewWorkspaceSubscriptionUpdate = async () => {
  if (subscriptionUpdateInfoLoading.value) return
  subscriptionUpdateInfoLoading.value = true
  const input = {
    billingItemsInfo: {
      billingInterval: billingInterval.value,
      billingDevicesQuantity: devices.value
    }
  }
  store.dispatch('subscription/previewWorkspaceSubscriptionUpdate', { input })
  .then(info => {
    priceInfoHasError.value = false
    subscriptionUpdateInfo.value = info
  }).catch((e)=>{
    priceInfoHasError.value = true
    error(e.message)
  }).then(()=>{
    subscriptionUpdateInfoLoading.value = false
  })
}

const debounceGetUpdateInfo = debounce(previewWorkspaceSubscriptionUpdate, 300)

const updatePaymentMethod = async () => {
  const elements = elms.value
  return elements.instance.confirmSetup({
    elements: elms.value.elements,
    redirect: 'if_required'
  })
}

const updateSubscription = () => {
  return store.dispatch('subscription/updateWorkspaceSubscription', {
    input: {
      billingItemsInfo: {
        billingInterval: billingInterval.value,
        billingDevicesQuantity: devices.value
      }
    }
  })
}

const applyPromoCode = () => {
  if (!promoCode.value) return
  paymentInProcess.value = true
  store.dispatch('subscription/updateWorkspaceCustomer', { input: { promotionCode: promoCode.value}}).then(()=>{
    previewWorkspaceSubscriptionUpdate()
    success()
    promoCode.value = '';
  }).catch(e=>{
    error(e.message)
  }).then(()=>{
    paymentInProcess.value = false
  })
}

const handleSuccess = () => {
  paymentSuccess.value = true
  togglePaymentProcess(false)
  sendSuccessEvent(successInfo)
}

const handleError = (e) => {
  error(e.message)
  togglePaymentProcess(false)
}

const setSuccessInfo = () => {
  successInfo.devicesBought = devices.value - (isPaid.value ? billingDevicesQuantity.value : 0)
  successInfo.couponName = coupon.value?.name
  successInfo.amount = amountDue.value
  successInfo.invoiceId = latestInvoice.value?.id
  successInfo.billingInterval = billingInterval.value
  successInfo.price = pricePerItemPerInterval.value
}

const pushGTMAddPaymentEvent = () => {
  gtm.push({
    event: "add_payment_info",
    ecommerce: {
      currency: "USD",
      value: +(amountDue.value || 0),
      payment_type: 'payment_type',
      items: [
        {
          item_name: "product_type", // Понятное пользователю название плана
          item_brand: billingInterval.value,
          price: +pricePerItemPerInterval.value,
          quantity: +devices.value
        }
      ]
    }
  })
}

const initStepTwo = () => {
  togglePaymentProcess(true)
  store.dispatch('subscription/getStripeSetupIntentClientSecret').then(client_secret => {
    currentClientSecret.value = client_secret
    elementsOptions.value.clientSecret = client_secret
    return nextTick()
  }).then(()=>{
    pushGTMAddPaymentEvent()
    updateStep(1)
  }).catch( e => {
    error(e)
  }).then(()=>{
    togglePaymentProcess(false)
  })
}


const handleFirstStep = () => {
  togglePaymentProcess(true)
  setSuccessInfo()
  //Try to update subscription if there is a paymentMethod
  if (paymentMethod.value) {
    updateSubscription().then(()=>{
      handleSuccess()
      store.dispatch('subscription/setWorkspaceCustomer')
    }).catch(()=>{
      initStepTwo()
    })
  }
  else {
    initStepTwo()
  }
}

const updateStep = (step) => {
  currentStep.value = step;
}

const togglePaymentProcess = (value = true) => {
  paymentInProcess.value = value
  disableSubscriptionFields.value = value
}

const handleSecondStep = () => {
  togglePaymentProcess(true)
  updatePaymentMethod().then((result)=>{
    if (result.error) {
      handleError(result.error)
      return
    }
    updateSubscription().then(()=>{
      store.dispatch('subscription/setWorkspaceCustomer')
      return handleSuccess()
    }).catch(e=>{
      handleError(e)
      initStepTwo()
    })
  }).catch(e=>{
    handleError(e)
  })
}

const handleSubmit = () => {
  if (currentStep.value === 0) {
    handleFirstStep()
  }
  else {
    handleSecondStep()
  }
}

const goToStepOne = () => {
  if (!paymentInProcess.value && !disableSubscriptionFields.value) {
    currentStep.value = 0
  }
}

const sendSuccessEvent = ({devicesBought = 0, couponName, amount = 0, invoiceId, billingInterval, price = 0}) => {
  gtm.push({ ecommerce: null })
  gtm.push({
    event: "purchase",
    ecommerce: {
      transaction_id: invoiceId,
      value: +amount,
      currency: "USD",
      coupon: couponName || null,
      items: [{
        item_name: planName.value,
        item_brand: billingInterval,
        quantity: +devicesBought,
        price: +price
      }]
    }
  })
}

const setPromoCode = () => {
  if (localStorage.getItem('couponCode')) {
    promoCode.value = localStorage.getItem('couponCode') || ''
    localStorage.removeItem('couponCode')
  }
}

watch(() => devices.value, (value)=>{
  value && debounceGetUpdateInfo()
})

watchEffect(async ()=>{
  if (showUpdateSubscription.value) {
    setPromoCode()
    devices.value = isPaid.value ? Math.max(billingDevicesQuantity.value, allDevicesNumber.value) : allDevicesNumber.value || MIN_DEVICES_FOR_GROWTH_PLAN
    await store.dispatch(`subscription/${hasCustomPrice.value ? 'getStripeCustomPrice' : 'getStripeGrowthPrices'}`)
    changeBillingInterval(customPriceInterval.value?.value || currentBillingInterval.value)
    if (!paymentSuccess.value) {
      gtm.push({
        event: "begin_checkout",
        ecommerce: {
          currency: "USD",
          value: +pricePerItemPerInterval.value,
          items: [
            {
              item_name: planName.value,
              item_brand: billingInterval.value,
              price: +pricePerItemPerInterval.value,
              quantity: +devices.value
            }
          ]
        }
      })
    }
  }
  else {
    setTimeout(()=>{
      currentStep.value = 0
      paymentSuccess.value = false
    }, 400)
  }
})

</script>

<template>
  <a-modal
    :open="showUpdateSubscription"
    :title="!paymentSuccess && $t('components.updateSubscriptionModal.title')"
    width="1200px"
    :closable="closable"
    :mask-closable="closable"
    :footer="null"
    wrap-class-name="full-modal subscription-modal"
    @ok="goToSubscription"
    @cancel="goToSubscription"
  >
    <template v-if="!paymentSuccess">
      <template v-if="!isPaid || isEducationStarterTier">
        <div style="width: 400px;">
          <a-steps
            :current="currentStep"
          >
            <a-step
              :title="$t('components.updateSubscriptionModal.subscriptionPlanTitle')"
              @click="goToStepOne"
            />
            <a-step :title="$t('components.updateSubscriptionModal.paymentMethodTitle')" />
          </a-steps>
        </div>

        <a-divider />
      </template>
      <a-row
        :gutter="[24, 24]"
      >
        <a-col :span="14">
          <template v-if="currentStep===0">
            <SubscriptionPlan
              :disable-fields="disableSubscriptionFields"
              :devices="devices"
              :is-monthly="isMonthly"
              :is-yearly="isYearly"
              :prices-info="pricesInfo"
              @change-billing-interval="changeBillingInterval"
              @on-devices-number-change="handleDevicesNumberChange"
            />
          </template>
          <template v-else>
            <SubscriptionPaymentMethod
              v-model:complete="stripeFormComplete"
              :disable-fields="paymentInProcess"
              :elements-options="elementsOptions"
              :stripe-loaded="stripeLoaded"
              :stripe-key="stripeKey"
              @on-elements-set="e => elms = e"
            />
          </template>
        </a-col>
        <a-col
          :span="10"
          :class="{'disabled': disablePayment}"
        >
          <SubscriptionOrderSummary
            v-model:promo-code="promoCode"
            :billing-interval="billingInterval"
            :prices-info="pricesInfo"
            :devices="devices"
            :disable-payment="disablePayment"
            :amount-due="amountDue"
            :payment-in-process="paymentInProcess"
            :discount-is-active="discountIsActive"
            :amount-discount="amountDiscount"
            :percent-discount="percentDiscount"
            :subscription-update-info-loading="subscriptionUpdateInfoLoading"
            :next-payment-anchor="nextPaymentAnchor"
            :handle-submit="handleSubmit"
            :apply-promo-code="applyPromoCode"
            :is-yearly="isYearly"
            :is-monthly="isMonthly"
            :current-step="currentStep"
            @apply-promo-code="applyPromoCode"
            @handle-submit="handleSubmit"
          />
        </a-col>
      </a-row>
    </template>
    <template v-else>
      <div style="display: flex; align-items: center; justify-content: center">
        <a-result
          status="success"
          :title="$t('components.updateSubscriptionModal.successTitle')"
          :sub-title="$t('components.updateSubscriptionModal.successSubtitle')"
          style="margin-top: 32px; max-width: 560px;"
        >
          <template #icon>
            <CheckCircleOutlined />
          </template>
          <template #extra>
            <a-button
              type="primary"
              @click="goToDashboard"
            >
              {{ $t('components.updateSubscriptionModal.goToDashboardButtonText') }}
            </a-button>
          </template>
        </a-result>
      </div>
    </template>
  </a-modal>
</template>

<style lang="less">
@import "../../styles/variables.less";

 .subscription-modal {
   .ant-modal-body {
     overflow: hidden !important;
   }
   .subscription-element {
     &.disabled {
       opacity: .6;
       pointer-events: none;
       user-select: none;
     }
     .small-x-borders {
       .ant-card-body {
         padding-top: 8px;
         padding-bottom: 8px;
         .ant-list-item {
           padding-left: 0;
           padding-right: 0;
         }
       }
     }
   }
   .promo {
     margin-bottom: 24px;
     margin-top: 12px;
     height: 150px;
   }
 }
</style>
