<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import SubscriptionSettingsPromoCode from '@/components/subscriptionSettings/SubscriptionSettingsPromoCode.vue'
defineEmits(['updateSubscription'])

const store = useStore()
const trialIsOver = computed(() => store.getters['workspace/billing/trialIsOver'])
const currentPeriodEndDate = computed(() => store.getters['workspace/billing/currentPeriodEndsFormatted']())
</script>

<template>
  <a-space
    direction="vertical"
    style="flex: 1;"
  >
    <a-alert
      type="warning"
      show-icon
    >
      <template #message>
        <template v-if="!trialIsOver">
          {{ $t('components.subscriptionSettingsManageBlockTrial.upgradeYourPlan', {date: currentPeriodEndDate}) }}
          <br>
          <a-typography-link
            class="underline-link"
            @click="$emit('updateSubscription')"
          >
            {{ $t('components.subscriptionSettingsManageBlockTrial.upgradeLink') }}
          </a-typography-link>
        </template>
        <template v-else>
          <i18n-t
            keypath="components.subscriptionSettingsManageBlockTrial.trialIsOverText"
            tag="label"
            for="components.subscriptionSettingsManageBlockTrial.trialIsOverLink"
          >
            <a-typography-link
              class="underline-link"
              @click="$emit('updateSubscription')"
            >
              {{ $t('components.subscriptionSettingsManageBlockTrial.trialIsOverLink') }}
            </a-typography-link>
          </i18n-t>
        </template>
      </template>
    </a-alert>
    <SubscriptionSettingsPromoCode />
  </a-space>
</template>

<style scoped lang="less">

</style>

