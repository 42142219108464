<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const isBusinessTier = computed(() => store.getters['workspace/billing/isBusinessTier'])

defineProps({
  disablePayment: {
    type: Boolean,
    default: false
  },
  pricesInfo: {
    type: Object,
    default: null
  },
  devices: {
    type: Number,
    default: 0
  },
  isYearly: {
    type: Boolean,
    default: false
  },
  isMonthly: {
    type: Boolean,
    default: false
  }
})
</script>

<template>
  <a-row type="flex">
    <a-col :flex="1">
      <a-typography-text :disabled="disablePayment">
        {{ $t(`components.updateSubscriptionModal.${isBusinessTier ? 'businessGrowthPlan' : 'educationGrowthPlan'}`) }}
      </a-typography-text>
    </a-col>
    <a-col>
      <a-typography-text
        strong
        :disabled="disablePayment"
      >
        <template v-if="isYearly">
          <span v-format-cents="pricesInfo?.yearly?.perYear * devices" />
          <span>
            {{ $t('components.updateSubscriptionModal.perYear') }}
          </span>
        </template>
        <template v-else-if="isMonthly">
          <span v-format-cents="pricesInfo?.monthly?.perMonth * devices" />
          <span>
            {{ $t('components.updateSubscriptionModal.perMonth') }}
          </span>
        </template>
      </a-typography-text>
    </a-col>
  </a-row>
  <a-row type="flex">
    <a-col :flex="1">
      <a-typography-text
        type="secondary"
        :disabled="disablePayment"
      >
        <template v-if="isYearly">
          <span v-format-cents="pricesInfo?.yearly?.perMonth" /> x <span style="color:var(--ant-primary-color)">{{ devices }}</span> {{ $t('components.updateSubscriptionModal.months', {count: 12}, count) }}
        </template>
        <template v-else-if="isMonthly">
          <span v-format-cents="pricesInfo?.monthly?.perMonth" /> x <span style="color:var(--ant-primary-color)">{{ devices }}</span> {{ $t('components.updateSubscriptionModal.months', {count: 1}, count) }}
        </template>
      </a-typography-text>
    </a-col>
    <a-col>
      <a-typography-text
        v-if="isYearly && pricesInfo?.yearlyDiscountPercent"
        :style="{ color: !disablePayment ? 'var(--ant-primary-color)' : ''}"
        :disabled="disablePayment"
      >
        {{ $t('components.updateSubscriptionModal.percentOff', {number: pricesInfo?.yearlyDiscountPercent}) }}
      </a-typography-text>
    </a-col>
  </a-row>
  <a-divider class="small" />
</template>

<style scoped lang="less">

</style>
