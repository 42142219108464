<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import SubscriptionSettingsManageBlockVerifyEmail
  from '@/components/subscriptionSettings/SubscriptionSettingsManageBlockVerifyEmail.vue'
import SubscriptionSettingsManageBlockTrial
  from '@/components/subscriptionSettings/SubscriptionSettingsManageBlockTrial.vue'
import SubscriptionSettingsManageBlockEnterprise
  from '@/components/subscriptionSettings/SubscriptionSettingsManageBlockEnterprise.vue'
import SubscriptionSettingsManageBlockGrowth
  from '@/components/subscriptionSettings/SubscriptionSettingsManageBlockGrowth.vue'
import SubscriptionSettingsManageBlockEDUStarter
  from '@/components/subscriptionSettings/SubscriptionSettingsManageBlockEDUStarter.vue'

const store = useStore()
const isTrialTier = computed(() => store.getters['workspace/billing/isTrialTier'])
const isEnterprise = computed(() => store.getters['workspace/billing/isEnterprize'])
const emailVerified = computed(() => store.getters['user/emailVerified'])
const isBusinessGrowthTier = computed(() => store.getters['workspace/billing/isBusinessGrowthTier'])
const isEducationStarterTier = computed(() => store.getters['workspace/billing/isEducationStarterTier'])
const isEducationGrowthTier = computed(() => store.getters['workspace/billing/isEducationGrowthTier'])

const updateSubscription = () => {
  store.dispatch('closeModal', 'globalSettings')
  store.dispatch('handleUpdateSubscription')
}

const updatePaymentMethod = () => {
  store.dispatch('closeModal', 'globalSettings')
  store.dispatch('openModal', { modal: 'updatePaymentMethod'})
}

</script>

<template>
  <div
    style="display: flex; flex-direction: column; flex: 1;"
  >
    <SubscriptionSettingsManageBlockVerifyEmail v-if="!emailVerified" />
    <SubscriptionSettingsManageBlockTrial
      v-else-if="isTrialTier"
      @update-subscription="updateSubscription"
    />
    <SubscriptionSettingsManageBlockEnterprise v-else-if="isEnterprise" />
    <SubscriptionSettingsManageBlockGrowth
      v-else-if="isBusinessGrowthTier || isEducationGrowthTier"
      @update-subscription="updateSubscription"
      @update-payment-method="updatePaymentMethod"
    />
    <SubscriptionSettingsManageBlockEDUStarter
      v-else-if="isEducationStarterTier"
      @update-subscription="updateSubscription"
    />
  </div>
</template>

<style scoped lang="less">
.underline-link {
  color: #000
}

</style>
