import { formatFileSize } from '@/utils'

const getWorkspaceSetting = (rootState, key) =>
  rootState.workspace?.workspace?.settings?.[key];

export default {
  namespaced: true,
  state: {},
  actions: {},
  getters: {
    hideWatermark: (state, getters, rootState) => getWorkspaceSetting(rootState, 'hideWatermark'),
    useCustomWatermark: (state, getters, rootState) => getWorkspaceSetting(rootState, 'useCustomWatermark'),
    emergencyFeedUrl: (state, getters, rootState) => getWorkspaceSetting(rootState, 'emergencyFeedUrl'),
    emergencyFeedEnabled: (state, getters, rootState) => getWorkspaceSetting(rootState, 'emergencyFeedEnabled'),
    emergencyFeedDeviceMatchingRule: (state, getters, rootState) => getWorkspaceSetting(rootState, 'emergencyFeedDeviceMatchingRule'),
    accentColor: (state, getters, rootState) => getWorkspaceSetting(rootState, 'whitelabelAccentColor'),
    demoStorageSizeLimit: (state, getters, rootState) => {
      const limit = getWorkspaceSetting(rootState, 'demoStorageSizeLimit')
      return limit ? formatFileSize(limit) : null
    }
  },
  mutations: {}
}
