import { GET_WORKSPACE_MEMBERS } from '@/graphql/queries'
import { REMOVE_WORKSPACE_MEMBER_BY_ID, UPDATE_WORKSPACE_MEMBER_BY_ID } from '@/graphql/mutations'
import { apolloCall } from '@/helpers/Graphql'
import { handleActionError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    members: [],
    loading: {
      members: false
    }
  },
  actions: {
    async getMembers ({ commit }) {
      try {
        const { listCurrentWorkspaceMemberRoles } = await apolloCall({
          commit,
          query: GET_WORKSPACE_MEMBERS,
          key: 'members'
        })
        commit('SET_MEMBERS', listCurrentWorkspaceMemberRoles)
      } catch (e) {
        handleActionError(e)
      }
    },
    async removeMember ({ commit }, userId) {
      try {
        await apolloCall({
          commit,
          mutation: REMOVE_WORKSPACE_MEMBER_BY_ID,
          variables: { userId },
          key: 'members'
        })
        commit('REMOVE_MEMBER_BY_ID', userId)
      }
      catch (e) {
        handleActionError(e)
      }
    },
    async updateMember ({ commit }, { userId, role, restrictions }) {
      try {
        const { updateCurrentWorkspaceMemberRole } = await apolloCall({
          commit,
          mutation: UPDATE_WORKSPACE_MEMBER_BY_ID,
          variables: { input: { userId, role, restrictions } },
          key: 'members'
        })
        commit('UPDATE_MEMBER_BY_ID', updateCurrentWorkspaceMemberRole)
      }
      catch (e) {
        throw e
      }
    },
    async resetModule ({ commit }) {
      commit('CLEAR_MEMBERS')
    }
  },
  getters: {
    members: state => state.members,
    membersLoading: state => state.loading.members
  },
  mutations: {
    SET_MEMBERS (state, members) {
      state.members = members
    },
    SET_LOADING_STATUS (state, { status, key }) {
      state.loading[key] = status
    },
    REMOVE_MEMBER_BY_ID (state, userId) {
      state.members = state.members.filter((member)=>member.user.id !== userId)
    },
    UPDATE_MEMBER_BY_ID (state, member) {
      state.members = state.members.map((m)=>m.userId === member.userId ? member : m)
    },
    CLEAR_MEMBERS (state) {
      state.members = []
    }
  },
  modules: {

  }
}
