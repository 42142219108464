import {
  GET_CURRENT_WORKSPACE_BILLING_INFO,
  GET_CURRENT_WORKSPACE_BILLING_INFO_AND_FROZEN_STATUSES,
} from '@/graphql/queries'
import moment from 'moment/moment'
import { apolloCall } from '@/helpers/Graphql'
import { handleActionError } from '@/helpers/ErrorHandler'

const TRIAL_TIERS = ['business-trial', 'education-trial']
const BUSINESS_GROWTH_TIER = 'business-growth'
const EDUCATION_GROWTH_TIER = 'education-growth'
const EDUCATION_STARTER_TIER = 'education-starter'

const TIER_GROUPS = {
  BUSINESS: 'business',
  EDUCATION: 'education'
}

const BILLING_STATUSES = {
  INCOMPLETE: 'incomplete',
  INACTIVE: 'inactive',
  UNPAID: 'unpaid',
  PAID: 'paid',
  PAST_DUE: 'past_due',
  TRIALING: 'trialing'
}

const formatDate = (date, format = 'MMMM D') => moment(date || null).format(format);
const isTierTrial = tier => TRIAL_TIERS.includes(tier)

export default {
  namespaced: true,
  state: {
  },
  actions: {
    async fetchBillingInfo({ commit }, query) {
      try {
        const { getCurrentWorkspace: workspace } = await apolloCall({ query })
        commit('workspace/UPDATE_CURRENT_WORKSPACE', { billingInfo: workspace.billingInfo, frozen: workspace.frozen, frozenReason: workspace.frozenReason }, { root: true })
      } catch (e) {
        handleActionError(e)
      }
    },
    async refetchBillingInfo({ dispatch }) {
      await dispatch('fetchBillingInfo', GET_CURRENT_WORKSPACE_BILLING_INFO)
    },
    async refetchFrozenStatusesAndBillingInfo({ dispatch }) {
      await dispatch('fetchBillingInfo', GET_CURRENT_WORKSPACE_BILLING_INFO_AND_FROZEN_STATUSES)
    }
  },
  getters: {
    billingInfo: (state, getters, rootState) => rootState.workspace?.workspace?.billingInfo,
    eduTierApplication: (state, getters) => getters.billingInfo?.eduTierApplication || {},
    devicesQuantity: (state, getters) => getters.billingInfo?.billingDevicesQuantity || 0,
    billingInterval: (state, getters) => getters.billingInfo?.billingInterval,
    freeDevicesQuantity: (state, getters) => getters.billingInfo?.freeDevicesQuantity || 0,
    isBusinessTier: (state, getters) => getters.billingInfo?.tierGroup === TIER_GROUPS.BUSINESS,
    isEducationTier: (state, getters) => getters.billingInfo?.tierGroup === TIER_GROUPS.EDUCATION,
    isEnterprize: (state, getters) => getters.billingInfo?.enterprize,
    isBillingStatus: (state, getters) => (status) => getters.billingInfo?.status === status,
    isInactive: (state, getters) => getters.isBillingStatus(BILLING_STATUSES.INACTIVE) || getters.isBillingStatus(BILLING_STATUSES.INCOMPLETE),
    isPaid: (state, getters) => getters.isBillingStatus(BILLING_STATUSES.PAID) && getters.billingInfo?.tier !== EDUCATION_STARTER_TIER,
    isUnpaid: (state, getters) => getters.isBillingStatus(BILLING_STATUSES.UNPAID),
    isPastDue: (state, getters) => getters.isBillingStatus(BILLING_STATUSES.PAST_DUE),
    isTrial: (state, getters) => isTierTrial(getters.billingInfo?.tier) && getters.isBillingStatus(BILLING_STATUSES.TRIALING),
    isTrialTier: (state, getters) => isTierTrial(getters.billingInfo?.tier),
    isPaused: (state, getters) => getters.billingInfo?.paused,
    isBusinessGrowthTier: (state, getters) => getters.billingInfo?.tier === BUSINESS_GROWTH_TIER,
    isEducationStarterTier: (state, getters) => getters.billingInfo?.tier === EDUCATION_STARTER_TIER,
    isEducationGrowthTier: (state, getters) => getters.billingInfo?.tier === EDUCATION_GROWTH_TIER,
    trialIsOver: (state, getters) => isTierTrial(getters.billingInfo?.tier) && getters.billingInfo?.status !== BILLING_STATUSES.TRIALING,
    currentPeriodEnds: (state, getters) => getters.billingInfo?.currentPeriodEnd,
    currentPeriodEndsFormatted: (state, getters) => (format) => formatDate(getters.billingInfo?.currentPeriodEnd, format),
    currentPeriodStarts: (state, getters) => getters.billingInfo?.currentPeriodStart,
    trialDaysLeft: (state, getters) => getters.billingInfo?.status === BILLING_STATUSES.TRIALING && moment(getters.billingInfo?.currentPeriodEnd).diff(moment(), 'days'),
    stripeCustomPriceId: (state, getters) => getters.billingInfo?.stripeCustomPriceId,
    hasCustomPrice: (state, getters) => !!getters.billingInfo?.stripeCustomPriceId
  },
  mutations: {}
}
