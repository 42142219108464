import { LIST_TAGS } from '@/graphql/queries'
import { handleActionError } from '@/helpers/ErrorHandler'
import { apolloCall } from '@/helpers/Graphql'

export default {
  namespaced: true,
  state: {
    tags: [],
    groupsTags: [],
    devicesTags: [],
    addBlockFunction: null
  },
  actions: {
    async getAllTags ({ commit }) {
      try {
        const { listTags } = await apolloCall(
          {
            query: LIST_TAGS,
            variables: { filters: {} },
          })
        commit('SET_TAGS', listTags)
      } catch (e) {
        handleActionError(e)
      }
    },
    async getDevicesTags ({ commit }) {
      try {
        const { listTags } = await apolloCall(
          {
            query: LIST_TAGS,
            variables: { filters: { linkTypes: 'DEVICE_TAG'} },
          })
        commit('SET_DEVICES_TAGS', listTags)
      } catch (e) {
        handleActionError(e)
      }
    },
    async getGroupsTags ({ commit }) {
      try {
        const { listTags } = await apolloCall(
          {
            query: LIST_TAGS,
            variables: { filters: { linkTypes: 'GROUP_TAG'} },
          })
        commit('SET_GROUPS_TAGS', listTags)
      } catch (e) {
        handleActionError(e)
      }
    },
    setAddBlockFunction({commit}, fn) {
      commit('SET_ADD_BLOCK_FUNCTION', fn)
    },
    async resetModule ({ commit }) {
      commit('CLEAR_TAGS_DATA')
    }
  },
  getters: {
    tags: state => state.tags,
    groupsTags: state => state.groupsTags,
    devicesTags: state => state.devicesTags,
    addBlockFunction: state => state.addBlockFunction
  },
  mutations: {
    SET_TAGS (state, tags) {
      state.tags = tags
    },
    SET_ADD_BLOCK_FUNCTION (state, fn) {
      state.addBlockFunction = fn
    },
    SET_DEVICES_TAGS (state, tags) {
      state.devicesTags = tags
    },
    SET_GROUPS_TAGS (state, tags) {
      state.groupsTags = tags
    },
    CLEAR_TAGS_DATA (state) {
      state.tags = []
    }
  }
}
