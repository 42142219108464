<script setup>
import { reactive, ref, toRaw, watch, watchEffect } from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const emit = defineEmits(['update:open', 'save'])
const dateFormat = 'YYYY-MM-DD';

const props = defineProps({
  open: Boolean,
  options: Object,
  mediaIds: String
})

const state = reactive({
  deleteAt: props.options?.deleteAt ? dayjs(props.options.deleteAt, dateFormat) : null
})

const disabledDate = current => {
  // return current && current < dayjs().subtract(1, 'day')
  return current && current < dayjs().endOf('day')
}

const onCancel = () => {
  emit('update:open', false)
}

const onSave= () => {
  emit('save', {
    autoDeleteOptions :{
      deleteAt: state.deleteAt ? dayjs(state.deleteAt).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null,
      force: false
    },
    mediaIds: props.mediaIds
  })
  emit('update:open', false)
}

const onRemove = () => {
  emit('save', {
    autoDeleteOptions :null,
    mediaIds: props.mediaIds
  }, )
  emit('update:open', false)
}

watchEffect(()=>{
  if (props.open) {
    state.deleteAt = props.options?.deleteAt ? dayjs(props.options.deleteAt, dateFormat) : null
  }
})

</script>

<template>
  <a-modal
    :title="$t('components.mediaTableExpirationModal.title')"
    :open="open"
    width="400px"
    destroy-on-close
    ok-text="Save"
    cancel-text="Cancel"
    :ok-button-props="{ disabled: !state.deleteAt }"
    @cancel="onCancel"
    @ok="onSave"
  >
    <a-date-picker
      v-model:value="state.deleteAt"
      :disabled-date="disabledDate"
      style="width: 200px; margin-top: 8px;"
    />
    <template #footer>
      <a-space>
        <a-button @click="onCancel">
          {{ $t('components.mediaTableExpirationModal.cancelButtonText') }}
        </a-button>
        <a-button
          type="default"
          @click="onRemove"
        >
          {{ $t('components.mediaTableExpirationModal.removeButtonText') }}
        </a-button>
        <a-button
          type="primary"
          :disabled="!state.deleteAt"
          @click="onSave"
        >
          {{ $t('components.mediaTableExpirationModal.saveButtonText') }}
        </a-button>
      </a-space>
    </template>
  </a-modal>
</template>

<style scoped lang="less">

</style>
