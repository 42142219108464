import { GET_WORKSPACE_PENDING_INVITE_LINK, GET_WORKSPACE_PENDING_INVITES } from '@/graphql/queries'
import { CANCEL_INVITE, RESEND_INVITE, SEND_INVITE } from '@/graphql/mutations'
import { handleActionError } from '@/helpers/ErrorHandler'
import { apolloCall } from '@/helpers/Graphql'

export default {
  namespaced: true,
  state: {
    invites: [],
    loading: {
      invites: false
    }
  },
  actions: {
    async getInvites({ commit }) {
      try {
        const { listCurrentWorkspacePendingInvites } = await apolloCall({
          commit,
          query: GET_WORKSPACE_PENDING_INVITES,
          key: 'invites'
        })
        commit('SET_INVITES', listCurrentWorkspacePendingInvites)
      }
      catch (e) {
        handleActionError(e)
      }
    },
    async sendInvite({ commit }, payload) {
      try {
        const { sendInvite } = await apolloCall({
          commit,
          mutation: SEND_INVITE,
          variables: { input: payload },
          key: 'invites'
        })
        commit('ADD_INVITE', sendInvite)
      }
      catch (e) {
        throw e
      }
    },
    async resendInvite({commit}, inviteId) {
      return await apolloCall({
        commit,
        mutation: RESEND_INVITE,
        variables: { inviteId },
        key: 'invites'
      })
    },
    async cancelInvite({ commit }, email) {
      try {
        await apolloCall({
          commit,
          mutation: CANCEL_INVITE,
          variables: { email },
          key: 'invites'
        })
        commit('REMOVE_INVITE', email)
      }
      catch (e) {
        throw e
      }
    },
    async getInviteLink({}, inviteId) {
      return await apolloCall({
        query: GET_WORKSPACE_PENDING_INVITE_LINK,
        variables: { inviteId },
        fetchPolicy: 'no-cache'
      }).then(({ getCurrentWorkspacePendingInviteLink }) => getCurrentWorkspacePendingInviteLink)
    },
    async resetModule({ commit }) {
      commit('CLEAR_INVITES')
    }
  },
  getters: {
    invites: state => state.invites,
    invitesLoading: state => state.loading.invites
  },
  mutations: {
    SET_INVITES (state, invites) {
      state.invites = invites
    },
    SET_LOADING_STATUS (state, { status, key }) {
      state.loading[key] = status
    },
    ADD_INVITE (state, invite) {
      state.invites = [invite, ...state.invites]
    },
    REMOVE_INVITE (state, email) {
      state.invites = state.invites.filter(invite => invite.email !== email)
    },
    CLEAR_INVITES (state) {
      state.invites = []
    }
  }
}
